import React from 'react';
import {Redirect, Route} from 'react-router';
import Navbar from './components/Navbar/Navbar';
import {history} from './helpers/history';
import {useSelector} from 'react-redux';
import Footer from './Pages/Landing/Footer';

function RouteWithNavBar({component: Component, ...rest}) {
  const {user: currentUser} = useSelector (state => state.auth);
  return currentUser
    ? <Route {...rest}>
        <Navbar history={history} />
        <Component />
        <Footer />
      </Route>
    : <Redirect to="/login" />;
}

export default RouteWithNavBar;
