import React, { useEffect, useState } from 'react'
import './PopularCourses.css';
// import SingleCourse from './SingleCourse';
import courseImg from '../../assets/courseImage.svg';
// import dataScience from '../../assets/dataScience.svg';
import CourseServices from '../../services/courses.service';
import {message} from 'antd';
import {Link} from 'react-router-dom'
import CourseCard from '../Courses/CourseCard/CourseCard';


function PopularCourses() {
    const [allCourses,setAllCourses] = useState([])
    // const getAllCourses = async ()=>{
    //     try{
    //         const result = await CourseServices.getAllCourses();
    //         // console.log(result.data);
    //         setAllCourses(result.data);
    //     }catch(err){
    //         console.log(err);
    //         message.error(err.message ? err.message : 'Unable to Get courses!');
    //     }
    // }
    useEffect(()=>{
        const getAllCourses = async ()=>{
            try{
                const result = await CourseServices.getAllCourses();
                // console.log(result.data);
                setAllCourses(result.data);
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Unable to Get courses!');
            }
        }
        getAllCourses();
    },[])
    return (
        <div className='popularCourses'>
           <h1>Popular Courses</h1>
           <div className='popularCourses__wrapper'>
           {allCourses.map((item,index)=>{
                return(index <9 ? <CourseCard 
                img={item.imageUrl ? item.imageUrl : courseImg} 
                isShadow={"no"}
                styles={{width:'30%'}}
                key={index}
                title={item.title} id={item.id} description={item.description} /> : null)
              
           })}
           {/* <SingleCourse img={dataScience} title="Data Science" desc="This is a Data Science Course" /> */}
         
          
          
           </div>
           <div className='btnArea'>
                <Link to="/courses" className="outlineBtn"><b>More Courses</b></Link>
            </div>
        
        </div>
    )
}

export default PopularCourses
