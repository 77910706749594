import React, {useState, useEffect, useRef} from 'react';
import {Link, useParams} from 'react-router-dom';
import './LearnCourse.css';
import { Button, Collapse, message, Modal, Progress, Spin, Tabs, Tooltip} from 'antd';
import CourseServices from '../../services/courses.service.js';
import {Layout, Menu,Checkbox} from 'antd';
import logo from '../../assets/codersartsLogo.png';
import noData from '../../assets/no-data.svg';
import brainImg from '../../assets/brain.jpg';
import brainLearning from '../../assets/brainLearning.jpg';
import pdfIcon from '../../assets/pdfSelect.png';
import avatar  from '../../assets/login.png';
import styles from '../StartProject/StartProject.module.css';
// import avatar  from '../../assets/login.png';
// import { useSelector,useDispatch } from 'react-redux';
// import { logout } from '../../actions/auth';
// import { history } from '../../helpers/history';
import {BiBookReader, BiChevronLeft, BiChevronRight, BiHomeCircle} from 'react-icons/bi';
import {BsChatLeftText, BsCheckLg} from 'react-icons/bs';
import ReactPlayer from 'react-player/lazy';
import {MdFileDownload, MdSend} from 'react-icons/md';
import ReactQuill from 'react-quill';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';
import StudentServices from '../../services/students.service';
import {useSelector} from 'react-redux';
import { GiAchievement } from 'react-icons/gi';
import { AiFillTrophy,AiFillLike,AiOutlineLike, AiOutlineQuestionCircle } from 'react-icons/ai';
import {FaChalkboardTeacher, FaCheck, FaTimes} from 'react-icons/fa';
import { BulbOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ResizeHandle from './ResizeHandle';
import { Panel, PanelGroup } from "react-resizable-panels";
import chatbot from '../../assets/chatbot.png';
import { LoadingOutlined } from '@ant-design/icons';
import { history } from '../../helpers/history.js';


const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


// import ProfileDropdown from '../../components/Navbar/ProfileDropdown';
// import ReactHtmlParser from 'react-html-parser';

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Panel:PanelCollapse} = Collapse

const modules = {
  syntax: {highlight: text => hljs.highlightAuto (text).value},
  toolbar: false,
};

function LearnCourse () {
  const [courseSections, setCourseSections] = useState ([]);
  const [courseMaterials, setCourseMaterials] = useState ([]);
  const [currentCourseMaterial, setCurrentCourseMaterial] = useState (null);
  const [collapsed, setCollapsed] = useState (false);
  const {user: currentUser} = useSelector (state => state.auth);
  const [currentStudentInCourse, setCurrentStudentInCourse] = useState ('');
  const [currentProgress,setCurrentProgress] = useState(0);
  const [currentModuleQuizes,setCurrentModuleQuizes] = useState([]);
  const [isQuizStarted,setIsQuizStarted] = useState(false);
  const [currentCourseSection,setCurrentCourseSection] = useState("");
  const [currentQuizItem,setCurrentQuizItem] = useState("");
  const [currentActiveOption,setCurrentActiveOption] = useState("");
  const [isAnswerSubmitted,setIsAnswerSubmitted] = useState(false);
  const [currentScore,setCurrentScore] = useState(0);
  const [isShowAnswer,setIsShowAnswer] = useState(false);
  const [isClickedOnShow,setIsClickedOnShow] = useState(false);
  const [isQuizFinished,setIsQuizFinished] = useState(false);
  const [isLockChanges,setIsLockChanges] = useState(false);
  const [showVideoPanel,setShowVideoPanel] = useState(true);
  const [moveCollapseBtn,setMoveCollapseBtn] = useState(false);
  const optionLetters = ['A','B','C','D'];
  const [comment,setComment] = useState("");
  const [isFocused,setIsFocused] = useState(false);
  const [allCourseComments,setAllCourseComments] = useState([]);
  const [isCommenting,setIsCommenting] = useState(false);
  const [currentReply,setCurrentReply] = useState("");
  const [isReplyFocused,setIsReplyFocused] = useState(false);
  const [isReplying,setIsReplying] = useState(false);
  const [reply,setReply] = useState("");
  const [allConversations,setAllConversations] = useState([]);
  const [isAssistantView,setIsAssistantView] = useState(false);
  const [query,setQuery] = useState("");
  const scrollRef = useRef();
  const fileRef = useRef();
  const [fileUrl,setFileUrl] = useState("");
  const [docId,setDocId] = useState("");
  const [pdfQuery,setPdfQuery] = useState("");
  const [allPdfMsgList,setAllPdfMsgList] = useState([]);
  const [pdfUploading,setPdfUploading] = useState(false);
  const [isQuerying,setIsQuerying] = useState(false);
  const [currentActiveTab,setCurrentActiveTab] = useState('1');
  // const [isQuizLoading,setIsQuizLoading] = useState(false);
  const {id} = useParams();
  useEffect(()=>{
    const checkUserEnrolled = async ()=>{
      try{
        //console.log("calling");
        const response = await StudentServices.checkIsEnrolled({userId:currentUser.id,courseId:id});
        console.log(response.data);
        if(response.status===200){
          if(!response.data.isEnrolled){
            message.error("You're not enrolled in the course!");
            history.push(`/courses/view-course-details/${id}`);
          }
        }
      }catch(err){
        console.log(err);
        message.error(err.message);
      }
    }
    console.log(currentUser);
    if(currentUser){
      console.log(currentUser);
      checkUserEnrolled();
    }else{
      history.push('/login');
    }
  },[currentUser,id])


  const handleTabChange = (key)=>{
    // console.log(key);
    setCurrentActiveTab(key);
  }

  const handleActiveOption = (option)=>{
    if(isLockChanges){
      return;
    }
    if(isAnswerSubmitted){
      return;
    }
    setCurrentActiveOption(option);
  }

  const checkCurrentAnswer = ()=>{
    if(isLockChanges){
      setIsQuizFinished(true);
      return;
    }
    setIsAnswerSubmitted(true);
    
    if(currentActiveOption===currentQuizItem.answer && isClickedOnShow===false ){
      setCurrentScore(prev=>prev+10);
      handleNextQuestion();
    }else if(currentActiveOption!==currentQuizItem.answer){
        message.error("Wrong Answer!");
    }

    if(isClickedOnShow){
      message.info("No Points for this question because you saw the answer!");
    }

    if(currentQuizItem.id === currentModuleQuizes[currentModuleQuizes.length - 1].id){
      setTimeout(()=>{
        setIsQuizFinished(true);
      },[1500])
    }
  }

  const getQuizForModule = async (item)=>{
    setCurrentCourseSection(item.title);
    //console.log("CourseSection Id : ",item.id)
    // setIsQuizLoading(true);
    try{
      const response = await CourseServices.getSingleModuleQuiz(item.id);
      if(response.data){
        console.log(response.data);
        setCurrentModuleQuizes(response.data);
        // setIsQuizLoading(false);
        let questionData = {
          ...response.data[0],
          qNumber:1,
        }
        setCurrentQuizItem(questionData);
        handleMaterialChange({type:"quiz",isFound:true});
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : "something went wrong!");
      // setIsQuizLoading(false);
      handleMaterialChange({type:"quiz",isFound:false});
    }
  }

  const getModuleQuiz =  (item)=>{

    if(isQuizStarted){
      Modal.confirm({
        title: 'Do you want to exit from Quiz?',
        icon: <ExclamationCircleOutlined />,
        content: `You are giving ${currentCourseSection} quiz`,
        okText: 'Exit',
        onOk:()=>{
          setIsQuizStarted(false);
          setCurrentActiveOption("");
          setCurrentQuizItem("");
          setIsAnswerSubmitted(false);
          setIsShowAnswer(false);
          setIsClickedOnShow(false);
          setIsLockChanges(false);
          setCurrentScore(0);
          getQuizForModule(item);
        },
        cancelText: 'Cancel',
        onCancel:()=>{
          return;
        }
      });
    }else{
     getQuizForModule(item)
    }
    
   
  }






  // const {user:currentUser} = useSelector((state)=>state.auth);
  // const dispatch = useDispatch();
  // const logOut = () => {
  //     dispatch(logout());
  //     history.push('/login');
  // }

  const onCollapseHandler = collapse => {
    setCollapsed (collapse);
  };

  useEffect (
    () => {
      let isMount = true;
      let getAllCourseSection = async () => {
        try {
          const response = await CourseServices.getCourseSection (id);
          // console.log("Course Sections : ",response.data);
          let data = response.data?.sort((a,b)=>a?.order - b?.order);
          if (isMount) {
            setCourseSections (data);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'unable to fetch course section!'
          );
        }
      };

      let getAllCourseMaterial = async () => {
        try {
          const response = await CourseServices.getCourseMaterial (id);
          // console.log("Course Material : ",response.data);
          let data = response.data?.sort((a,b)=>a?.order - b?.order);
          if (isMount) {
            setCourseMaterials (data);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'unable to fetch course material!'
          );
        }
      };

      getAllCourseSection ();
      getAllCourseMaterial ();

      return () => {
        isMount = false;
      };
    },
    [id]
  );

  const handleShowAnswer = ()=>{
    setIsShowAnswer(prev=>!prev);
   
  }

  useEffect(()=>{
    if(isShowAnswer===true){
      setIsClickedOnShow(true)
    }
  },[isShowAnswer])

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allConversations]);


  useEffect (
    () => {
      let isMounted = true;
      const getSingleStudentInCourse = async () => {
        try {
          // console.log ('data : ', {
          //   userId: currentUser.id,
          //   courseId: id,
          // });
          const response = await StudentServices.getSingleStudentInCourse ({
            userId: currentUser.id,
            courseId: id,
          });
          // console.log (response.data);
          setCurrentStudentInCourse (response.data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };
      if (id && currentUser && isMounted) {
        getSingleStudentInCourse ();
      }

      return () => {
        isMounted = false;
      };
    },
    [id, currentUser]
  );

  const handleMaterialChange = item => {
    setAllConversations([]);
    if(isAssistantView){
      setIsAssistantView(false);
    }
    
    if(isQuizStarted){
      Modal.confirm({
        title: 'Do you want to exit from Quiz?',
        icon: <ExclamationCircleOutlined />,
        content: `You are giving ${currentCourseSection} quiz`,
        okText: 'Exit',
        onOk:()=>{
          setIsQuizStarted(false);
          setCurrentActiveOption("");
          setCurrentQuizItem("");
          setIsAnswerSubmitted(false);
          setIsShowAnswer(false);
          setIsLockChanges(false);
        setIsClickedOnShow(false);
          setCurrentScore(0);
          setCurrentCourseMaterial (item);
        },
        cancelText: 'Cancel',
        onCancel:()=>{
          return;
        }
      });
    }else{
      setCurrentCourseMaterial (item);
    }
    
  };

  // useEffect(() => {
  //   console.log("Current Course Material : ",currentCourseMaterial);

  // }, [currentCourseMaterial])

  useEffect(()=>{
    let isMounted = true;
    let getProgress = async ()=>{
      try{
        const response = await StudentServices.getCourseProgress({courseId:id,studentId:currentStudentInCourse.id});
        console.log(response);
        if(response.data){
          setCurrentProgress(response.data);

        }
      }catch(err){
        console.log(err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    }

    if(isMounted && id && currentStudentInCourse){
      getProgress()
    }

    return ()=>{
      isMounted = false;
    }
  },[id,currentStudentInCourse]);


 useEffect(()=>{
  const getCourseMaterialComment = async () => {
    try {
        const response = await CourseServices.getAllCourseMaterialComments (
          { courseMaterialId:currentCourseMaterial?.id}
        );
          console.log (response.data);
          let data = []
          if(response.data && response.data.length>0){
            response?.data?.forEach(item=>{
              let isLiked = item.likedBy.includes(currentUser.id);
              let updatedData = {
                ...item,
                isLiked:isLiked
              }
              data.push(updatedData);
            })
          }
         

        setAllCourseComments(data);
      
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };
  if(currentCourseMaterial && currentUser){
    getCourseMaterialComment();
  }
 },[currentCourseMaterial,currentUser]);


 const getCourseMaterialComment = async () => {
  try {
      const response = await CourseServices.getAllCourseMaterialComments (
        { courseMaterialId:currentCourseMaterial?.id}
      );
        console.log (response.data);
        let data = []
        response.data.forEach(item=>{
          let isLiked = item.likedBy.includes(currentUser.id);
          let updatedData = {
            ...item,
            isLiked:isLiked
          }
          data.push(updatedData);
        })

      setAllCourseComments(data);
    
  } catch (err) {
    console.log (err);
    message.error (err.message ? err.message : 'something went wrong!');
  }
};

 const handleAddComment = async ()=>{
  setIsCommenting(true);
  try{
    let data = {
      comment:comment,
      userDetails:{
        username:currentUser?.username,
        profilePic:currentUser?.profilePicture,
       
      },
      courseMaterialId:currentCourseMaterial.id
    }
    console.log(data);
    const response = await CourseServices.createCourseComment(data);
    if(response){
      console.log(response.data);
      message.success("Added Your Comment Successfully!");
      setIsCommenting(false);
      getCourseMaterialComment();
    }
  }catch(err){
    console.log(err);
    message.error(err.message ? err.message : 'something went wrong!');
    setIsCommenting(false);
}
  
}

const handleRemoveComment = ()=>{
  setComment("");
  setIsFocused(false);
}


const handleAddLike = async (item)=>{
  let prevLike = item.likes ? item.likes : 0;
  let likedBy = item.likedBy;
  likedBy.push(currentUser.id);
  let finalData = {
    ...item,
    likes:prevLike+1,
    likedBy:likedBy
  }

  console.log(finalData);

  try{
    const response = await CourseServices.updateCourseComment(finalData);
    console.log(response.data);
    if(response){
      message.success("Liked Comment!");
      getCourseMaterialComment();
    }
  }catch(err){
    console.log(err);
    message.error (err.message ? err.message : 'something went wrong!');
  }

}


const handleRemoveLike = async (item)=>{
       
  let prevLike = item.likes ? item.likes : 0;
  let likedBy = item.likedBy;
  for( var i = 0; i < likedBy.length; i++){ 

    if ( likedBy[i] === currentUser.id) { 

      likedBy.splice(i, 1); 
    }

}
  let finalData = {
    ...item,
    likes:prevLike-1,
    likedBy:likedBy
  }

  console.log(finalData);

  try{
    const response = await CourseServices.updateCourseComment(finalData);
    console.log(response.data);
    if(response){
      message.success("Removed Like!");
      getCourseMaterialComment();
    }
  }catch(err){
    console.log(err);
    message.error (err.message ? err.message : 'something went wrong!');
  }

}


const handleAddReply = async (item)=>{
  setIsReplying(true);

  try{
    let  replyData = {
      username:currentUser.username,
      profilePic:currentUser.profilePicture,
      reply:reply
    }
    let replyArr = item.replies;
    replyArr.push(replyData);
    let finalData = {
      ...item,
      reply:replyArr
    }
    const response = await CourseServices.updateCourseComment(finalData);
    console.log(response.data);
    if(response){
      setIsReplying(false);
      message.success("Added Reply Successfully!");
      getCourseMaterialComment();
      handleRemoveReply();
    }
  }catch(err){
    console.log(err);
    setIsReplying(false);
    message.error (err.message ? err.message : 'something went wrong!');
  }

}
const handleRemoveReply = ()=>{
  setReply("");
  setIsReplyFocused(false);
  setCurrentReply("");
}



  const updatePercentage = (completedData)=>{
    console.log(completedData);
    let totalVideo = courseMaterials.length;
    let percentage = Math.floor((completedData.materialCompleted.length/totalVideo)*100);
    console.log("percentage : ",percentage);
    
    setCurrentProgress((prev)=>{
      let data = {...prev,progress:percentage}
      return data
    });
    StudentServices.updateCourseProgress({progress:percentage,courseId:id,userId:currentUser.id,id:currentProgress.id,projectId:null}).then((result)=>{
      console.log(result);
    }).catch(err=>{
      console.log(err);
      message.error(err.message ? err.message : 'something went wrong!');
    })
  }

 const handleCourseMaterialComplete = async (isCompleted)=>{
    let material = currentCourseMaterial;
    console.log(material,isCompleted);
   if(isCompleted){
    let materialCompleted = currentStudentInCourse.materialCompleted
    if(materialCompleted.includes(material.id)){
      return;
    }
    materialCompleted.push(material.id);
    let data = {
      ...currentStudentInCourse,
      materialCompleted:materialCompleted
    }
    try{
      const response = await StudentServices.updateCourseStudents(data);
      console.log(response.data);
      updatePercentage(data);
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message:'something went wrong!');
    }
   }else{
    let materialCompleted = currentStudentInCourse.materialCompleted
    for( var i = 0; i < materialCompleted.length; i++){ 

      if ( materialCompleted[i] === material.id) { 
  
        materialCompleted.splice(i, 1); 
      }
  
  }
    let data = {
      ...currentStudentInCourse,
      materialCompleted:materialCompleted
    }
    try{
      const response = await StudentServices.updateCourseStudents(data);
      console.log(response.data);
      updatePercentage(data);
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message:'something went wrong!');
    }
   }
 }

 const handleNextQuestion = ()=>{
   let currentQuizIndex = currentModuleQuizes.findIndex(item=>{
     return item.id===currentQuizItem.id
   });

   console.log("current index : ",currentQuizIndex);
   if(currentQuizIndex !==-1 && currentQuizIndex < currentModuleQuizes.length && currentQuizIndex !==currentModuleQuizes.length - 1){
    setCurrentActiveOption("");
    setIsAnswerSubmitted(false);
    setIsShowAnswer(false);
    setIsClickedOnShow(false);
    let nextQuestionData = {
      ...currentModuleQuizes[currentQuizIndex+1],
      qNumber:currentQuizIndex+2
    }
    setCurrentQuizItem(nextQuestionData)
   }
  
 }

 const handleResetQuiz = ()=>{
  setCurrentActiveOption("");
  setIsAnswerSubmitted(false);
  setIsShowAnswer(false);
  setIsClickedOnShow(false);
  setIsLockChanges(false);
  setCurrentScore(0);
  setIsQuizFinished(false);
  setIsQuizStarted(false);
  let questionData = {
    ...currentModuleQuizes[0],
    qNumber:1,
  }
  setCurrentQuizItem(questionData)
  
 }

 const handleFinishCancel = ()=>{
   setIsLockChanges(true);
   setIsQuizFinished(false)
 }

 const handleListenResize = (size)=>{
  // console.log(size);
  if(size <10){
    setMoveCollapseBtn(true);
  }else{
    setMoveCollapseBtn(false);
  }
 }

 const handleListenCollapse = (isCollapse)=>{
  // console.log(isCollapse);
  if(isCollapse){
    setMoveCollapseBtn(true);
  }else{
    setMoveCollapseBtn(false);
  }
 }

 const handleVideoPanelCollapseClick = ()=>{
  if(showVideoPanel){
    setMoveCollapseBtn(true);
  }else{
    setMoveCollapseBtn(false);
  }
  setShowVideoPanel(prev=>!prev);

 }


 const handleFileUploadClick = ()=>{
  fileRef.current.click();
 }

 const handleSendQuery = async ()=>{
  console.log(query);
  if(!currentQuizItem || !currentQuizItem.question || !isQuizStarted){
    message.info('Current Question not found!');
    return;
  }
  try{
    let data = {
      quiz:currentQuizItem?.question,
      query:query,
      options:currentQuizItem.options
    }
    console.log(data);
    const response = await CourseServices.getAiQuery(data);
    console.log(response.data);
    let conv = allConversations;
    conv = [...conv,{text:query,isAi:false},{text:response.data?.bot,isAi:true}];
    console.log(conv);
    setAllConversations(conv);
    setQuery("");
  }catch(err){
    console.log(err);
    message.error(err.message ? err.message : 'something went wrong!');
  }
 
 }

const handleAssistantViewClick = ()=>{
  setCurrentCourseMaterial(null);
  setIsAssistantView(true);
}

const handleFileUploadChange = async (e)=>{
  
  try{
    let file = e.target.files[0]
  if(file.type !=='application/pdf'){
    message.info("Please select only pdf files!");
    return;
  }
    setPdfUploading(true);
    
    let formData = new FormData();
    formData.append('file',file);
    console.log(file);
    fileRef.current.value = null;
    const response = await CourseServices.uploadPdfForChat(formData);
    console.log(response.data);
    setDocId(response.data?.docId);
    setFileUrl(URL.createObjectURL(file));
    setPdfUploading(false);
    
    console.log(file);
  }catch(err){
    console.log(err);
    message.error(err.message);
    setPdfUploading(false);
  }
  
}

const handleGenerateQuiz = ()=>{
  let prompt = 'Generate 5 Questions from the document with 4 options and the correct answer';
  handleSendPdfQuery(prompt);
}

const handleSendPdfQuery = async (query=pdfQuery)=>{ 
  setIsQuerying(true);
  if(!query){
    return;
  }
  let data = {
    text:query,
    isAi:false
  };
  try{
    let conv = allPdfMsgList;
    let response = await CourseServices.getDocQuery({docId:docId,message:query});
    console.log(response.data);
    conv = [...conv,data,{text:response.data?.content,isAi:true}];
    setAllPdfMsgList(conv);
    setIsQuerying(false);
    setPdfQuery("");
    console.log(conv);

  }catch(err){
    console.log(err);
    setIsQuerying(false);
    message.error(err.message ? err.message : 'Something went wrong!');
  }
 
}





 return (
    <div className="learn-course">
      {/* <h1>Course id : {id}</h1>
        {courseSections.map((item)=>{
            return <h1>section : {item.title}</h1>
        })}
        {courseMaterials.map((item)=>{
            return <h1>material : {item.title}</h1>
        })} */}
      <Layout style={{minHeight: '100vh'}}>
        <Sider
          width={'20%'}
          collapsible
          onCollapse={onCollapseHandler}
          collapsed={collapsed}
          style={{marginTop: '50px', paddingTop: '20px'}}
        >
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            {courseSections.map ((item, index) => {
              return (
                <SubMenu key={item.id} title={`${index + 1}. ${item.title}`}>
                  {courseMaterials.map (material => {
                    if (material.CourseSectionId === item.id &&
                  !material.isInstructorConnect) {
                      return (
                        <Menu.Item
                          key={material.id}
                         
                          onClick={() => {
                            handleMaterialChange (material);
                          }}
                        >
                        <p  className="course-link">
                        <b>{material.order}. {material.title}</b>
                          {currentStudentInCourse?.materialCompleted?.includes(material.id) && <BsCheckLg className="checkIcon" />}
                        </p>
                        </Menu.Item>
                      );
                    }
                    return null;
                  })}


                  {courseMaterials.map (material => {
                    if (material.CourseSectionId === item.id && material.isInstructorConnect && material.isEnabled) {
                      return (
                        <Menu.Item
                          key={material.id}
                         
                          onClick={() => {
                            handleMaterialChange (material);
                          }}
                        >
                        <p  className="course-link">
                        <span className='instructorTitleGroup'>
                        <FaChalkboardTeacher style={{fontSize:'20px',marginRight:'10px',marginTop:'5px'}} />
                        <b>{material.title}</b>
                        </span>
                        
                          {currentStudentInCourse?.materialCompleted?.includes(material.id) && <BsCheckLg className="checkIcon" />}
                        </p>
                        </Menu.Item>
                      );
                    }
                    return null;
                  })}


                      <Menu.Item
                          key={item.id}
                          onClick={() => {
                            getModuleQuiz (item);
                          }}
                        >
                        <p  className="course-link">
                        <span className='instructorTitleGroup'>
                        <AiOutlineQuestionCircle style={{fontSize:'20px',marginRight:'10px',marginTop:'5px'}} />
                        <b>Module Quiz</b>
                        </span>
                        </p>
                        </Menu.Item>

                </SubMenu>
              );
            })}
          <Menu.Item icon={<BsChatLeftText />} onClick={handleAssistantViewClick}>
            Ai Assistant
          </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout adminDashboard">
          <Header
            className="site-layout-background adminNav learnDashboard"
            style={{padding: 0}}
          >
            <div className="logoArea">
              <div> <img src={logo} alt="logo" /></div>
              <div className="logoTitles">
                <Link to="/" style={{color: 'white'}}>
                  {' '}
                  <div className="logoText">
                    Codersarts <span className="green">Learning </span>
                  </div>
                  {' '}
                </Link>
              </div>
            </div>
            <div className="navigationLinks">
              <Link to="/" className="navLink">
                <BiHomeCircle className="navIcon" /> Home
              </Link>
              <Link to="/courses" className="navLink">
                <BiBookReader className="navIcon" />Courses
              </Link>

            </div>
           <div className="progressIconWrapper">
           {currentProgress?.progress < 100 ? <GiAchievement className={'progressIcon'}/> : <AiFillTrophy className={'progressIcon'}/> }
            <Progress  type="circle" width={30} percent={currentProgress.progress ? currentProgress.progress : 0} />
           </div>
            {/* <div className='profileItems' style={{marginRight:'500px'}}> 
            <div className="nav-item profile-items">
              <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} className="profile-avatar" alt="img" />
            </div>
            <ProfileDropdown user={currentUser} name={currentUser.username} logout={logOut} />
          </div> */}
          </Header>
          <Content style={{margin: '0 16px'}}>
            {isAssistantView & !currentCourseMaterial ?
              <div className='assistantViewWrapper'>
               <div className="assistantViewHeader">
                  <h1> Ai Assistant</h1>
                  <div>
                  <input accept='application/pdf' type="file" ref={fileRef} hidden onChange={handleFileUploadChange} />
                  <Button loading={pdfUploading} type="primary" onClick={handleFileUploadClick}>Upload</Button>
                  <Button loading={pdfUploading} onClick={handleGenerateQuiz}>Generate Quiz</Button>
                  </div>
                  
                </div>

                <div className='assistantViewBody'> 
                     {fileUrl ? <div className='assistantPdfViewer'>
                      <object data={fileUrl} width="100%" height="100%">
      <p>Unable to display PDF file. <a href={fileUrl}>Download</a> instead.</p>
    </object>
                      </div>: <div className='assistantPdfViewer'>
                        <div className="pdfinfoWrapper">
                          <img src={pdfIcon} alt="pdf" />
                        <h3>Please Select a PDF</h3>
                        </div>  
                       
                      </div>}
                      <div className='assistantChat'>
                          {fileUrl ? 
                            <div className='assistant__chatMsgWrapper'>
                              <div className='assistant__chatMsgHeader'>
                              <img src={chatbot} alt='Ai' />
                              <h2>Ai Assistant</h2>
                              </div>  
                              <div className='assistant__chatMsgList' id='chat_container'>
                                { allPdfMsgList.length ===0 ? <h1 className='noMsg'>No messages</h1> : allPdfMsgList.map((item,i)=>{
           return(<div className={`wrapper ${item.isAi && 'ai'}`} ref={scrollRef}>
           <div className="chat">
           <div className="profile">
           <img
             src={`${item.isAi ? chatbot : avatar}`}
             alt={`${item.isAi ? 'bot' : 'user'}`}
           />
           <small>{item.isAi ? 'Ai' : 'You'}</small>
         </div>
         <div className="message" id={i}>
           {item.text}
         </div>
           </div>
          </div>)

          })}
                              </div>  
                             <form>
                                <textarea value={pdfQuery} onChange={(e)=>setPdfQuery(e.target.value)} name="prompt" rows="1" cols="1" placeholder="Ask Your Questions..."></textarea>
                                {isQuerying ? <Spin indicator={spinIcon} /> : <MdSend onClick={()=>handleSendPdfQuery(pdfQuery)} className="sendIcon" />}
                              </form>
                              </div>
                          
                            :
                            <div className='assistantChat__imgWrapper'>
                              <img src={chatbot} alt='Ai' className='assistantChat__img' />
                              <h1>Ask your assistant</h1>
                            </div>  
                        }
                      </div>

                </div>
              </div>
             :
            <div
              className="site-layout-background"
              style={{padding: 24, height: '100%'}}
            >

              {currentCourseMaterial
                ? 
                <div className="course-material-wrapper" >
                <Tabs 
                type="card"
                activeKey={currentActiveTab}  
                onTabClick={handleTabChange}
                defaultActiveKey='1'
                >
                  <Tabs.TabPane key="1" tab="Course Material">
                 
                <div className='course-material-header'>
                 {(currentCourseMaterial.type==='Notebook' && currentCourseMaterial.isDownloadAllowed) &&  <div className='notebook__header'>
                                          <a
                                      href={currentCourseMaterial.fileLink}
                                      target={'_blank'}
                                      download
                                      rel="noreferrer"
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'var(--green)',
                                        display:'flex',
                                        alignItems:'center'
                                      }}
                                    >
                                      <MdFileDownload
                                        style={{
                                          fontSize: '1.2rem',
                                          color: 'var(--green)',
                                        }}
                                      />
                                      
                                      Download {currentCourseMaterial.title}
                                          </a>
                                          </div>
                      } 
                {currentCourseMaterial.type !=="quiz" &&
                <div className="markAsCompletedCheck">
               <Checkbox checked={currentStudentInCourse?.materialCompleted?.includes(currentCourseMaterial.id)} onChange={(e)=>handleCourseMaterialComplete(e.target.checked)}></Checkbox> 
                <h4>Mark as Completed</h4>
               </div>
                }
              
                </div>
                {currentCourseMaterial.type !== 'Video' && <h1>{currentCourseMaterial?.title}</h1>}
                {currentCourseMaterial.type==='Notebook' && <p>{currentCourseMaterial?.textData}</p>}
                    <div className="material-content">
                      {currentCourseMaterial.type === 'Text'
                        ? <div className="text-data">
                            {currentCourseMaterial.textData}
                          </div>
                        : currentCourseMaterial.type === 'Video'
                            ? <div className="video-data">
                                <ReactPlayer
                                  url={currentCourseMaterial.fileLink}
                                  width={'100%'}
                                  playing={false}
                                  controls={true}
                                />
                                <div className="description">
                                <h1>{currentCourseMaterial.title}</h1>
                                  {currentCourseMaterial.textData &&
                                    currentCourseMaterial.textData}
                                </div>
                              </div>

                            : 
                            currentCourseMaterial.type==='Document' ? 
                            <div className="resource-data">
                                    
                                    <div>
                                    <iframe title={currentCourseMaterial.title} width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${currentCourseMaterial.fileLink}&embedded=true`}></iframe>
                                    </div>
                                    <a
                                      href={currentCourseMaterial.fileLink}
                                      target={'_blank'}
                                      download
                                      rel="noreferrer"
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'var(--green)',
                                      }}
                                    >
                                      
                                      Download {currentCourseMaterial.title}
                                    </a>
                                    <div className="description">
                                      {currentCourseMaterial.textData &&
                                        currentCourseMaterial.textData}
                                    </div>
                                  </div>
                            :
                            currentCourseMaterial.type === 'Resource'
                                ? <div className="resource-data">
                                    <a
                                      href={currentCourseMaterial.fileLink}
                                      target={'_blank'}
                                      download
                                      rel="noreferrer"
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'var(--green)',
                                      }}
                                    >
                                      <MdFileDownload
                                        style={{
                                          fontSize: '5rem',
                                          color: 'var(--green)',
                                        }}
                                      />
                                      <br />
                                      Download {currentCourseMaterial.title}
                                    </a>
                                    <div className="description">
                                      {currentCourseMaterial.textData &&
                                        currentCourseMaterial.textData}
                                    </div>
                                  </div>
                                : 

                                currentCourseMaterial.type === 'Notebook'
                                ? <div className="notebookRenderWrapper">

                                  <PanelGroup autoSaveId="example" direction="horizontal">
                                           
                                              <>
                                              {moveCollapseBtn && <Button size="small"  onClick={handleVideoPanelCollapseClick}>{showVideoPanel ? <Tooltip title="Hide Video?"><BiChevronRight style={{fontSize:'1.5rem'}} /></Tooltip> :  <Tooltip title="Show Video?"><BiChevronLeft style={{fontSize:'1.5rem'}}  /></Tooltip>}</Button>}
                                                <Panel
                                                  className={"Panel"}
                                                  collapsible={true}
                                                  defaultSize={60}
                                                  order={1}
                                                 
                                                >
                                                 <iframe style={{width:'100%'}} title={currentCourseMaterial.title} src={currentCourseMaterial.fileLink}></iframe>
                                  
                                                </Panel>
                                               
                                                <ResizeHandle handleVideoPanelCollapseClick={handleVideoPanelCollapseClick} moveCollapseBtn={moveCollapseBtn} id={"panel1"} showVideoPanel={showVideoPanel} setShowVideoPanel={setShowVideoPanel} />
                                              </>
                                           
                                            {showVideoPanel && (
                                              <>
                                              
                                                <Panel
                                                  className={"Panel"}
                                                  collapsible={true}
                                                  defaultSize={40}
                                                  order={2}
                                                  onResize={handleListenResize}
                                                  onCollapse={handleListenCollapse}
                                                  
                                                >
                                              <video controls style={{width:'100%'}}>
                                                 <source type="video/mp4" src={currentCourseMaterial.explanationVideo} />
                                             </video>
                                                </Panel>
                                               
                                              </>
                                            )}
                                          </PanelGroup>
                                      
                                     {/* <div className='notebookRenderBody'>
                                     <div className='notebook__area'>
                                         
                                     
                                        
                                         <iframe title={currentCourseMaterial.title} src={currentCourseMaterial.fileLink}></iframe>
                                         
     
                                       </div>
     
                                           <div className='notebook__video'>
                                           <video controls>
                                                 <source type="video/mp4" src={currentCourseMaterial.explanationVideo} />
                                             </video>
                                           </div>  
                                     </div>  */}

                                   

                                  </div>

                                  :
                                
                                currentCourseMaterial.type === 'Rich Text'
                                    ? <div
                                        style={{
                                          textAlign: 'left',
                                          height: '100%',
                                        }}
                                      >
                                        {/* <Editor editorState={getEditorState(JSON.parse(item.textData))} readOnly={true} />  */}
                                        {/* <div>{ ReactHtmlParser(currentCourseMaterial.textData) }</div>; */}
                                        <ReactQuill
                                          modules={modules}
                                          value={currentCourseMaterial.textData}
                                          readOnly
                                          theme="snow"
                                        />
                                      </div>
                                    : 
                                    currentCourseMaterial.type === 'quiz' ?
                                        
                                      <div className='quizWrapper'>
                                          {currentCourseMaterial.isFound ?
                                          currentModuleQuizes.length > 0 ? 
                                          <div className='quizArea'>
                                            {isQuizStarted ? 
                                               <>
                                               <div className="quixArea__header">
                                               <div className='quizArea__score'>
                                                  <b>Scores : </b>
                                                  <span><b>{currentScore}</b> out of {currentModuleQuizes.length * 10}</span>
                                               </div>
                                               <Tooltip title="Get Help from Ai Assistant!">
                                               <Button onClick={()=>handleTabChange('3')} type="primary"><BulbOutlined />Get Hint</Button>
                                                </Tooltip>
                                               </div>
                                              
                                               <div className='quizArea__question'>
                                                  <h2>Q.{currentQuizItem?.qNumber}: </h2>
                                                  <ReactQuill 
                                                    value={currentQuizItem?.question}
                                                    readOnly
                                                    modules={modules}
                                                  /> 
                                                </div>
                                                <ol className='quizArea__optionsWrapper' type="A">
                                                    {currentQuizItem?.options?.map((item,index)=>{
                                                      return(
                                                        <li className={`quizArea__option ${currentActiveOption===item ? 'activeOption':''} ${(isAnswerSubmitted && currentQuizItem.answer===item) ? 'rightOption' : isAnswerSubmitted && currentQuizItem.answer!==item ? 'wrongOption' : ''}`} onClick={()=>handleActiveOption(item)} key={index}>
                                                           <span style={{display:'flex'}}> <span style={{marginRight:'10px'}}>{optionLetters[index]}.</span> <ReactQuill readOnly modules={modules} style={{border:'none'}} value={item} /></span>
                                                            <span>
                                                              {(isAnswerSubmitted && currentQuizItem.answer===item) ? <FaCheck className='checkIcon' /> : (isAnswerSubmitted && currentQuizItem.answer!==item) ? <FaTimes style={{color:"red"}} /> : ''}
                                                            </span>
                                                        </li>
                                                      )
                                                    })}
                                                </ol>

                                              <div className={`quizArea__showAnswer ${isAnswerSubmitted ? 'visibleShowAnswer' : ''}`}>
                                                  <h5><b>You Selected {currentActiveOption===currentQuizItem.answer ? 'Right Option' : 'Wrong Option'} : </b><span><ReactQuill readOnly modules={modules} style={{marginTop:'10px'}} value={currentActiveOption} /></span></h5>
                                              
                                              </div>
                                              <div className={`quizArea__showAnswer ${isShowAnswer ? 'visibleShowAnswer' : ''}`}>
                                                  <h5><b>Ans: </b><span><ReactQuill readOnly modules={modules} style={{border:'none'}} value={currentQuizItem.answer} /></span></h5>
                                                  {currentQuizItem.explanation && <h5>Explanation: {currentQuizItem.explanation}</h5>}
                                              </div>
                                                
                                                <div className="quizArea__btnArea">
                                                    <Button style={{marginRight:"10px"}} onClick={handleShowAnswer}>{isShowAnswer ? "Hide Answer" : "Show Answer"}</Button>
                                                    <Button disabled={currentActiveOption===""} type="primary" onClick={checkCurrentAnswer}>Submit</Button>
                                                    <Button disabled={currentQuizItem.qNumber===currentModuleQuizes.length} style={{marginLeft:"10px"}} onClick={handleNextQuestion}>Next Question</Button>
                                                    
                                                </div>
                                               </>
                                            :
                                            <div className='quizStartScreen'>
                                            <img src={brainImg} alt="quiz" />
                                              <div className='quizStartScreenInfo'>
                                                <h2>{currentCourseSection} Quiz</h2>
                                                <h4>Total Quiz : {currentModuleQuizes.length}</h4>
                                                <Button onClick={()=>setIsQuizStarted(true)}><b>Start Quiz</b></Button>
                                              </div>
                                            </div>
                                            }
                                            
                                          </div>
                                          :
                                          <div className="no-data">
                                              <img src={noData} alt="select" />
                                              <h2>No Quiz Found For this module!</h2>
                                          </div>

                                             
                                         
                                          :
                                         
                                          <div className="no-data">
                                              <img src={noData} alt="select" />
                                              <h2>No Quiz Found For this module!</h2>
                                            </div> }
                                      </div>

                                    : null
                                    
                                    }
                    </div>
                
                  </Tabs.TabPane>

                  <Tabs.TabPane key="2" tab="Q&A">
                  <div className={styles.addComment}>
        <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} className={styles.profilePic} alt="img" />
        <input onFocus={()=>setIsFocused(true)} type="text" placeholder={`Commenting publicly as ${currentUser?.username}`} value={comment} onChange={(e)=>setComment(e.target.value)} className={styles.addCommentInput} />
          
        </div>
        {isFocused &&
            <div className={styles.commentBtnArea}>
              <Button onClick={handleRemoveComment} style={{marginRight:'10px'}}>Cancel</Button>
              <Button type='primary'  disabled={comment===""} loading={isCommenting} onClick={handleAddComment}>Comment</Button>
            </div>
            }
        <div className={styles.allCommentArea} style={{textAlign:'left'}}>
          <h3>Recent Comments</h3>
          {allCourseComments.map((item)=>{
            return(
              <div key={item.id}>
              <div className={styles.singleComment}>
                <div>
                {  <img src={item.userDetails.profilePic ? item.userDetails.profilePic : avatar} className={styles.profilePic} alt="img" />}
                </div>
               <div className={styles.singleComment__info}>
                <div><b>{item?.userDetails?.username}</b></div>
                <div>{item?.comment}</div>
                  
                  <div className={styles.likeArea}>
                  {item.isLiked ? 
                    <AiFillLike title={"Remove Like?"} style={{fontSize:'1rem',cursor:'pointer',color:'var(--green)'}} onClick={()=>handleRemoveLike(item)}/>
                  :
                  <AiOutlineLike title={"Like?"} style={{fontSize:'1rem',cursor:'pointer'}} onClick={()=>handleAddLike(item)}/>
                  }
                 
                  <span>{item.likes > 0 && item.likes}</span>
                  <b className={styles.replyText} onClick={()=>setCurrentReply(item.id)}>Reply</b>
                  </div>
                  

               </div>
              </div>
             
              {currentReply===item.id && 
              <div className={styles.replyArea}>
              <div className={styles.addComment}>
                  <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} style={{width:'30px',height:'30px'}} className={styles.profilePic} alt="img" />
                  <input onFocus={()=>setIsReplyFocused(true)} type="text" placeholder={`Replying publicly as ${currentUser?.username}`} value={reply} onChange={(e)=>setReply(e.target.value)} className={styles.addCommentInput} />
                  
                </div>
              {isReplyFocused &&
            <div className={styles.commentBtnArea}>
              <Button onClick={handleRemoveReply} style={{marginRight:'10px'}}>Cancel</Button>
              <Button type='primary' disabled={reply===""} loading={isReplying} onClick={()=>handleAddReply(item)}>Reply</Button>
            </div>
            }
              </div>
               
              }

              {item.replies.length > 0 &&
                <Collapse  ghost style={{marginLeft:'6%'}}>
                  <PanelCollapse header={`${item.replies.length} Replies`} key={item.id}>
                  {item.replies.map((reply,index)=>{
                    return(
                      <div className={styles.singleComment} key={index}>
                          <div>
                          {  <img style={{width:'30px',height:'30px'}} src={reply.profilePic ? reply.profilePic : avatar} className={styles.profilePic} alt="img" />}
                          </div>
                        <div className={styles.singleComment__info} style={{fontSize:'12px'}}>
                          <div><b>{reply?.username}</b></div>
                          <div>{reply?.reply}</div>
                        </div>
                      </div>
                    )
                  })}
                  </PanelCollapse>
                </Collapse>
              }

             
             
              </div>
            )
          })}
        </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="3" tab="Ai Assistance">
                    <div >
                    <h3>Ask Your Doubts Here</h3>
                    
                      <div id="assistant">
      <div className="chat_container" id="chat_container">
          {allConversations.length ===0 ? <img src={chatbot} alt='Ai' className='chatbotImg' /> : 
          allConversations.map((item,i)=>{
           return(<div className={`wrapper ${item.isAi && 'ai'}`} ref={scrollRef}>
           <div className="chat">
           <div className="profile">
           <img
             src={`${item.isAi ? chatbot : avatar}`}
             alt={`${item.isAi ? 'bot' : 'user'}`}
           />
           <small>{item.isAi ? 'Ai' : 'You'}</small>
         </div>
         <div className="message" id={i}>
           {item.text}
         </div>
           </div>
          </div>)

          })
          }
      </div>

      <form>
        <textarea value={query} onChange={(e)=>setQuery(e.target.value)} name="prompt" rows="1" cols="1" placeholder="Ask Your Doubts..."></textarea>
        <MdSend onClick={handleSendQuery} className="sendIcon" />
      </form>

    </div>
                    </div>

                  </Tabs.TabPane>
                </Tabs>  
                </div>
              
                : <div className="no-data">
                    <img src={noData} alt="select" />
                    <h2>Please Select a Course Material to study!</h2>
                  </div>}

                  <Modal
                    visible={isQuizFinished}
                    onCancel={handleFinishCancel}
                    onOk={handleResetQuiz}
                    okText={"Reset Quiz"}
                  >

                  
                  <div className='quizCompleted'>
                  <img src={brainLearning} alt="brain" />
                    <h3>Quiz Completed</h3>
                    <div className='quizCompleted__scoreArea'>
                      You Scored : <b>{currentScore}</b>
                    </div>
                  </div>

                  </Modal>
            </div>
}
          </Content>
          <Footer style={{textAlign: 'center'}}>Codersarts</Footer>
        </Layout>
      </Layout>

    </div>
  );
}

export default LearnCourse;
