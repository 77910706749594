import React, {useState, useEffect} from 'react';
import './DashboardHomeAdmin.css';
import {BiBookReader} from 'react-icons/bi';
import {UserOutlined} from '@ant-design/icons';
import {VscProject} from 'react-icons/vsc';
import CourseServices from '../../../services/courses.service';
import {message} from 'antd';
import StudentServices from '../../../services/students.service';
import {useSelector} from 'react-redux';
import ProjectServices from '../../../services/projects.service';

function DashboardHomeAdmin () {
  const [allCoursesLength, setAllCoursesLength] = useState (0);
  const [courseCount, setCourseCount] = useState ('0');
  const [studentsCount, setStudentsCount] = useState (0);
  const [allProjectsCount, setAllProjectsCount] = useState (0);

  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      let isMount = true;
      let getAllEnrolledStudents = async () => {
        try {
          const response = await StudentServices.getAllEnrolledStudents (
            currentUser.id
          );
          let filteredStudents = [];
          // console.log (response.data);
          if (response.data.length > 0) {
            filteredStudents = response.data.filter (
              (value, index, self) =>
                index ===
                self.findIndex (
                  t =>
                    t.username === value.username && t.userId === value.userId
                )
            );
          }

          // console.log ('Filtered Result : ', filteredStudents);
          if (isMount) {
            setStudentsCount (filteredStudents.length);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'unable to fetch students record!'
          );
        }
      };

      if (currentUser) {
        getAllEnrolledStudents ();
      }

      return () => {
        isMount = false;
      };
    },
    [currentUser]
  );

  const getAllCourses = async () => {
    try {
      const response = await CourseServices.getAllCourses ();
      // console.log(response.data);

      setAllCoursesLength (response.data.length);
    } catch (err) {
      // console.log(err);
      message.error (
        err.message ? err.message : 'Unable to fetch courses length!'
      );
    }
  };
  useEffect (() => {
    getAllCourses ();
  }, []);

  const getAllProjects = async () => {
    try {
      const response = await ProjectServices.getAllProjects ();

      setAllProjectsCount (response.data.length);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to fetch projects!');
    }
  };
  useEffect (() => {
    getAllProjects ();
  }, []);

  useEffect (
    () => {
      let start = 0;
      // first three numbers from props
      const end = parseInt (allCoursesLength.toString ().substring (0, 3));
      // if zero, return
      if (start === end) return;

      // find duration per increment
      let totalMilSecDur = parseInt (1);
      let incrementTime = totalMilSecDur / end * 500;

      // timer increments start counter
      // then updates count
      // ends if start reaches end
      let timer = setInterval (() => {
        start += 1;
        setCourseCount (
          String (start) + allCoursesLength.toString ().substring (3)
        );
        if (start === end) clearInterval (timer);
      }, incrementTime);

      // dependency array
    },
    [allCoursesLength]
  );
  return (
    <div className="dashboardHome">
      <div className="statCard">
        <div>
          <BiBookReader className="icon" />
        </div>
        <div className="cardLabel">
          <div><div className="h1">{courseCount}</div></div>
          <div><small>Courses</small></div>
        </div>
      </div>
      <div className="statCard" id="pink">
        <div>
          <UserOutlined className="icon" />
        </div>
        <div className="cardLabel">
          <div><div className="h1">{studentsCount}</div></div>
          <div><small>Students</small></div>
        </div>
      </div>

      <div className="statCard" id="orange">
        <div>
          <VscProject className="icon" />
        </div>
        <div className="cardLabel">
          <div><div className="h1">{allProjectsCount}</div></div>
          <div><small>Projects</small></div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHomeAdmin;
