import axios from 'axios';
import authHeader from './auth-header';

// const SERVER_URL = 'http://localhost:8000/';
const SERVER_URL = 'https://learn.codersarts.com/';

const createNewProgram = data => {
  return axios.post (
    SERVER_URL + 'create-new-program',
    {...data},
    {headers: authHeader ()}
  );
};

const getAllPrograms = () => {
  return axios.get (SERVER_URL + 'get-all-programs');
};

const deleteProgramById = (id, userId) => {
  return axios.post (
    SERVER_URL + 'delete-program-by-id',
    {id: id, userId: userId},
    {headers: authHeader ()}
  );
};

const updateProgramById = data => {
  return axios.post (
    SERVER_URL + 'update-program-by-id',
    {...data},
    {headers: authHeader ()}
  );
};

const getProgramById = id => {
  return axios.post (SERVER_URL + 'get-program-by-id', {id: id});
};

const ProgramServices = {
  createNewProgram,
  getAllPrograms,
  deleteProgramById,
  updateProgramById,
  getProgramById,
};

export default ProgramServices;
