import React from 'react';
import './LearningMethods.css';
import {MdOutlineOndemandVideo} from 'react-icons/md';
import {IoReaderOutline} from 'react-icons/io5';
import {AiOutlineCode} from 'react-icons/ai';


function LearningMethods() {
  return(
    <div className='learningMethods'>
        <h1>Explore Different Methods of Learning!</h1>
        <div className='methods'>
          <div className='card'>  
              <MdOutlineOndemandVideo className='methodIcon' />
              <div className='methodTitle'>
                <h3>Learn By Watching</h3>
              </div>
              <div className='methodDesc'>
                  Watch a course video to learn from our experts!
              </div>
          </div>

          <div className='card'>  
          <IoReaderOutline className='methodIcon' />
              <div className='methodTitle'>
                <h3>Learn By Reading</h3>
              </div>
              <div className='methodDesc'>
                  You can get the course knowledge through the exceptional reading material!
              </div>
          </div>


          <div className='card'>  
              <AiOutlineCode className='methodIcon' />
              <div className='methodTitle'>
                <h3>Learn By Doing</h3>
              </div>
              <div className='methodDesc'>
                  You can Apply your knowledge and Learnings in our Online Code Editor!
              </div>
          </div>

        </div>
    </div>  
  );
}

export default LearningMethods;
