import axios from 'axios';
import authHeader from './auth-header';

// const SERVER_URL = 'http://localhost:8000/';
const SERVER_URL = 'https://learn.codersarts.com/';

const createNewProject = data => {
  return axios.post (
    SERVER_URL + 'create-new-project',
    {...data},
    {headers: authHeader ()}
  );
};

const getAllProjects = () => {
  return axios.get (SERVER_URL + 'get-all-projects');
};

const deleteProjectById = (id, userId) => {
  return axios.post (
    SERVER_URL + 'delete-project-by-id',
    {id: id, userId: userId},
    {headers: authHeader ()}
  );
};

const createNewProjectCategory = (title, userId) => {
  return axios.post (
    SERVER_URL + 'create-new-project-category',
    {title: title, userId: userId},
    {headers: authHeader ()}
  );
};

const getAllProjectCategory = () => {
  return axios.get (SERVER_URL + 'get-project-category', {
    headers: authHeader (),
  });
};

const updateProjectById = data => {
  return axios.post (
    SERVER_URL + 'update-project-by-id',
    {...data},
    {headers: authHeader ()}
  );
};

const getProjectById = id => {
  return axios.post (SERVER_URL + 'get-project-by-id', {id: id});
};

const createProjectMaterial = data => {
  return axios.post (SERVER_URL + 'create-project-material', data);
};

const getProjectMaterialByProjectId = id => {
  return axios.post (SERVER_URL + 'get-project-material-by-projectid', {
    projectId: id,
  });
};

const deleteProjectMaterialById = (id, userId) => {
  return axios.post (
    SERVER_URL + 'delete-project-material-by-id',
    {id: id, userId: userId},
    {headers: authHeader ()}
  );
};

const getAllProjectMaterial = () => {
  return axios.get (SERVER_URL + 'get-all-project-material');
};

const createComment = data => {
  return axios.post (SERVER_URL + 'create-comment', data);
};

const getAllProjectMaterialComments = data => {
  return axios.post (SERVER_URL + 'get-material-comment', data);
};

const updateComment = data => {
  return axios.post (SERVER_URL + 'update-comment', data);
};

const createProjectProgress = data => {
  return axios.post (SERVER_URL + 'create-project-progress', data);
};

const updateProjectProgress = data => {
  return axios.post (SERVER_URL + 'update-project-progress', data);
};

const getProgressByProjectId = data => {
  return axios.post (SERVER_URL + 'get-project-progress', data);
};

const checkIsEnrolledInProject = data => {
  return axios.post (SERVER_URL + 'check-is-enroll-in-project', data);
};

const enrollStudentInProject = data => {
  return axios.post (SERVER_URL + 'enroll-student-in-project', data);
};

const updateProjectStudents = data => {
  return axios.post (SERVER_URL + 'update-project-students', data);
};

const getProjectStudentDetails = data => {
  return axios.post (SERVER_URL + 'get-enrolled-student-in-project', data);
};

const getAllEnrolledProjects = data => {
  return axios.post (SERVER_URL + 'get-all-enrolled-projects', data);
};

const getAllFeedback = data => {
  return axios.post (SERVER_URL + 'get-feedback-of-project', data);
};

const createNewFeedback = data => {
  return axios.post (SERVER_URL + 'add-feedback-to-projects', data);
};

const updateProjectMaterialById = data => {
  return axios.post (SERVER_URL + 'update-project-material-by-id', data);
};

const ProjectServices = {
  createNewProject,
  getAllProjects,
  deleteProjectById,
  createNewProjectCategory,
  getAllProjectCategory,
  updateProjectById,
  getProjectById,
  createProjectMaterial,
  getProjectMaterialByProjectId,
  deleteProjectMaterialById,
  getAllProjectMaterial,
  createComment,
  getAllProjectMaterialComments,
  updateComment,
  createProjectProgress,
  updateProjectProgress,
  getProgressByProjectId,
  checkIsEnrolledInProject,
  enrollStudentInProject,
  updateProjectStudents,
  getProjectStudentDetails,
  getAllEnrolledProjects,
  getAllFeedback,
  createNewFeedback,
  updateProjectMaterialById,
};

export default ProjectServices;
