import './App.css';
import Landing from './Pages/Landing/Landing';
import {Router, Route, Switch} from 'react-router-dom';
import Login from './Pages/AuthenticationPage/Login/Login';
import ErrorPage from './components/ErrorPage/ErrorPage';
import {history} from './helpers/history';
import RouteWithNavBar from './RouteWithNavBar';
import Register from './Pages/AuthenticationPage/Register/Register';
import Dashboard from './Pages/Dashboard/Dashboard';
import Courses from './Pages/Courses/Courses';
import RouteForAdmin from './RouteForAdmin';
import AdminDashboard from './Pages/AdminDashboard/AdminDashboard';
import RouteWithNavBarWithoutLoggedIn from './RouteWithNavBarWithoutLoggedIn';
import SingleCourseDetails
  from './Pages/Courses/SingleCourseDetails/SingleCourseDetails';
import LearnCourse from './Pages/LearnCourse/LearnCourse';
import PreviewCourse from './Pages/Courses/PreviewCourse/PreviewCourse';
import CodeEditor from './Pages/CodeEditor/CodeEditor';
import AllProjects from './Pages/AllProjects/AllProjects';
import ProjectDetails from './Pages/ProjectDetails/ProjectDetails';
import StartProject from './Pages/StartProject/StartProject';
import AllPrograms from './Pages/AllPrograms/AllPrograms';
import ViewProgramDetails from './Pages/ViewProgramDetails/ViewProgramDetails';
import PaymentCourse from './Pages/Courses/PaymentCourse/PaymentCourse';
import PaymentSuccess from './components/CheckoutForm/PaymentSuccess';
import PaymentIndia from './Pages/PaymentIndia/PaymentIndia';
function App () {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <RouteWithNavBarWithoutLoggedIn
            exact
            path={['/', '/home']}
            component={Landing}
          />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/code-editor" component={CodeEditor} />
          <RouteWithNavBar exact path="/courses" component={Courses} />

          <RouteWithNavBar
            exact
            path="/courses/view-course-details/:slug"
            component={SingleCourseDetails}
          />
          <RouteWithNavBar
            exact
            path="/courses/material-preview/:id"
            component={PreviewCourse}
          />
           <Route
            exact
            path="/courses/pay/:id"
            component={PaymentCourse}
          />
           <Route
            exact
            path="/courses/in/pay/:id"
            component={PaymentIndia}
          />
           <Route
            exact
            path="/payment/success/:courseId/:userId/:intent"
            component={PaymentSuccess}
          />
          <Route
            exact
            path="/courses/learn-course/:id"
            component={LearnCourse}
          />
          <RouteWithNavBar
            exact
            path="/project-details/:id"
            component={ProjectDetails}
          />
          <RouteWithNavBar
            exact
            path="/project-details/start/:id"
            component={StartProject}
          />
          <RouteWithNavBar exact path="/all-projects" component={AllProjects} />
          {/* <RouteWithNavBar exact path="/all-programs" component={AllPrograms} /> */}
          <RouteWithNavBar
            exact
            path="/program-details/:id"
            component={ViewProgramDetails}
          />
          <RouteForAdmin path="/admin/dashboard" component={AdminDashboard} />
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
