import React, {useEffect, useState} from 'react';
import styles from './ModuleQuiz.module.css';
import CourseServices from '../../../../services/courses.service';
import ImageResize from 'quill-image-resize-module-react';
import {
  message,
  Menu,
  Input,
  Dropdown,
  Space,
  Button,
  Collapse,
  Select,
  Tooltip,
  Drawer,
} from 'antd';
import {DownOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';

Quill.register ('modules/imageResize', ImageResize);

const modules = {
  syntax: {highlight: text => hljs.highlightAuto (text).value},
  toolbar: {
    container: [
      [{font: []}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ['bold', 'italic', 'underline', 'strike'],
      [{color: []}, {background: []}],
      [{script: 'sub'}, {script: 'super'}],
      ['blockquote', 'code-block'],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{indent: '-1'}, {indent: '+1'}, {align: []}],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    handlers: {
      image: imageHandler,
    },
  },
  imageResize: {
    parchment: Quill.import ('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
};

const modulesRead = {
  syntax: {highlight: text => hljs.highlightAuto (text).value},
  toolbar: false,
};

function imageHandler () {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const range = this.quill.getSelection (true);
    const value = this.textbox.value;
    if (value) {
      this.quill.insertEmbed (range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide ();
  };
  tooltip.edit ('image');
  tooltip.textbox.placeholder = 'Embed URL';
}

const {Panel} = Collapse;
const {Option} = Select;
function ModuleQuiz({courseDetails}) {
  const [courseSections, setCourseSections] = useState ([]);
  const [loading, setLoading] = useState (false);
  const [isAddQuiz, setIsAddQuiz] = useState (false);
  const [currentQuizType, setCurrentQuizType] = useState ('moduleQuiz');
  const [question, setQuestion] = useState ('');
  const [answer, setAnswer] = useState ('');
  const [quizCourseSection, setQuizCourseSection] = useState ('');
  const [currentOption, setCurrentOption] = useState ('');
  const [allOptions, setAllOptions] = useState ([]);
  const [allModuleQuizes, setAllModuleQuizes] = useState ([]);
  const [currentEditQuiz, setCurrentEditQuiz] = useState ('');
  const [updatedQuestion, setUpdatedQuestion] = useState ('');
  const [updatedAnswer, setUpdatedAnswer] = useState ('');
  const [updatedQuizCourseSection, setUpdatedQuizCourseSection] = useState ('');
  const [updatedAllOptions, setUpdatedAllOptions] = useState ([]);
  const [isEditQuiz, setIsEditQuiz] = useState (false);
  const [currentUpdatedOption, setCurrentUpdatedOption] = useState ('');
  const [explanation,setExplanation] = useState("");
  const [updatedExplanation,setUpdatedExplanation] = useState("");
  const {user: currentUser} = useSelector (state => state.auth);

  const getAllModuleQuizes = async () => {
    // console.log (courseDetails);
    try {
      const result = await CourseServices.getAllModuleQuiz ();
      //   console.log (result);
      if (result) {
        setAllModuleQuizes (result.data);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleSubmitQuiz = async () => {
    setLoading (true);
    // console.log ('Add Quiz : ', currentQuizType);
    let data = {
      CourseSectionId: quizCourseSection,
      question: question,
      options: allOptions,
      answer: answer,
      userId: currentUser.id,
      explanation:explanation
    };

    console.log (data);

    try {
      const response = await CourseServices.createNewModuleQuiz (data);
      console.log (response.data);
      if (response) {
        setLoading (false);
        setIsAddQuiz (false);
        setQuestion ('');
        setAnswer ('');

        setAllOptions ([]);
        message.success ('Quiz Added Successfully!');
        getAllModuleQuizes ();
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'something went wrong!');
    }

    // console.log (data);
  };

  const handleAddOption = () => {
    setAllOptions (prev => [...prev, currentOption]);
    setCurrentOption ('');
  };

  const handleOptionDelete = index => {
    // let copiedArr = allOptions;

    setAllOptions (prev => {
      let filtered = prev.filter ((item, i) => {
        return i !== index;
      });
      return filtered;
    });
    // setAllOptions (prev => copiedArr);
  };
  useEffect (
    () => {
      let isMounted = true;

      const getAllCourseSection = async () => {
        // console.log (courseDetails);
        try {
          const result = await CourseServices.getCourseSection (
            courseDetails.id
          );
          //   console.log (result);
          if (result) {
            setCourseSections (result.data);
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      const getAllModuleQuizes = async () => {
        // console.log (courseDetails);
        try {
          const result = await CourseServices.getAllModuleQuiz ();
          //   console.log (result);
          if (result) {
            setAllModuleQuizes (result.data);
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      if (isMounted) {
        getAllCourseSection ();
        getAllModuleQuizes ();
      }

      return () => {
        isMounted = false;
      };
    },
    [courseDetails]
  );

  const handleAddQuiz = e => {
    // console.log ('quiz : ', e);

    if (courseSections.length === 0 || !courseSections) {
      message.info ('No Course Module Found!');
      message.warning ("Can't add any quiz!");
      return;
    }
    setCurrentQuizType (e.key);
    setIsAddQuiz (true);
  };

  const handleCancelQuiz = () => {
    setIsAddQuiz (false);
  };

  const handleAddUpdatedOption = () => {
    setUpdatedAllOptions (prev => [...prev, currentUpdatedOption]);
    setCurrentUpdatedOption ('');
  };
  const menu = (
    <Menu onClick={handleAddQuiz}>
      <Menu.Item key="moduleQuiz">Module Quiz</Menu.Item>
      <Menu.Item key="finalQuiz">Final Quiz</Menu.Item>
    </Menu>
  );

  const handleQuestionModule = value => {
    // console.log ('section : ', value);
    setQuizCourseSection (value);
  };

  const handleEditQuiz = quiz => {
    // console.log(quiz);
    setCurrentEditQuiz (quiz);
    setUpdatedQuestion (quiz.question);
    setUpdatedAnswer (quiz.answer);
    setUpdatedAllOptions (quiz.options);
    setUpdatedQuizCourseSection (quiz.CourseSectionId);
    setUpdatedExplanation(quiz?.explanation);
    setIsEditQuiz (true);
  };

  const handleQuizDelete = async quiz => {
    try {
      const response = await CourseServices.deleteModuleQuiz ({
        id: quiz.id,
        userId: currentUser.id,
      });
      if (response) {
        console.log (response.data);
        message.success ('Quiz Deleted Successfully!');
        getAllModuleQuizes ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const handleUpdateQuiz = async () => {
    // console.log ('updating');
    setLoading (true);
    let data = {
      ...currentEditQuiz,
      question: updatedQuestion,
      answer: updatedAnswer,
      options: updatedAllOptions,
      CourseSectionId: updatedQuizCourseSection,
      userId: currentUser.id,
      explanation:updatedExplanation
    };

    // console.log ('final data : ', data);
    try {
      const response = await CourseServices.updateModuleQuiz (data);
      if (response) {
        console.log (response.data);
        message.success ('Quiz Updated Successfully!');
        setLoading (false);
        setIsEditQuiz (false);
        getAllModuleQuizes ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setLoading (false);
    }
  };

  const handleCancelEditModal = () => {
    setIsEditQuiz (false);
  };

  const handleDeleteUpdatedOptions = index => {
    setUpdatedAllOptions (prev => {
      let filtered = prev.filter ((item, i) => {
        return i !== index;
      });
      return filtered;
    });
  };
  return (
    <div className={styles.moduleQuiz}>
      <div className={styles.moduleQuiz__header}>
        <h2>Quizzes</h2>
        <Dropdown
          overlay={menu}
          arrow={{
            pointAtCenter: true,
          }}
        >
          <Button size={'large'}>
            <Space>
              Add Quiz
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>

      <div className={styles.moduleQuiz__body}>
        {courseSections.length === 0
          ? <h3>No Course Section Added</h3>
          : <Collapse className={styles.moduleQuiz__collapse}>
              {courseSections.map ((item, index) => {
                return (
                  <Panel header={item.title} key={index}>
                    {allModuleQuizes.map ((quiz,i) => {
                      if (quiz.CourseSectionId === item.id) {
                        return (
                          <div className={styles.option} key={quiz.id}>
                          
                              <ReactQuill
                                value={quiz.question}
                                readOnly
                                modules={modulesRead}
                              />
                           
                            <span>
                              <Tooltip title={'Edit Quiz'}>
                                <EditOutlined
                                  onClick={() => handleEditQuiz (quiz)}
                                  className={styles.quizIcon}
                                  style={{color: 'var(--green)'}}
                                />
                              </Tooltip>
                              <Tooltip title={'Delete Quiz'}>
                                <DeleteOutlined
                                  onClick={() => handleQuizDelete (quiz)}
                                  className={styles.quizIcon}
                                  style={{color: 'red'}}
                                />
                              </Tooltip>

                            </span>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Panel>
                );
              })}
            </Collapse>}
      </div>
      <Drawer
        title={
          currentQuizType === 'moduleQuiz'
            ? 'Add Quiz to Module'
            : 'Add Quiz to Final Assessment'
        }
        placement={'right'}
        width={700}
        onClose={handleCancelQuiz}
        visible={isAddQuiz}
        extra={
          <Space>
            <Button onClick={handleCancelQuiz}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={handleSubmitQuiz}>
              Add Quiz
            </Button>
          </Space>
        }
      >

        {currentQuizType === 'moduleQuiz' &&
          <Select
            placeholder="Select Course Module"
            showSearch
            style={{width: '100% ', marginBottom: '2%'}}
            onChange={handleQuestionModule}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes (input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase ()
                .localeCompare (optionB.children.toLowerCase ())}
          >
            {courseSections.map (item => {
              return (
                <Option value={item.id} key={item.id}>{item.title}</Option>
              );
            })}
          </Select>}

        <ReactQuill
          modules={modules}
          theme="snow"
          onChange={setQuestion}
          placeholder="Type Question Here..."
        />
        <br />

        {allOptions.map ((item, index) => {
          return (
            <div key={index} className={styles.option}>
              <b>{index + 1}</b>
              <ReactQuill
                modules={modulesRead}
                theme="snow"
                readOnly
                value={item}
                style={{width: '90%'}}
              />
              <Tooltip title="Delete Option?">
                <DeleteOutlined
                  style={{fontSize: '1.5rem'}}
                  onClick={() => handleOptionDelete (index)}
                />
              </Tooltip>

            </div>
          );
        })}

        <ReactQuill
          onChange={setCurrentOption}
          modules={modules}
          theme={'snow'}
          placeholder={
            allOptions.length >= 4
              ? 'Cannot Add More than 4 options'
              : 'Enter Your Option'
          }
          style={{marginBottom: '2%'}}
          disabled={allOptions.length >= 4}
        />
        <Button
          type="primary"
          disabled={allOptions.length >= 4}
          onClick={handleAddOption}
        >
          Add
        </Button>
        <br />
        {allOptions.length > 0 &&
          <Select
            placeholder={'Select Correct Option'}
            onChange={value => setAnswer (value)}
            style={{width: '100% ', marginBottom: '2%'}}
          >
            {allOptions.map ((item, index) => {
              return <Option key={index} value={item}>{index + 1}</Option>;
            })}

          </Select>}

          <Input.TextArea
          value={explanation}
          onChange={e => setExplanation (e.target.value)}
          placeholder="Enter Explanation for answer"
          style={{marginBottom: '2%'}}
        />
      </Drawer>

      <Drawer
        title={'Update Module Quiz'}
        visible={isEditQuiz}
        placement={'right'}
        width={700}
        onClose={handleCancelQuiz}
        extra={
          <Space>
            <Button onClick={handleCancelEditModal}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={handleUpdateQuiz}>
              Update Quiz
            </Button>
          </Space>
        }
      >

        <Select
          placeholder="Select Updated Course Module"
          showSearch
          value={updatedQuizCourseSection}
          style={{width: '100% ', marginBottom: '2%'}}
          onChange={value => setUpdatedQuizCourseSection (value)}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes (input)}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase ()
              .localeCompare (optionB.children.toLowerCase ())}
        >
          {courseSections.map (item => {
            return <Option value={item.id} key={item.id}>{item.title}</Option>;
          })}
        </Select>

        <ReactQuill
          modules={modules}
          theme="snow"
          value={updatedQuestion}
          onChange={setUpdatedQuestion}
          placeholder="Enter Updated Question..."
        />
        <br />

        {updatedAllOptions.map ((item, index) => {
          return (
            <div key={index} className={styles.option}>
              <b>{index + 1}</b>
              <ReactQuill
                modules={modulesRead}
                theme="snow"
                readOnly
                value={item}
                style={{width: '90%'}}
              />
              <Tooltip title="Delete Option?">
                <DeleteOutlined
                  style={{fontSize: '1.5rem'}}
                  onClick={() => handleDeleteUpdatedOptions (index)}
                />
              </Tooltip>

            </div>
          );
        })}

        <ReactQuill
          onChange={setCurrentUpdatedOption}
          modules={modules}
          theme={'snow'}
          placeholder={
            allOptions.length >= 4
              ? 'Cannot Add More than 4 options'
              : 'Enter Your Option'
          }
          style={{marginBottom: '2%'}}
          disabled={updatedAllOptions.length >= 4}
        />
        <Button
          type="primary"
          disabled={updatedAllOptions.length >= 4}
          onClick={handleAddUpdatedOption}
        >
          Add
        </Button>
        <br />
        {updatedAllOptions.length > 0 &&
          <Select
            value={updatedAnswer}
            placeholder={'Select Correct Option'}
            onChange={value => setUpdatedAnswer (value)}
            style={{width: '100% ', marginBottom: '2%'}}
          >
            {updatedAllOptions.map ((item, index) => {
              return <Option key={index} value={item}>{item}</Option>;
            })}

          </Select>}
          <Input.TextArea
          value={updatedExplanation}
          onChange={e => setUpdatedExplanation (e.target.value)}
          placeholder="Enter Updated Explanation for answer"
          style={{marginBottom: '2%'}}
        />
      </Drawer>
      {/* <Modal
        title={
          currentQuizType === 'moduleQuiz'
            ? 'Add Quiz to Module'
            : 'Add Quiz to Final Assessment'
        }
        visible={isAddQuiz}
        confirmLoading={loading}
        okText={'Add Quiz'}
        onOk={handleSubmitQuiz}
        onCancel={handleCancelQuiz}
      >

        <Input
          value={question}
          onChange={e => setQuestion (e.target.value)}
          placeholder="Enter Your Question"
          style={{marginBottom: '2%'}}
        />

        {currentQuizType === 'moduleQuiz' &&
          <Select
            placeholder="Select Course Module"
            showSearch
            style={{width: '100% ', marginBottom: '2%'}}
            onChange={handleQuestionModule}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes (input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase ()
                .localeCompare (optionB.children.toLowerCase ())}
          >
            {courseSections.map (item => {
              return (
                <Option value={item.id} key={item.id}>{item.title}</Option>
              );
            })}
          </Select>}

        {allOptions.map ((item, index) => {
          return (
            <div className={styles.option} key={index}>
              <span>{index + 1}. {item}</span>
              <DeleteOutlined onClick={() => handleOptionDelete (index)} />
            </div>
          );
        })}

        <Input.Group style={{width: '100%'}}>
          <Row>
            <Col span={20}>
              <Input
                value={currentOption}
                onChange={e => setCurrentOption (e.target.value)}
                placeholder={
                  allOptions.length >= 4
                    ? 'Cannot Add More than 4 options'
                    : 'Enter Your Option'
                }
                style={{marginBottom: '2%'}}
                disabled={allOptions.length >= 4}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={handleAddOption}>Add</Button>
            </Col>
          </Row>

        </Input.Group>

        {allOptions.length > 0 &&
          <Select
            placeholder={'Select Correct Option'}
            onChange={value => setAnswer (value)}
            style={{width: '100% ', marginBottom: '2%'}}
          >
            {allOptions.map ((item, index) => {
              return <Option key={index} value={item}>{item}</Option>;
            })}

          </Select>}

      </Modal>

      <Modal
        title={'Update Module Quiz'}
        visible={isEditQuiz}
        confirmLoading={loading}
        okText={'Update Quiz'}
        onOk={handleUpdateQuiz}
        onCancel={handleCancelEditModal}
      >

        <Input
          value={updatedQuestion}
          onChange={e => setUpdatedQuestion (e.target.value)}
          placeholder="Enter Updated Question"
          style={{marginBottom: '2%'}}
        />

        <Select
          placeholder="Select Updated Course Module"
          showSearch
          value={updatedQuizCourseSection}
          style={{width: '100% ', marginBottom: '2%'}}
          onChange={value => setUpdatedQuizCourseSection (value)}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes (input)}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase ()
              .localeCompare (optionB.children.toLowerCase ())}
        >
          {courseSections.map (item => {
            return <Option value={item.id} key={item.id}>{item.title}</Option>;
          })}
        </Select>

        {updatedAllOptions.map ((item, index) => {
          return (
            <div className={styles.option} key={index}>
              <span>{index + 1}. {item}</span>
              <DeleteOutlined
                onClick={() => handleDeleteUpdatedOptions (index)}
              />
            </div>
          );
        })}

        <Input.Group style={{width: '100%'}}>
          <Row>
            <Col span={20}>
              <Input
                value={currentUpdatedOption}
                onChange={e => setCurrentUpdatedOption (e.target.value)}
                placeholder={
                  updatedAllOptions.length >= 4
                    ? 'Cannot Add More than 4 options'
                    : 'Enter Your Updated Option'
                }
                style={{marginBottom: '2%'}}
                disabled={updatedAllOptions.length >= 4}
              />
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={handleAddUpdatedOption}>
                Add
              </Button>
            </Col>
          </Row>

        </Input.Group>

        {updatedAllOptions.length > 0 &&
          <Select
            value={updatedAnswer}
            placeholder={'Select Correct Option'}
            onChange={value => setUpdatedAnswer (value)}
            style={{width: '100% ', marginBottom: '2%'}}
          >
            {updatedAllOptions.map ((item, index) => {
              return <Option key={index} value={item}>{item}</Option>;
            })}

          </Select>}

      </Modal> */}
    </div>
  );
}

export default ModuleQuiz;
