import React, {useEffect, useState} from 'react';
import {
  HomeOutlined,
  InboxOutlined,
  LoadingOutlined,
  ProjectOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Select,
  Modal,
  Input,
  message,
  Spin,
  Upload,
} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import '../../AdminCourse/AdminCourse.css';
import ProjectServices from '../../../../services/projects.service';
import CourseServices from '../../../../services/courses.service';
import ProgramServices from '../../../../services/programs.service';

const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);

function EditProject () {
  const {id} = useParams ();
  const [programDetails, setProgramDetails] = useState (null);
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const [loading, setLoading] = useState (false);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState (false);
  const [title, setTitle] = useState ('');
  const [description, setDescription] = useState ('');
  const [category, setCategory] = useState ('');
  const [isCategoryModal, setIsCategoryModal] = useState (false);
  const [isCategoryAdding, setIsCategoryAdding] = useState (false);
  const [allProjectCategory, setAllProjectCategory] = useState ([]);
  const [courseGroup, setCourseGroup] = useState ([]);
  const [allCourses, setAllCourses] = useState ([]);
  const [newCategory, setNewCategory] = useState ('');

  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          console.log (response.data);
          setAllProjectCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect (() => {
    let isMounted = true;
    const getAllCourses = async () => {
      try {
        const response = await CourseServices.getAllCourses ();

        setAllCourses (response.data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Unable to fetch courses!');
      }
    };

    if (isMounted) {
      getAllCourses ();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  useEffect (() => {
    let isMounted = true;
    const getAllCourses = async () => {
      try {
        const response = await CourseServices.getAllCourses ();

        setAllCourses (response.data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Unable to fetch courses!');
      }
    };

    if (isMounted) {
      getAllCourses ();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const getAllProjectCategory = () => {
    ProjectServices.getAllProjectCategory ()
      .then (response => {
        console.log (response.data);
        setAllProjectCategory (response.data);
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleCategorySubmit = async () => {
    setIsCategoryAdding (true);
    try {
      const response = await ProjectServices.createNewProjectCategory (
        newCategory,
        currentUser.id
      );
      //   console.log (response);
      if (response) {
        message.success (`Added ${newCategory} Successfully!`);
        setNewCategory ('');
        getAllProjectCategory ();
        setIsCategoryAdding (false);
        setIsCategoryModal (false);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setIsCategoryAdding (false);
    }
  };

  async function getProgramDetails () {
    try {
      const response = await ProgramServices.getProgramById (id);
      // console.log (response.data);
      setProgramDetails (response.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch project details!'
      );
    }
  }

  useEffect (
    () => {
      async function getProgramDetails () {
        try {
          const response = await ProgramServices.getProgramById (id);
          // console.log (response.data);
          setProgramDetails (response.data);
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch project details!'
          );
        }
      }
      getProgramDetails ();
    },
    [id]
  );

  useEffect (
    () => {
      if (programDetails) {
        setDescription (programDetails.description);
        setTitle (programDetails.title);
        setCategory (programDetails.category);
        setCourseGroup (programDetails.courseGroup);
      }
    },
    [programDetails]
  );

  const onFinish = async () => {
    setLoading (true);

    let data = {
      id: programDetails.id,
      title: title,
      description: description,
      category: category,
      imageUrl: fileUrl ? fileUrl : programDetails.imageUrl,
      courseGroup: courseGroup,
      userId: currentUser.id,
    };
    // console.log(data);
    try {
      const response = await ProgramServices.updateProgramById (data);
      // console.log(response.data);
      if (response.status === 200) {
        console.log ('success!');
      }
      message.success ('Updated Program Successfully!');
      setLoading (false);
      getProgramDetails ();
      // onReset();
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  function beforeUpload (file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (file) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    if (!isJpgOrPng) {
      message.error ('You can only upload JPG/PNG file!');
      setUploadBtnDisabled (true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error ('Image must smaller than 2MB!');
      setUploadBtnDisabled (true);
    }

    return false;
  }

  const removePrevItemFromAws = key => {
    console.log ('key to remove : ', key);
    CourseServices.removeItemFromBucket ({userId: currentUser.id, key: key})
      .then (result => {
        console.log (result);
        message.info ('Deleted Previous Image from Bucket!');
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleUpload = async () => {
    setUploading (true);
    const files = fileList;
    let removedItemKey = '';
    if (programDetails.imageUrl) {
      console.log (programDetails.imageUrl);
      let imageUrlArr = programDetails.imageUrl.split ('/');
      console.log (imageUrlArr);
      removedItemKey = imageUrlArr[imageUrlArr.length - 1];
    }

    let changedName = '';
    // console.log("File List : ",files);
    // if (courseDetails.imageUrl) {
    //   let changedArr = courseDetails.imageUrl.split ('/');
    //   changedName = changedArr[changedArr.length - 1];
    // } else {
    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    changedName = finalDate.toString () + '__' + files[0].name;
    // console.log("Changed Arr : ",changedArr);
    // let changedName="8-2-2022_at_13:13:5__nodejs_banner.jpg";

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      message.success ('Image Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }

    if (removedItemKey) {
      removePrevItemFromAws (removedItemKey);
    }
  };

  return (
    <div className="adminCourse">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumb className="breadCrumb">
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard">
              <HomeOutlined style={{marginRight: '5px'}} />
              <span>Dashboard</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard/manage-programs">
              <ProjectOutlined
                style={{
                  marginRight: '5px',
                  fontSize: '1rem',
                  marginBottom: '-2px',
                }}
              />
              <span>All Programs</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {programDetails ? programDetails.title : id}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div>
          <Button
            type="primary"
            className="btn"
            onClick={() => setIsCategoryModal (true)}
          >
            <PlusOutlined /> Add Category
          </Button>
        </div>
      </div>

      <Modal
        title="Add New Program Category"
        visible={isCategoryModal}
        confirmLoading={isCategoryAdding}
        okText={'Add'}
        onOk={handleCategorySubmit}
        onCancel={() => setIsCategoryModal (false)}
      >

        <div className="materialForm">
          <label>
            <b>Category Name</b>
          </label>

          <Input
            placeholder="e.g. Web Development"
            type="text"
            onChange={e => {
              setNewCategory (e.target.value);
            }}
            value={newCategory}
          />
        </div>
      </Modal>

      <div className="editCourse">
        <div className="createCourseContainer">
          <h1>Update The Program Details</h1>
          <form>
            <div className="form-control">
              <label>Title</label>
              <input
                type="text"
                onChange={e => setTitle (e.target.value)}
                value={title}
                placeholder="e.g. Javascript Counter"
              />
            </div>

            <div className="form-control">
              <label>Description</label>
              <textarea
                onChange={e => setDescription (e.target.value)}
                placeholder="Enter any description for the project"
                value={description}
              />
            </div>

            <div className="form-control">
              <label>Select Updated Category</label>
              <br />
              <Select
                showSearch
                value={category}
                style={{width: '100%'}}
                onChange={value => setCategory (value)}
                placeholder="e.g. Web Development"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.includes (input)}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase ()
                    .localeCompare (optionB.children.toLowerCase ())}
              >
                {allProjectCategory.map (item => {
                  return (
                    <Select.Option key={item.id} value={item.title}>
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>

            <div className="form-control">
              <label>Select Updated Course Group: </label><br />
              <Select
                showSearch
                placeholder="e.g. Nodejs"
                optionFilterProp="children"
                value={courseGroup}
                onChange={value => setCourseGroup (value)}
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.includes (input)}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase ()
                    .localeCompare (optionB.children.toLowerCase ())}
              >
                {allCourses.map (item => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>

            <Upload.Dragger
              name="files"
              beforeUpload={beforeUpload}
              onRemove={() => {
                setFileList ([]);
              }}
              fileList={fileList}
            >
              <p className="ant-upload-drag-icon">
                {programDetails
                  ? <img
                      src={programDetails.imageUrl}
                      className="uploadDefaultImg"
                      alt="img"
                    />
                  : <InboxOutlined style={{color: 'var(--green)'}} />}
              </p>
              <p className="ant-upload-text">
                Click or drag image to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a Single Image File with maximum 2 MB of size.
              </p>
            </Upload.Dragger>

            <button
              type="button"
              disabled={uploading || fileList.length === 0 || uploadBtnDisabled}
              className="primaryBtn"
              style={{marginTop: '5px', marginBottom: '5px'}}
              onClick={handleUpload}
            >
              {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
            </button>

            <Button
              type="primary"
              loading={loading}
              onClick={onFinish}
              className="btn"
            >
              Update
            </Button>

          </form>
        </div>

      </div>
    </div>
  );
}

export default EditProject;
