import React from 'react';
import {Link} from 'react-router-dom';
import './Editor.css';
import editorImg from '../../assets/editor.gif';
function Editor () {
  return (
    <div className="editorSection">

      <div className="editorImg">
        <img src={editorImg} alt="img" />
      </div>
      <div className="editorText">
        <h1>
          <span className="green">Hands-On </span>
          Learning Environment with Our
          <span className="green"> Coding</span> Editor!
        </h1>
        <div className="btnArea">
          <Link to="/code-editor" className="outlineBtn">
            <b>Write Code</b>
          </Link>
        </div>
      </div>

    </div>
  );
}

export default Editor;
