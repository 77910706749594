import {Badge, Button, Card, Drawer, message} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import ProjectServices from '../../services/projects.service';
import styles from './ProjectDetails.module.css';
import {FaPlay} from 'react-icons/fa';
import ReactPlayer from 'react-player/lazy'
import { useSelector } from 'react-redux';
import { history } from '../../helpers/history';
import avatar from '../../assets/login.png';
import {BsCheckLg, BsFillStarFill} from 'react-icons/bs';


function ProjectDetails () {
  const {id} = useParams ();
  const [allProjectMaterial, setAllProjectMaterial] = useState ([]);
  const [projectDetails, setProjectDetails] = useState ('');
  const [currentVideo,setCurrentVideo] = useState("");
  const [visible,setVisible] = useState(false);
  const [isEnrolled,setIsEnrolled] = useState(false);
  const [enrolling,setEnrolling] = useState(false);
  const {user:currentUser} = useSelector((state)=>state.auth);
  const [allFeedback,setAllFeedback] = useState([]);



  useEffect(()=>{
    if(!currentUser){
      history.push('/login');
    }
  },[currentUser]);

  useEffect(()=>{
    let isMounted = true;

    const getFeedbackOfProject = async ()=>{
      try{
        const response = await ProjectServices.getAllFeedback({projectId:projectDetails.id});
        // console.log(response.data);
        setAllFeedback(response.data);
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!')
      }
    }

    if(projectDetails && isMounted){
      getFeedbackOfProject();
    } 

    return ()=>{
       isMounted = false;
    }
  },[projectDetails])



  useEffect(()=>{
    let isMounted = true;
    const checkIsEnrolled = async ()=>{
      try{
        const response = await ProjectServices.checkIsEnrolledInProject({userId:currentUser.id,projectId:projectDetails.id});
        if(response.data.isEnrolled){
          setIsEnrolled(true);
        }else{
          setIsEnrolled(false);
        }
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
      }
    }

    if(isMounted){
      checkIsEnrolled();
    }

    return ()=>{
      isMounted = false;
    }
  },[currentUser,projectDetails])

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  useEffect (
    () => {
      let isMounted = true;
      const getProjectMaterial = async () => {
        try {
          const response = await ProjectServices.getProjectMaterialByProjectId (
            id
          );
          //   console.log (response.data);
          setAllProjectMaterial (response.data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      const getProjectDetails = async () => {
        try {
          const response = await ProjectServices.getProjectById (id);
          console.log (response.data);
          setProjectDetails (response.data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };
      if (isMounted) {
        getProjectMaterial ();
        getProjectDetails ();
      }

      return () => {
        isMounted = false;
      };
    },
    [id]
  );
//   console.log (id);
const handleVideoPreview = (video)=>{
    setCurrentVideo(video);
    showDrawer();
}

const createProgress = async ()=>{
  try{
    const response = await ProjectServices.createProjectProgress(
      {userId:currentUser.id,
      projectId:projectDetails.id});
      console.log("progress created : ",response.data)
      if(response.data){
      setEnrolling(false);
      history.push(`/project-details/start/${id}`)
      }
  }catch(err){  
    console.log(err);
    message.error(err.message ? err.message : 'something went wrong!');
  }

}

const handleEnroll = async ()=>{
  setEnrolling(true);
  try{
    const response = await ProjectServices.enrollStudentInProject(
      {userId:currentUser.id,
      username:currentUser.username,
      projectId:projectDetails.id,userDetails:currentUser});
      // console.log(response.data);
      if(response.data){
       createProgress();
      }
  }catch(err){  
    console.log(err);
    message.error(err.message ? err.message : 'something went wrong!');
    setEnrolling(false);
  }
}


  return (
    <div className={styles.projectDetails}>
        <div className={styles.projectDetails__header}>
           <div className={styles.projectDetails__header__info}>
                <h2>{projectDetails?.title}</h2>
                <div className={styles.badge}>{projectDetails?.category}</div>
                <p>{projectDetails?.description}</p>
                
           </div>
        </div>
       
        <div className={styles.projectDetails__header__img}>
        <Badge.Ribbon
         status={`${projectDetails.complexity ==='Beginner' ? 'success': projectDetails.complexity ==='Intermediate' ? 'warning' : 'error'}`}
         text={projectDetails.complexity}>
               <img src={projectDetails?.imageUrl} alt={projectDetails?.title}/>
               <div className={styles.projectDetails__header__img__description}>
                  {isEnrolled ? 
                    <Link 
                    to={`/project-details/start/${id}`} className="primaryBtn">Continue</Link>
                    :
                    <Button type="primary" loading={enrolling} onClick={handleEnroll} className="primaryBtn">Start Project</Button>
                  }
               </div>
          </Badge.Ribbon>
        </div>

       {projectDetails.learningOutcome?.length > 0 &&
        <div className={styles.projectLearning}>
        <h2>What you will Learn?</h2>
        <Card className={styles.learningPointCard}>
          {projectDetails.learningOutcome.map((item,index)=>{
            return(
              <div className={styles.learningPoint} key={index}>
                <BsCheckLg className={styles.learningPointIcon} />
                <span className={styles.learningPointText}>{item}</span>
              </div>
            )
          })}
        </Card>

       </div>
       }
       
   

        <div className={`${styles.projectVideos} ${projectDetails.learningOutcome?.length > 0 ? styles.marTop100:styles.marTop300}`}>
            <h2>Project Material</h2>
            {allProjectMaterial.map((item,index)=>{
                return(
                    <div className={styles.videoWrapper} key={index}>
                        <div style={{
          backgroundImage: `
        linear-gradient(to bottom,  rgba(0, 12, 53, 0.83),rgba(4, 44, 155, 0.82)),
        url('${projectDetails?.imageUrl}')`,
          backgroundSize: 'cover',height:'150px',width:'250px'}}
          className={styles.videoImg}
          >
            <FaPlay className={styles.playIcon} onClick={()=>handleVideoPreview(item)} />
                        </div>
                        <div className={styles.videoInfo}>
                            <h4>{index+1}. {item.title}</h4>
                            <p>{item.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>

        {allFeedback.length > 0 &&
        <div className={styles.reviewsArea}>
          <h2>Reviews</h2>
          <div className={styles.allReviews}>
              {allFeedback.map((item)=>{
                return(
                  <div className={styles.singleReview} key={item.id}>
                     <div className={styles.reviewImgArea}>
                     <img src={item?.ProjectStudent?.userDetails.profilePicture ? item?.ProjectStudent?.userDetails.profilePicture : avatar} alt={"img"} />
                     <b>{item?.ProjectStudent?.userDetails?.username}</b>
                     </div>
                     <div className={styles.reviewData}>
                        <q> {item?.description} </q>
                        <p>{item?.rating} <BsFillStarFill className={styles.ratingStar} /></p>
                     </div>

                  </div>
                )
              })}

          </div>
        
        </div>

        }
      

        <Drawer size={"large"} title={currentVideo?.title} placement="top" onClose={onClose} visible={visible}>
            <ReactPlayer width={"100%"} height={"75%"} url={currentVideo?.fileLink} controls={true} />
            <br />
            <p>{currentVideo?.description}</p>
      </Drawer>

    </div>
  );
}

export default ProjectDetails;
