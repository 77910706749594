import React from 'react';
import './CourseCard.css';
import {Link} from 'react-router-dom';
import { Tag } from 'antd';

function CourseCard (props) {
  return (
    <div
      style={props.styles}
      className={`courseCard ${props.isShadow === 'no' ? 'blueShadow' : 'grayShadow'}`}
    >
      <div
        style={{
          backgroundImage: `
        linear-gradient(to bottom,  rgba(0, 12, 53, 0.73),rgba(4, 44, 155, 0.52)),
        url('${props.img}')`,
          backgroundSize: 'cover',
          width: '100%',
          height: '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          
          padding: '10px',
        }}
      >
        <h5 className="cardTitle">{props.title}</h5>
      </div>
      <div className="courseDesc">
        <div className="cardBody">
          {props.description.slice (0, 150)}
          ....
          <Link to={`/courses/view-course-details/${props.id}`}>More</Link>
        </div>

      </div>
      <div>
        {Array.isArray(props.category) ? props.category.map(item=><Tag style={{margin:'7px'}} key={item}>{item}</Tag>):<Tag style={{margin:'7px'}} >{props.category}</Tag>}
      </div>
      {props.joinedAt &&
        <div>
          <small className="gray__text">Joined At {props.joinedAt}</small>
        </div>}
      <Link
        to={`/courses/view-course-details/${props.id}`}
        className="primaryBtn"
      >
        View
      </Link>

    </div>
  );
}

export default CourseCard;
