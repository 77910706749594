import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import '../Login/Login.css';
import logo from '../../../assets/codersartsLogo.png';
import loginIcon from '../../../assets/login.png';
import { Link } from 'react-router-dom';
// import * as Yup from 'yup';
import {RiEyeOffLine,RiEyeLine} from 'react-icons/ri';
import {LoadingOutlined} from '@ant-design/icons';
import { register,login } from "../../../actions/auth";
import {message, Spin} from 'antd';
import AuthService from '../../../services/auth.service';
import successfulImg from '../../../assets/successfully.png';
import {history} from '../../../helpers/history';


const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;


// const validationSchema = Yup.object({
//     username:Yup.string().required("Required"),
//     email:Yup.string().email("Invalid Email Format").required("Required"),
//     password:Yup.string().required('Required').matches(
//         /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
//         "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
//       ),
//     otp:Yup.number("Invalid Type").required('Required')
// })






function Register() {
    const [passwordHidden,setPasswordHidden] = useState(true);
    const [loading,setLoading] = useState(false);
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [username,setUsername] = useState("");
    const dispatch = useDispatch();
    const [otp,setOtp] = useState("");
    const [screen,setScreen] = useState(1);
    const {currentMessage} = useSelector((state)=>state.currentMessage);
    const [originalOtp,setOriginalOtp] = useState(null);
    const [successful,setSuccessful] = useState(false);
    const {user:currentUser} = useSelector((state)=>state.auth);



    const loginNow = async ()=>{
        await dispatch(login(username,password)).then(()=>{
            message.success("User Created Successfully!");
            setLoading(false);
            setSuccessful(true);
        }).catch(()=>{
            message.error("Unable to Login!");
            setLoading(false);
        })
           
    }

    useEffect(()=>{
        if(currentUser){
            history.push('/');
        }
    },[currentUser])

    const handleRegister =  async ()=>{
        let data = {
            username:username,
            email:email,
            password:password
        }
        if(otp){
            setLoading(true);
            if(otp===originalOtp.toString()){
                await dispatch(register(data))
                .then(() => {
                 loginNow();
                })
                .catch(() => {
                    message.error(currentMessage ? currentMessage : 'Something went wrong!');
                  setLoading(false);
                });
            }else{
                message.error("Entered Wrong OTP!!");
                setLoading(false);
               
            }
        }

        
       
    }

    const getOtp = async ()=>{
        
        try{
            const response = await AuthService.sendOtpFromBackend(email);
            console.log("OTP Sent : ",response.data);
            message.success("OTP Sent!");
            message.success("Please Check your Mail!");
            setOriginalOtp(response.data.otp);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something went wrong!');
        }
        
        setScreen(2);
    }

   

    return (
        <div className='login'>
            <div className='bgBlue'>
                <div className='logoArea'>
                    <img src={logo} alt="logo"/>
                    <div className='logoText'>
                        Codersarts
                    </div>
                    <div className='logoDesc'>
                    Codersarts is a mentorship and Learning platform under <a href="https://www.sofstack.com/" rel="noreferrer" target={"_blank"}>Sofstack Technologies </a> that helps you get better at coding and provides support on latest Technologies.
                    </div>
                    <div className='readMore'>
                        <a href="https://www.codersarts.com/how-we-work" rel="noreferrer" target={"_blank"}><u>Read More</u></a>
                    </div>
                </div>
            </div>
            <div className='form-area' style={{marginTop:'2%'}}>

            
            {successful ?
            <div className='registeredSuccess'>
                <div>
                <img src={successfulImg} alt="success" />
                </div>
                <div>
                <h3>You Are Registered Successfully!</h3>
                <p>You will be Redirected in some seconds!</p>
                </div>
            </div>
            :
            
            <form>
                    
                    <div className='loginImg' >
                    <img src={loginIcon} alt="logo" />
                    </div>  
                    <div> <h4>Register</h4></div>
                    {screen===1 ? 
                <>
                <div className='formGroup' style={{marginTop:'-3%'}}>
                        <label htmlFor='username'>Username</label>
                        <input 
                            type="text" 
                            id="username" 
                            name="username" 
                            className='formInput' 
                            placeholder='Enter Your Username'
                            value={username}
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />

                      
                       
                    </div>

                    <div className='formGroup'>
                        <label htmlFor='email'>Email</label>
                        <input 
                            id="email" 
                            type="email" 
                            className='formInput' 
                            name="email" 
                            placeholder='Enter Your Email'
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            />
                          
                    </div>

                    <div className='formGroup'>
                        <label htmlFor='password'>Password</label>
                        <div className='passwordField'>
                        <input 
                            id="password" 
                            type={`${passwordHidden ? 'password' : 'text'}`}
                            className='formInput' 
                            name="password" 
                            placeholder='Create Account Password'
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            />
                            <span>{passwordHidden ? <RiEyeOffLine className="passwordIcon" onClick={()=>{setPasswordHidden(false)}} /> : <RiEyeLine className="passwordIcon" onClick={()=>{setPasswordHidden(true)}} />}</span>
                        </div>
                      

                    </div>
                    <div className='formBottomLink'>
                        <small>Already have an Account? <Link to="/login" className='link'>Login</Link></small>
                    </div>
                   <div>
                   <button 
                   disabled={password==="" || username==="" || email===""}
                   type="button" 
                   onClick={getOtp} 
                   className='loginBtn'>{loading ? <Spin indicator={spinIcon}/> : 'Get OTP'}</button>
                   </div>
                </>
                    :
                   <>
                   <div className='formGroup' style={{marginTop:'2%'}}>
                        <label htmlFor='otp'>Enter OTP Here</label>
                        <input 
                            type="text" 
                            id="otp" 
                            name="otp" 
                            value={otp}
                            onChange={(e)=>{setOtp(e.target.value)}}
                            className='formInput' 
                            placeholder='Enter OTP which is send to your Email'
                            />
                    </div>
                    <div>
                   <button 
                    disabled={otp===""}
                   type="button" 
                   onClick={handleRegister} 
                   className='loginBtn'>{loading ? <Spin indicator={spinIcon}/> : 'Register'}</button>
                   </div>
                   </>
                    }
                    
                </form>
            }
    
        
       

            </div>
        </div>
    )
}

export default Register
