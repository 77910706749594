import React, {useEffect, useState} from 'react';
import {Table, message, Input, Modal, Dropdown, Menu, InputNumber, Select} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import '../ManageCourses/ManageCourses.css';
import {useSelector} from 'react-redux';
import CourseServices from '../../../services/courses.service';
const {confirm} = Modal;

function AdminPrice () {
  const [allPrices, setAllPrices] = useState ([]);
  const [filteredPrices, setFilteredPrices] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const [isCreatePrice,setIsCreatePrice] = useState(false);
  const [value,setValue] = useState("");
  const [currency,setCurrency] = useState("");
  const [tier,setTier] = useState("");
  const [currentEditItem,setCurrentEditItem] = useState("");
  const [updatedValue,setUpdatedValue] = useState("");
  const [updatedCurrency,setUpdatedCurrency] = useState("");
  const [updatedTier,setUpdatedTier] = useState("");
  const [isEditModal,setIsEditModal] = useState("");
 

  const getAllPrices = async () => {
    try {
      const response = await CourseServices.getAllPrice ({userId:currentUser.id});
      // console.log (response.data);
      setAllPrices(response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to fetch prices!');
    }
  };
  useEffect (() => {
    getAllPrices ();
  }, []);

  const handleDelete = (id, price) => {
    confirm ({
      title: 'Are you sure delete this Price?',
      icon: <ExclamationCircleOutlined />,
      content: `Price to Delete : ${price}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        CourseServices.deletePriceById ({id:id,userId:currentUser.id})
          .then (response => {
            //   console.log(response.data);
            message.success (`Deleted ${price} Successfully!`);
            getAllPrices ();
          })
          .catch (err => {
            console.log (err);
            message.error (err.message ? err.message : 'Unable to delete!');
          });
      },
    });
  };


  const handleEditPrice = (item)=>{
    //console.log(item);
    setCurrentEditItem(item);
    setUpdatedCurrency(item?.currency);
    setUpdatedTier(item?.tier);
    setUpdatedValue(item?.value);
    setIsEditModal(true);

  }



  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: id => {
        return <b>#{id}</b>;
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '30%',
      key: 'value',
      render: value => {
        return <b>{value}</b>;
      },
    },
    {
      title: 'Tier',
      dataIndex: 'tier',
      width: '30%',
      key: 'tier',
      render: value => {
        return <div>{value}</div>;
      },
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        filters: [{text:'USD',value:'usd'},{text:'INR',value:'inr'}],
        onFilter: (value, record) => record.currency.indexOf (value) === 0, 
        render: value => {
          return <div>{value}</div>;
        },
      },
      
    {
      title: 'Action',
      key: 'action',
      render: record => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={()=>handleEditPrice(record)}>
                  <EditOutlined />
                  Edit Price
                </Menu.Item>
                <Menu.Item
                  danger
                  key="2"
                  onClick={() => {
                    handleDelete (record.id, `${record.value} ${record.currency}`);
                  }}
                >
                  <div><DeleteOutlined /> Delete</div>
                </Menu.Item>
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{
                  cursor: 'pointer',
                  color: 'black',
                  transform: '90deg',
                  fontSize: '1.5rem',
                }}
              />
            </p>
          </Dropdown>
        );
      },
    },
  ];
  const handleSearch = e => {
    // console.log(e);
    let query = e.target.value;
    // console.log("Query : ",query);
    let filteredResult = [];
    filteredResult = allPrices.filter (item => {
      return (
        item.price && item.price === query
      );
    });
    // console.log(filteredResult);
    setFilteredPrices (filteredResult);
  };

  const handleNewProject = () => {
    
    setIsCreatePrice(true);
  };

  const handleNewSubmitPrice = async ()=>{
    try{
        const data = {
            userId:currentUser.id,
            value:value,
            currency:currency,
            tier:tier
        }
        const response = await CourseServices.createNewPrice(data);
        console.log(response.data);
        if(response.status===200){
            message.success("Price added successfully!");
            setIsCreatePrice(false);
            getAllPrices();
        }
    }catch(err){
        console.log(err);
        message.error(err.message);
    }
  }

  const handleCancelPrice = ()=>{
    setIsCreatePrice(false);
  }

  const handleUpdatePriceSubmit = async ()=>{
    try{
      const data = {
          userId:currentUser.id,
          id:currentEditItem.id,
          data:{
            value:updatedValue,
            currency:updatedCurrency,
            tier:updatedTier
          }
      }
      const response = await CourseServices.updatePriceById(data);
      console.log(response.data);
      if(response.status===200){
          message.success("Price Updated successfully!");
          setIsEditModal(false);
          getAllPrices();
      }
  }catch(err){
      console.log(err);
      message.error(err.message);
  }
  }

  return (
   <>
    <div className="manageCourses">
      <div className="tableHeading">
        <h1>All Prices </h1>
        <div className="searchArea">
          <Input
            className="searchInput"
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="Search for Prices by value"
            allowClear
            onChange={handleSearch}
          />
        </div>
        <div>
          <button className="btn" onClick={handleNewProject}>
            {' '}<PlusOutlined /> Add New Price
          </button>
        </div>
      </div>
      <Table
        columns={columns}
        pagination={true}
        
        dataSource={filteredPrices.length > 0 ? filteredPrices : allPrices}
      />

    

    </div>
      <Modal title="Create New Price" 
      visible={isCreatePrice} 
      onOk={handleNewSubmitPrice} 
      okButtonProps={{disabled:!currency || !value || !tier}}
      onCancel={handleCancelPrice}>

      <div style={{display:'flex',flexDirection:'column'}}>
        <label>Value: </label>
        <InputNumber value={value} 
        style={{width:'100%'}}
        onChange={(value)=>setValue(value)} 
        min={0} placeholder='Enter Value for price' />
      </div>

      <div style={{display:'flex',flexDirection:'column'}}>
        <label>Currency</label>
      <Select
                 style={{width:'100%'}}
                  value={currency}
                  onChange={(value)=>setCurrency(value)}
                   placeholder="e.g. USD"
                  >
                      <Select.Option  value={"usd"}>USD</Select.Option>
                      <Select.Option  value={"inr"}>INR</Select.Option>
                  </Select>
      </div>

      <div style={{display:'flex',flexDirection:'column'}}>
        <label>Tier</label>
      <Input
                  value={tier}
                  style={{width:'100%'}}
                  onChange={(e)=>setTier(e.target.value)}
                   placeholder="e.g. Tier 1"
                  />
                     
                  
      </div>

      </Modal>

      <Modal title="Update Price" 
      visible={isEditModal} 
      onOk={handleUpdatePriceSubmit} 
      okButtonProps={{disabled:!updatedCurrency || !updatedValue || !updatedTier}}
      onCancel={()=>setIsEditModal(false)}>

      <div style={{display:'flex',flexDirection:'column'}}>
        <label>Value: </label>
        <InputNumber value={updatedValue} 
        style={{width:'100%'}}
        onChange={(value)=>setUpdatedValue(value)} 
        min={0} placeholder='Enter Value for price' />
      </div>

      <div style={{display:'flex',flexDirection:'column'}}>
        <label>Currency</label>
      <Select
                 style={{width:'100%'}}
                  value={updatedCurrency}
                  onChange={(value)=>setUpdatedCurrency(value)}
                   placeholder="e.g. USD"
                  >
                      <Select.Option  value={"usd"}>USD</Select.Option>
                      <Select.Option  value={"inr"}>INR</Select.Option>
                  </Select>
      </div>

      <div style={{display:'flex',flexDirection:'column'}}>
        <label>Tier</label>
      <Input
                  value={updatedTier}
                  style={{width:'100%'}}
                  onChange={(e)=>setUpdatedTier(e.target.value)}
                   placeholder="e.g. Tier 1"
                  />
                     
                  
      </div>

      </Modal>
   
   </>
  );
}

export default AdminPrice;
