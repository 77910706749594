import React, {useEffect, useState} from 'react';
import './CodeEditor.css';
import {Button, Layout, message, Spin, Input, Select} from 'antd';
import logo from '../../assets/codersartsLogo.png';
import {Link} from 'react-router-dom';
import {BiBookReader, BiHomeCircle} from 'react-icons/bi';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-csp';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-kotlin';
import 'ace-builds/src-noconflict/mode-r';
import 'ace-builds/src-noconflict/mode-rust';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/ext-language_tools';
import {FaPlay} from 'react-icons/fa';
import axios from 'axios';
import {LoadingOutlined} from '@ant-design/icons';
const {Header, Content, Footer} = Layout;
const {Option} = Select;

const editorLanguageInfo = [
  {value: 'c', name: 'C (GCC 7.4.0)'},
  {value: 'c_cpp', name: 'C++ (GCC 7.4.0)'},
  {value: 'java', name: 'Java (OpenJDK 13.0.1)'},
  {value: 'javascript', name: 'JavaScript (Node.js 12.14.0)'},
  {value: 'csp', name: 'C# (Mono 6.6.0.161)'},
  {value: 'kotlin', name: 'Kotlin (1.3.70)'},
  {value: 'swift', name: 'Swift (5.2.3)'},
  {value: 'python', name: 'Python (2.7.17)'},
  {value: 'python3', name: 'Python (3.8.1)'},
  {value: 'ruby', name: 'Ruby (2.7.0)'},
  {value: 'golang', name: 'Go (1.13.5)'},
  {value: 'r', name: 'R (4.0.0)'},
  {value: 'rust', name: 'Rust (1.40.0)'},
];

const spinIcon = (
  <LoadingOutlined style={{fontSize: 24, color: 'white'}} spin />
);

function CodeEditor () {
  const [language, setLanguage] = useState ('java');
  const [theme, setTheme] = useState ('monokai');
  const [fontSize, setFontSize] = useState (16);
  const [activeLanguages, setActiveLanguages] = useState ([]);
  const [languageId, setLanguageId] = useState (62);
  const [sourceCode, setSourceCode] = useState (null);
  const [input, setInput] = useState (null);
  const [loading, setLoading] = useState (false);
  const [outputResult, setOutputResult] = useState (false);
  const [err, setErr] = useState ('');
  // const [output, setOutput] = useState ('');

  useEffect (() => {
    const options = {
      method: 'GET',
      url: 'https://judge0-ce.p.rapidapi.com/languages',
      headers: {
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
        'X-RapidAPI-Key': '1b7e4598a8msh0d2064876509bb0p12482cjsn478db1227312',
      },
    };

    axios
      .request (options)
      .then (function (response) {
        // console.log ('languages info : ', response.data);
        setActiveLanguages (response.data);
      })
      .catch (function (error) {
        console.error (error);
      });
  }, []);

  const handleLanguageChange = (value, event) => {
    // console.log ('event : ', event);
    // console.log ('language : ', value);
    let queryLang = event.children.toString ();
    // console.log ('query : ', queryLang);
    let foundLang = activeLanguages.filter (item => {
      return item.name.toString ().toLowerCase () === queryLang.toLowerCase ();
    });

    // console.log ('found lang : ', foundLang);
    setLanguageId (foundLang[0].id);
    setLanguage (value);
    if (value === 'c') {
      setLanguage ('c_cpp');
    }
    if (value === 'python3') {
      setLanguage ('python');
    }
  };

  const handleThemeChange = value => {
    // console.log ('theme : ', value);
    setTheme (value);
  };

  const handleFontSizeChange = value => {
    // console.log ('size : ', value);
    let font = Number (value);
    setFontSize (font);
  };

  const handleEditorContent = value => {
    // console.log (value);
    setSourceCode (value);
  };

  const handleInput = e => {
    // console.log (e.target.value);
    setInput (e.target.value);
  };

  const handleRunCode = async () => {
    setErr ('');
    setOutputResult ('');
    setLoading (true);
    const response = await fetch (
      'https://judge0-ce.p.rapidapi.com/submissions',
      {
        method: 'POST',
        headers: {
          'x-rapidapi-host': 'judge0-ce.p.rapidapi.com',
          'x-rapidapi-key': '1b7e4598a8msh0d2064876509bb0p12482cjsn478db1227312', // Get yours for free at https://rapidapi.com/judge0-official/api/judge0-ce/
          'content-type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify ({
          source_code: sourceCode,
          stdin: input,
          language_id: languageId,
        }),
      }
    );

    // console.log (response);
    const jsonResponse = await response.json ();
    let jsonGetSolution = {
      status: {description: 'Queue'},
      stderr: null,
      compile_output: null,
    };
    while (
      jsonGetSolution.status.description !== 'Accepted' &&
      jsonGetSolution.stderr == null &&
      jsonGetSolution.compile_output == null
    ) {
      if (jsonResponse.token) {
        let url = `https://judge0-ce.p.rapidapi.com/submissions/${jsonResponse.token}?base64_encoded=true`;
        const getSolution = await fetch (url, {
          method: 'GET',
          headers: {
            'x-rapidapi-host': 'judge0-ce.p.rapidapi.com',
            'x-rapidapi-key': '1b7e4598a8msh0d2064876509bb0p12482cjsn478db1227312', // Get yours for free at https://rapidapi.com/judge0-official/api/judge0-ce/
            'content-type': 'application/json',
          },
        });
        jsonGetSolution = await getSolution.json ();
      }
    }

    // console.log (jsonGetSolution);

    if (jsonGetSolution.stdout) {
      const output = atob (jsonGetSolution.stdout);
      // console.log (output);
      setOutputResult (output);
      setLoading (false);
    } else if (jsonGetSolution.stderr) {
      const error = atob (jsonGetSolution.stderr);
      // console.log (error);
      setLoading (false);
      message.error (error);
    } else {
      const compilation_error = atob (jsonGetSolution.compile_output);
      // console.log (compilation_error);
      setErr (compilation_error);
      setLoading (false);
    }
  };

  return (
    <div className="codeEditor">
      <Layout style={{minHeight: '100vh'}}>
        <Layout className="site-layout adminDashboard">
          <Header
            className="site-layout-background adminNav learnDashboard"
            style={{padding: 0}}
          >
            <div className="logoArea">
              <div> <img src={logo} alt="logo" /></div>
              <div className="logoTitles">
                <Link to="/" style={{color: 'white'}}>
                  {' '}
                  <div className="logoText">
                    Codersarts <span className="green">Learning </span>
                  </div>
                  {' '}
                </Link>
              </div>
            </div>
            <div className="navigationLinks">
              <Link to="/" className="navLink">
                <BiHomeCircle className="navIcon" /> Home
              </Link>
              <Link to="/courses" className="navLink">
                <BiBookReader className="navIcon" />Courses
              </Link>

            </div>
            {/* <div className='profileItems' style={{marginRight:'500px'}}> 
            <div className="nav-item profile-items">
              <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} className="profile-avatar" alt="img" />
            </div>
            <ProfileDropdown user={currentUser} name={currentUser.username} logout={logOut} />
          </div> */}
          </Header>
          <Content style={{margin: '0 16px'}}>
            <div
              className="site-layout-background"
              style={{padding: 24, height: '100%'}}
            >
              <div className="codingEditor__headerWrapper">
                <h1 className="codingEditor__heading">Coding Editor</h1>
                <div className="codingEditor__controls">
                  <label className="codingEditor__label">Language</label>
                  <Select
                    showSearch
                    style={{width: 150}}
                    defaultValue="java"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase ()
                        .indexOf (input.toLowerCase ()) >= 0}
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase ()
                        .localeCompare (optionB.children.toLowerCase ())}
                    onChange={(event, value) =>
                      handleLanguageChange (event, value)}
                  >
                    {editorLanguageInfo.map ((item, index) => {
                      return (
                        <Option value={item.value} key={index}>
                          {item.name}
                        </Option>
                      );
                    })}

                  </Select>
                </div>

                <div className="codingEditor__controls">
                  <label className="codingEditor__label">Theme</label>
                  <Select
                    showSearch
                    style={{width: 120}}
                    defaultValue="monokai"
                    optionFilterProp="value"
                    filterOption={(input, option) =>
                      option.value
                        .toLowerCase ()
                        .indexOf (input.toLowerCase ()) >= 0}
                    filterSort={(optionA, optionB) =>
                      optionA.value
                        .toLowerCase ()
                        .localeCompare (optionB.value.toLowerCase ())}
                    onChange={handleThemeChange}
                  >
                    <Option value="monokai">Monokai</Option>
                    <Option value="github">Github</Option>
                    <Option value="kuroir">Kuroir</Option>
                    <Option value="twilight">Twilight</Option>
                    <Option value="xcode">X Code</Option>
                    <Option value="solarized_dark">Solarized Dark</Option>
                    <Option value="solarized_light">Solarized Light</Option>
                    <Option value="terminal">Terminal</Option>
                  </Select>
                </div>

                <div className="codingEditor__controls">
                  <label className="codingEditor__label">Font Size</label>
                  <Select defaultValue="16" onChange={handleFontSizeChange}>
                    <Option value="14">14</Option>
                    <Option value="16">16</Option>
                    <Option value="18">18</Option>
                    <Option value="20">20</Option>
                    <Option value="24">24</Option>
                    <Option value="28">28</Option>
                    <Option value="32">32</Option>
                    <Option value="40">40</Option>
                  </Select>
                </div>

                <Button
                  type={'primary'}
                  className="primaryBtn"
                  onClick={handleRunCode}
                >
                  {loading
                    ? <span><Spin indicator={spinIcon} /> Compiling</span>
                    : <span><FaPlay /> Run Code </span>}
                </Button>

              </div>

              <div className="codingEditor__mainEditor">
                <AceEditor
                  placeholder="Let's Write Some code here"
                  mode={language}
                  theme={theme}
                  name="blah2"
                  style={{width: '50%'}}
                  onChange={handleEditorContent}
                  fontSize={fontSize}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                />

                <div className="compiler__output__area">
                  <h3>Input</h3>
                  <Input
                    placeholder="Your Input"
                    value={input}
                    onChange={handleInput}
                  />
                  <div className="compiler__output">
                    {err
                      ? <code className="err">{err}</code>
                      : <code>{outputResult}</code>}
                  </div>
                </div>

              </div>
            </div>
          </Content>
          <Footer style={{textAlign: 'center'}}>Codersarts</Footer>
        </Layout>
      </Layout>

    </div>
  );
}

export default CodeEditor;
