//login, register, logout actions

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  INVALID_PASSWORD,
  RESET_INVALID_PASSWORD,
} from './types';

import AuthService from '../services/auth.service';

export const register = userData => dispatch => {
  return AuthService.register (userData).then (
    response => {
      dispatch ({
        type: REGISTER_SUCCESS,
      });

      dispatch ({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve ();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();

      dispatch ({
        type: REGISTER_FAIL,
      });

      dispatch ({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject ();
    }
  );
};

export const login = (username, password) => dispatch => {
  return AuthService.login (username, password).then (
    data => {
      dispatch ({
        type: LOGIN_SUCCESS,
        payload: {user: data},
      });

      // runLogOutTimer(dispatch,3000000);
      return Promise.resolve ();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();
      if (message.toLowerCase () === 'invalid password!') {
        dispatch ({
          type: INVALID_PASSWORD,
        });
      }

      dispatch ({
        type: LOGIN_FAIL,
      });
      dispatch ({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject ();
    }
  );
};

export const logout = () => dispatch => {
  AuthService.logout ();
  dispatch ({
    type: LOGOUT,
  });

  dispatch ({
    type: SET_MESSAGE,
    payload: '',
  });
};

export const setMsgNull = () => dispatch => {
  dispatch ({
    type: SET_MESSAGE,
    payload: '',
  });
};

export const resetInvalidPasswordCount = () => {
  return {
    type: RESET_INVALID_PASSWORD,
  };
};
