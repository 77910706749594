import React from 'react';
import './Mentorship.css';
import mentorshipImg from '../../assets/mentoship.png'


function Mentorship() {
  return(
    <div className='mentorshipSection'>
        <div className='mentorshipText'>
            <h1>Need Help in your Upcoming Project?</h1>
            <p>Get Any Type of  Programming Assignment Help,
             Coding Help, Project Help in C++,Java, Python,
            Machine Learning, Data Science, R, Android, 
            IOS,React Native,Web programming and much more! </p>
            <a href="https://www.codersarts.com/" target={'_blank'}  rel="noreferrer" className='primaryBtn'>Read More</a>
        </div>
        <div className='mentorshipImage'>
            <img src={mentorshipImg} alt="mentorship" />
        </div>
    </div>
  );
}

export default Mentorship;
