import React from 'react';
import { Link } from 'react-router-dom';
import error from '../../assets/error.gif';
import './ErrorPage.css';

function ErrorPage() {
    return (
        <div className="error-container">
            <div>
                <img src={error} className="error-img" alt="error" />
            </div>
            <div>
                <Link to="/" className="error-btn">Back To Home !</Link>
            </div>
        </div>
    )
}

export default ErrorPage
