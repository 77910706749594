import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
  ProjectOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import './AdminDashboard.css';
import logo from '../../assets/codersartsLogo.png';
import avatar from '../../assets/login.png';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import ProfileDropdown from '../../components/Navbar/ProfileDropdown';
import {history} from '../../helpers/history';
import {useDispatch} from 'react-redux';
import {BiBookReader, BiHomeCircle} from 'react-icons/bi';
import {MdOutlineDashboardCustomize} from 'react-icons/md';
import {useSelector} from 'react-redux';
import DashboardHomeAdmin from './DashboardHomeAdmin/DashboardHomeAdmin';
import ManageCourses from './ManageCourses/ManageCourses';
import ManageStudents from './ManageStudents/ManageStudents';
// import {Route} from 'react-router';
import RouteForAdmin from '../../RouteForAdmin';
import CreateCourses from './ManageCourses/CreateCourses/CreateCourses';
import CreateProjects from './ManageProjects/CreateProjects/CreateProjects';
import AdminCourse from './AdminCourse/AdminCourse';
import CourseMaterialDetails
  from './AdminCourse/CourseMaterialDetails/CourseMaterialDetails';
// import RichEditor from '../RichEditor/RichEditor';
import QuillEditor from '../RichEditor/QuillEditor';
import StudentsDetail from './ManageStudents/StudentsDetail';
import ManageProjects from './ManageProjects/ManageProjects';
import EditProject from './ManageProjects/EditProject/EditProject';
import {AiFillDollarCircle, AiOutlineProject} from 'react-icons/ai';
import ManagePrograms from './ManagePrograms/ManagePrograms';
import {FiTarget} from 'react-icons/fi';
import CreatePrograms from './ManagePrograms/CreatePrograms/CreatePrograms';
import EditPrograms from './ManagePrograms/EditPrograms/EditPrograms';
import AdminPrice from './AdminPrice/AdminPrice';

const {Header, Sider, Content} = Layout;

const {SubMenu} = Menu;

function AdminDashboard () {
  const [collapsed, setCollapsed] = useState (false);
  const {user: currentUser} = useSelector (state => state.auth);
  const dispatch = useDispatch ();
  const toggle = () => {
    setCollapsed (prevValue => !prevValue);
  };

  const logOut = () => {
    dispatch (logout ());
    history.push ('/login');
  };

  return (
    <Layout className="adminDashboard">
      <Sider trigger={null} className="sider" collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item
            key="1"
            icon={<MdOutlineDashboardCustomize />}
            className="customSideLink"
          >
            Dashboard
            <Link to="/admin/dashboard" />
          </Menu.Item>
          <SubMenu key="Manage" icon={<SettingOutlined />} title="Manage">
            <Menu.Item
              key="2"
              icon={<BiBookReader />}
              className="customSideLink"
            >
              Courses
              <Link to="/admin/dashboard/manage-courses" />
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<UserOutlined />}
              className="customSideLink"
            >
              Students
              <Link to="/admin/dashboard/manage-students" />
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<ProjectOutlined />}
              className="customSideLink"
            >
              Projects
              <Link to="/admin/dashboard/manage-projects" />
            </Menu.Item>
            <Menu.Item key="5" icon={<FiTarget />} className="customSideLink">
              Programs
              <Link to="/admin/dashboard/manage-programs" />
            </Menu.Item>
            <Menu.Item key="6" icon={<DollarCircleOutlined />} className="customSideLink">
              Pricing
            <Link to="/admin/dashboard/manage-price" />
            </Menu.Item>
          </SubMenu>

        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background adminNav">
          {React.createElement (
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: toggle,
            }
          )}
          <div className="logoArea">
            <div> <img src={logo} alt="logo" /></div>
            <div className="logoTitles">
              <div className="logoText">
                Codersarts <span className="green">Learning </span>
              </div>
            </div>
          </div>
          <div className="navigationLinks">
            <Link to="/" className="navLink">
              <BiHomeCircle className="navIcon" /> Home
            </Link>
            <Link to="/courses" className="navLink">
              <BiBookReader className="navIcon" />Courses
            </Link>
            <Link to="/all-projects" className="navLink">
              <AiOutlineProject className="navIcon" /><span>Projects</span>
            </Link>

            {/* <Link to="/all-programs" className="navLink">
              <FiTarget className="navIcon" /><span>Programs</span>
            </Link> */}

          </div>
          <div className="profileItems">
            <div className="nav-item profile-items">
              <img
                src={
                  currentUser.profilePicture
                    ? currentUser.profilePicture
                    : avatar
                }
                className="profile-avatar"
                alt="img"
              />
            </div>
            <ProfileDropdown
              user={currentUser}
              name={currentUser.username}
              logout={logOut}
            />
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 70,
            minHeight: 280,
          }}
        >
          <RouteForAdmin
            exact
            path="/admin/dashboard"
            component={DashboardHomeAdmin}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/manage-courses"
            component={ManageCourses}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/manage-students"
            component={ManageStudents}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/create-new-course"
            component={CreateCourses}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/text-based-editor"
            component={QuillEditor}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/edit-course-details/:id"
            component={AdminCourse}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/view-course-material/:id"
            component={CourseMaterialDetails}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/view-student-details/:id"
            component={StudentsDetail}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/manage-projects"
            component={ManageProjects}
          />

          <RouteForAdmin
            exact
            path="/admin/dashboard/create-new-project"
            component={CreateProjects}
          />
          <RouteForAdmin
            exact
            path="/admin/dashboard/edit-project-details/:id"
            component={EditProject}
          />

          <RouteForAdmin
            exact
            path="/admin/dashboard/manage-programs"
            component={ManagePrograms}
          />

          <RouteForAdmin
            exact
            path="/admin/dashboard/create-new-program"
            component={CreatePrograms}
          />

          <RouteForAdmin
            exact
            path="/admin/dashboard/edit-program-details/:id"
            component={EditPrograms}
          />

          <RouteForAdmin
            exact
            path="/admin/dashboard/manage-price"
            component={AdminPrice}
          />

        </Content>
      </Layout>
    </Layout>
  );
}

export default AdminDashboard;
