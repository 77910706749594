import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import styles from './ViewProgramDetails.module.css';
import {message, Tag} from 'antd';
import ProgramServices from '../../services/programs.service';
import CourseServices from '../../services/courses.service';
import CourseCard from '../Courses/CourseCard/CourseCard';

function ViewProgramDetails () {
    const [program,setProgram] = useState(""); 
    const [allCourses,setAllCourses] = useState([]);
    const params = useParams ();
    const {id} = params;

  useEffect (
    () => {
      let isMounted = true;
      const getSingleProgram = async () => {
        try {
          const response = await ProgramServices.getProgramById (id);
          console.log (response.data);
          setProgram(response.data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      if (isMounted) {
        getSingleProgram ();
      }

      return () => {
        isMounted = false;
      };
    },
    [id]
  );

  useEffect(  ()=>{
      
    const getCourses = async ()=>{
        if(program && allCourses.length ===0){
            for(let i in program.courseGroup){
                try{
                    const response = await CourseServices.getCourseById(program.courseGroup[i]);
                    console.log(response.data);
                    setAllCourses(prev=>{
                        return [...prev,response.data]
                        
                    });
                }catch(err){
                    console.log(err);
                }
             
            }
        }
    }
    getCourses();

  
  },[program,allCourses]);

  console.log(allCourses);

  return (
  <div className={styles.programDetails}>
    <div className={styles.programDetails__header} 
    style={
        { backgroundImage: `
        linear-gradient(to bottom,  rgba(0, 12, 53, 0.8),rgba(4, 44, 155, 0.7)),
        url('${program.imageUrl}')`,
        backgroundSize: 'cover'}}>
         <Tag color={"#01df5a"}>
                       {program?.category}
        </Tag>
        <h2>{program?.title}</h2>
        <p>{program?.description}</p>
    </div>

    <div className={styles.programDetails__body}>
        <h2>Courses</h2>
        <div className={styles.programDetails__cardGroup}>
           
                { allCourses.map (course => {
                  return (
                    <CourseCard
                      key={course.id}
                      img={course.imageUrl}
                      description={course.description}
                      title={course.title}
                      id={course.id}
                    />
                  );
                })}
            
        </div>
    </div>

  </div>);
}

export default ViewProgramDetails;
