import { PanelResizeHandle } from "react-resizable-panels";

import './LearnCourse.css';
import { Button, Tooltip } from "antd";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

export default function ResizeHandle({
  className = "",
  id,
  showVideoPanel,
  moveCollapseBtn,
  handleVideoPanelCollapseClick
}){
  return (
    <PanelResizeHandle
      className={["ResizeHandleOuter",className].join(' ')}
      id={id}
    >
      <div className={"ResizeHandleInner"}>
      {!moveCollapseBtn && <Button size="small"  onClick={handleVideoPanelCollapseClick}>{showVideoPanel ? <Tooltip title="Hide Video?"><BiChevronRight style={{fontSize:'1.5rem'}} /></Tooltip> :  <Tooltip title="Show Video?"><BiChevronLeft style={{fontSize:'1.5rem'}}  /></Tooltip>}</Button>}
        <svg className={"Icon"} viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
          />
        </svg>
      </div>
    </PanelResizeHandle>
  );
}
