import React from 'react';
import { Redirect, Route } from 'react-router';
// import Navbar from './components/Navbar/Navbar';
// import { history } from "./helpers/history";
import {useSelector} from 'react-redux';

function RouteForAdmin({component:Component, ...rest}){
    const {user:currentUser} = useSelector((state)=>state.auth);
    return(
       currentUser && currentUser.roles[0].toLowerCase()==='role_admin' ?
       <Route {...rest}>
      
       <Component />
      </Route>: 
      <Redirect to="/" />);
}

export default RouteForAdmin;