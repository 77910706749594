import React, {useState, useEffect} from 'react';
import './CourseMaterial.css';
import {BsPlusLg} from 'react-icons/bs';
import {
    Button,
  Collapse,
  Dropdown,
  InputNumber,
  Menu,
  message,
  Modal,
  Radio,
  Select,
  Spin,
  Tooltip,
  Upload,
} from 'antd';
import selectType from '../../../../assets/select.png';
import {
  EditOutlined,
  EyeOutlined,
  InboxOutlined,
  LoadingOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import CourseServices from '../../../../services/courses.service';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {history} from '../../../../helpers/history';
import {DragDropContext,Draggable,Droppable} from 'react-beautiful-dnd';


const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);
const {Option} = Select;
const {Panel} = Collapse;
function CourseMaterial (props) {
  const [loading, setLoading] = useState (false);
  const [isAddModal, setIsAddModal] = useState (false);
  const [materialType, setMaterialType] = useState ('');
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState (true);
  const [isAddSection, setIsAddSection] = useState (false);
  const [newSection, setNewSection] = useState ('');
  const [courseSectionArr, setCourseSectionArr] = useState ([]);
  const [newMaterialSection, setNewMaterialSection] = useState ('');
  const [materialTitle, setMaterialTitle] = useState ('');
  const [materialTextData, setMaterialTextData] = useState ('');
  const [isMaterialLocked, setIsMaterialLocked] = useState (true);
  const [isDownloadAllowed, setIsDownloadAllowed] = useState (false);
  const [courseMaterialArr, setCourseMaterialArr] = useState ([]);
  const [editSectionModal, setEditSectionModal] = useState (false);
  const [currentEditSectionItem, setCurrentSectionEditItem] = useState ('');
  const [currentSectionName, setCurrentSectionName] = useState ('');
  const [currentOrderUpdateMaterial, setCurrentOrderUpdateMaterial] = useState (
    ''
  );
  const [updateOrderModal, setUpdateOrderModal] = useState (false);
  const [updatedOrderId, setUpdatedOrderId] = useState ('');
  const [updatedSections,setUpdatedSections] = useState([]);
  const [sectionOrder,setSectionOrder] = useState("");
  const [explanationFile,setExplanationFile] = useState([]);
  const [explanationFileUrl,setExplanationFileUrl] = useState("");
  const [uploadingExpFile,setUploadingExpFile] = useState(false);
  const {user: currentUser} = useSelector (state => state.auth);
  const [explanationDisableBtn,setExplanationFileDisabledBtn] = useState(true);

  const getCourseSection = async () => {
    try {
      const response = await CourseServices.getCourseSection (
        props.courseDetails.id
      );
      // console.log(response.data);
      let data = response.data.sort((a,b)=>a?.order - b?.order);
      setCourseSectionArr (data);
      setUpdatedSections(data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch course section!'
      );
    }
  };

  const getCourseMaterial = async () => {
    try {
      const response = await CourseServices.getCourseMaterial (
        props.courseDetails.id
      );
      console.log(response.data);
      setCourseMaterialArr (response.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch course section!'
      );
    }
  };


  useEffect (
    () => {
      let isMount = true;
      const getCourseSection = async () => {
        try {
          const response = await CourseServices.getCourseSection (
            props.courseDetails.id
          );
          // console.log(response.data);
          let data = response.data?.sort((a,b)=>a?.order - b?.order);
          if (isMount) {
            setCourseSectionArr (data);
            setUpdatedSections(data);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch course section!'
          );
        }
      };

      const getCourseMaterial = async () => {
        // console.log("Calling material!");
        try {
          const response = await CourseServices.getCourseMaterial (
            props.courseDetails.id
          );
        //   console.log("Data : ",response);
          if (isMount) {
            // console.log("setting state")
            setCourseMaterialArr (response.data);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch course section!'
          );
        }
      };
      getCourseSection ();
      getCourseMaterial ();
      return () => {
        isMount = false;
      };
    },
    [props.courseDetails.id]
  );



  useEffect(()=>{
    setUpdatedSections((prev)=>{
      let final = prev.map(section=>{
        let material = courseMaterialArr.filter(item=>item.CourseSectionId===section.id)
        let sortedMaterial = material.sort((a,b)=>a?.order-b?.order);
        let finalSection = {
          ...section,
          material:sortedMaterial
        }
       
        return finalSection;
      });
      // console.log(final);
      return final;
      
    })

   
  },[courseMaterialArr])


  // useEffect(()=>{
  //   console.log(updatedSections);
  // },[updatedSections])

  const handleAddCourseMaterial = () => {
    if (courseSectionArr.length > 0) {
      setIsAddModal (true);
    } else {
      message.warning ('Please add atleast one section!');
    }

    // console.log("add material!");
  };

  const handleAddSection = () => {
    setIsAddSection (true);
    // console.log("add material!");
  };

  const handleConfirmAddMaterial = async () => {
    setLoading (true);

    let materialData = {
      title: materialTitle,
      textData: materialTextData,
      type: materialType,
      fileLink: fileUrl,
      isLocked: isMaterialLocked,
      CourseSectionId: newMaterialSection,
      userId: currentUser.id,
      courseId: props.courseDetails.id,
      isInstructorConnect: false,
      isEnabled: false,
      explanationFile:explanationFileUrl,
      isDownloadAllowed:isDownloadAllowed
    };
    console.log ('Final Data : ', materialData);
    try {
      const response = await CourseServices.createNewMaterial (materialData);
      // console.log(response.data);
      if (response) {
        setLoading (false);
        setIsAddModal (false);
        message.success ('Added new Material Successfully!');
        getCourseMaterial ();
      }
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();
      console.log (error);
      setLoading (false);
      message.error (msg);
    }
  };

  const handleCancelAddModal = () => {
    // console.log("cancel");
    setIsAddModal (false);
  };

  const handleMaterialType = value => {
    // console.log(value);
     setMaterialType(value)
  };

  function beforeUpload (file) {
    if (file) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    return false;
  }

  
  function beforeNotebookUpload (file) {
    const isHtml = file.type === 'text/html';
    if (!isHtml) {
      message.error(`${file.name} is not a html file`);
    }
    if (file && isHtml) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    return isHtml || false;
  }

  


  function beforeExplanationUpload(file){
    const isMp4 = file.type === 'video/mp4';
    if (!isMp4) {
      message.error(`${file.name} is not a mp4 file`);
    }

    if (file && isMp4) {
      setExplanationFile ([file]);
      setExplanationFileDisabledBtn(false);
    }
    return isMp4 || false;
  }

  const handleExplanationUpload = async () => {
    setUploadingExpFile (true);
    const files = explanationFile;
    console.log(files);
    
    try {
    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();

    let finalDate = materialTitle + '__' +
      dd + '-' + mm + '-' + yyyy;
    let changedName = finalDate.toString () + '__' + files[0].name;

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploadingExpFile (false);
      // console.log("Data : ",response.data);
      setExplanationFileUrl (response.data.url);
      message.success ('Explanation Video Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploadingExpFile (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };
  const handleUpload = async () => {
    setUploading (true);
    const files = fileList;

    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let finalDate = materialTitle + '__' +
    dd + '-' + mm + '-' + yyyy;
    let changedName = finalDate.toString () + '__' + files[0].name;

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      message.success ('File Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleConfirmAddSection = async () => {
    //   console.log("added!");
    setLoading (true);
    try {
      const response = await CourseServices.createNewCourseSection ({
        title: newSection,
        courseId: props.courseDetails.id,
        userId: currentUser.id,
      });
      // console.log(response.data);
      if (response) {
        setLoading (false);
        setIsAddSection (false);
        setNewSection ('');
        getCourseSection ();
      }
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();
      console.log (error);
      setLoading (false);
      message.error (msg);
      setLoading (false);
    }
  };

  const handleCancelSection = () => {
    //   console.log("cancel");
    setIsAddSection (false);
  };

  const editSection = id => {
    setEditSectionModal (true);
    let currentSection = courseSectionArr.find (item => {
      return item.id === id;
    });
    //   console.log("Current section name : ",currentSection);
    setCurrentSectionName (currentSection.title);
    setCurrentSectionEditItem (currentSection);
  };

  const handleConfirmEditSection = async () => {
    setLoading (true);
    let updatedData = {
      ...currentEditSectionItem,
      title: currentSectionName,
      userId: currentUser.id,
      order:sectionOrder
    };
    // console.log(updatedData);
    try {
      const response = await CourseServices.updateCourseSection (updatedData);
      // console.log(response.data);
      if (response.data) {
        message.success (
          `Updated ${currentEditSectionItem.title} successfully!`
        );
        setLoading (false);
        setEditSectionModal (false);
        getCourseSection ();
        getCourseMaterial();
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const deleteSection = async id => {
    // console.log("Dekete : ",id);
    let courseMaterials = courseMaterialArr.filter (item => {
      return item.CourseSectionId === id;
    });
    // console.log("Section Material : ",courseMaterials);
    if (courseMaterials.length > 0) {
      message.warning (
        'Please Delete all the course materials in the section first!'
      );
    } else {
      // console.log("deleting!");
      try {
        const response = await CourseServices.deleteCourseSectionById ({
          id: id,
          userId: currentUser.id,
        });
        // console.log(response.data);
        if (response) {
          message.success ('Deleted section successfully!');
          getCourseSection ();
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Something went wrong!');
      }
    }
  };

  const handleEditSectionCancelModal = () => {
    setEditSectionModal (false);
  };

  const addInstructorConnect = (section,checked)=>{
      console.log(section);
      console.log(checked);
      let foundMaterial = courseMaterialArr.find((material)=>{
        return(material.isInstructorConnect && material.CourseSectionId===section.id)
    })
    console.log("found material in add func : ",foundMaterial);

        if(foundMaterial){
            CourseServices.updateCourseMaterial({...foundMaterial, userId: currentUser.id,isEnabled:checked}).then(result=>{
                console.log(result);
                message.success(checked ? "Instructor connect Enabled!":"Instructor connect Disabled!")
                getCourseMaterial();
            }).catch(err=>{
                console.log(err);
                message.error(err.message ? err.message : 'Something went wrong!');
            })
        }else{
            message.info("No Instructor Connect material found!");
         let materialData = {
                title: "Instructor Connect Live 1:1",
                textData: "This is a test instructor connect info",
                type: "Text",
                fileLink: "",
                isLocked: true,
                CourseSectionId: section.id,
                userId: currentUser.id,
                courseId: props.courseDetails.id,
                isInstructorConnect: true,
                isEnabled: true,
            };
            CourseServices.createNewMaterial(materialData).then(result=>{
                console.log(result);
                message.success("Created Default Instructor connect material!");
                getCourseMaterial();
            }).catch(err=>{
                console.log(err.message ? err.message : 'Something went wrong!');
            })
        }
      

  }


  const getExtra = item => {
    let foundMaterial = courseMaterialArr.find((material)=>{
        return(material.isInstructorConnect && material.CourseSectionId===item.id)
    })

    

    // console.log("found material : ",foundMaterial);

    

    const menu = (
      <Menu>
        <Menu.Item key="1">
          <EyeOutlined /> View Section
          <Link
            to={{
              pathname: `/admin/dashboard/view-course-material/${item.id}`,
              state: {
                courseId: props.courseDetails.id,
                courseName: props.courseDetails.title,
                section: item,
                isSingleMaterial: false,
              },
            }}
          />
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            editSection (item.id);
          }}
        >
          <EditOutlined /> Edit Section
        </Menu.Item>
        <Menu.Item
          danger
          key="3"
          onClick={() => {
            deleteSection (item.id);
          }}
        >
          Delete Section
        </Menu.Item>
      </Menu>
    );

    return (
        <>
        <Tooltip title={"Enable/Disable Instructor Connect"} color={"#001529"}>
        <Button  onClick={()=>addInstructorConnect(item,
        foundMaterial ? 
        foundMaterial.isEnabled ? false :true :false)
        } 
        style={{marginRight:'15px'}}>
        {foundMaterial ? foundMaterial.isEnabled ? "Disable":"Enable" : "Enable"}
        </Button>
        </Tooltip>
        <Dropdown overlay={menu}>
        <SettingOutlined
          onClick={event => {
            event.stopPropagation ();
          }}
        />
      </Dropdown>
        </>
     
    );
  };

  const handleNewMaterialSection = value => {
    // console.log("Section : ",value);
    setNewMaterialSection (value);
  };

  const handleMaterialPreview = event => {
    //   console.log("Is Locked : ",event.target.value);
    setIsMaterialLocked (event.target.value);
  };

  const goToEditor = () => {
    history.push ({
      pathname: '/admin/dashboard/text-based-editor',
      state: {
        courseId: props.courseDetails.id,
        isLocked: isMaterialLocked,
        courseName: props.courseDetails.title,
        CourseSectionId: newMaterialSection,
        title: materialTitle,
      },
    });
  };

  const handleOrderUpdateModal = material => {
    //   console.log("material : ",material);
    if (material.order) {
      setUpdatedOrderId (material.order);
    }
    setCurrentOrderUpdateMaterial (material);
    setUpdateOrderModal (true);
  };

  const handleUpdateOrder = async () => {
    setLoading (true);
    let data = {
      ...currentOrderUpdateMaterial,
      order: updatedOrderId,
      userId: currentUser.id,
    };

    try {
      const response = await CourseServices.updateCourseMaterial (data);
      console.log (response.data);
      if (response) {
        setLoading (false);
        setUpdateOrderModal (false);
        setUpdatedOrderId ('');
        message.success ('Updated order of the material successfully!');
        getCourseSection();
        getCourseMaterial ();
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
    //   console.log(data);
  };

  const handleDragSections = async (result)=>{
    // console.log(result);
    // if(!result.destination) return;
   
    // // const [reorderedItem] = items.splice(result.source.index,1);
    // // items.splice(result.destination.index,0,reorderedItem);
   
    // setUpdatedSections((prev)=>{
    //   const items = Array.from(prev);
    //   let destItem = items[result.destination.index];
    //   let sourceItem = items[result.source.index];
    //   console.log(destItem);
    //   console.log(sourceItem);
    //   items[result.source.index] = destItem;
    //   items[result.destination.index] = sourceItem;
    //   return items;
    // });

    console.log(result);
   
    if(!result.destination) return;

      let sourceItem = updatedSections[result.source.index]
      let destItem = updatedSections[result.destination.index];
      console.log(sourceItem);
      console.log(destItem);
      const data = {source:sourceItem,destination:destItem,userId:currentUser.id};
     const response = await CourseServices.handleUpdateSectionOrder(data);
      console.log(response.data);
      getCourseSection();
      getCourseMaterial();

    
  }

  const onMaterialDrag = async (result)=>{
    console.log(result);
   
    if(!result.destination) return;
    let sourceInd = result.source.index;
    let destInd = result.destination.index;
    let currentSection = updatedSections.find(item=>item.id===result.source.droppableId);
    console.log(currentSection);
    if(currentSection){
      let sourceItem = currentSection.material[sourceInd];
      let destItem = currentSection.material[destInd];
      // console.log(sourceItem);
      // console.log(destItem);
      const data = {source:sourceItem,destination:destItem,userId:currentUser.id};
     await CourseServices.handleUpdateMaterialOrder(data);
      // console.log(response.data);
      getCourseMaterial();
    
    }
   
    
  }
  return (
    <div className="addCourseMaterial">
      <div className="addAreaWrapper">
        <div className="addArea1">
          <h1>Add Material</h1>
          <div className="addButton" onClick={handleAddCourseMaterial}>
            <BsPlusLg className="plusIcon" onClick={handleAddCourseMaterial} />
          </div>
        </div>
        <div className="addArea2">
          <h1>Add Section</h1>
          <div className="addButton" onClick={handleAddSection}>
            <BsPlusLg className="plusIcon" onClick={handleAddSection} />
          </div>
        </div>
      </div>

      <Modal
        title="Add New Course Material"
        visible={isAddModal}
        confirmLoading={loading}
        okText={'Create'}
        onOk={handleConfirmAddMaterial}
        onCancel={handleCancelAddModal}
      >

        <Select
          placeholder="Select Format"
          style={{width: '100% ', marginBottom: '5%'}}
          onChange={handleMaterialType}
        >
          <Option value="Text">Text</Option>
          <Option value="Rich Text">Rich Text</Option>
          <Option value="Video">Video</Option>
          <Option value="Resource">Resource</Option>
          <Option value="Document">Document</Option>
          <Option value="Notebook">Notebook</Option>

        </Select>

        {materialType === ''
          ? <div className="add__coursematerial__noType">
              <div><img src={selectType} alt="img" /></div>
              <div className="add__coursematerial__selectMsg">
                Please Select Course Material Format
              </div>
            </div>
          : materialType === 'Text'
              ? <div className="materialForm">
                  <Select
                    placeholder="Select Section"
                    style={{width: '100% ', marginBottom: '5%'}}
                    onChange={handleNewMaterialSection}
                  >
                    {courseSectionArr.map (item => {
                      return (
                        <Option value={item.id} key={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                  </Select>
                  <input
                    type="text"
                    value={materialTitle}
                    onChange={e => {
                      setMaterialTitle (e.target.value);
                    }}
                    placeholder="Enter Text Title"
                  />
                  <textarea
                    width="100%"
                    value={materialTextData}
                    onChange={e => {
                      setMaterialTextData (e.target.value);
                    }}
                    placeholder="Enter Material Text"
                  />
                  <Radio.Group
                    onChange={handleMaterialPreview}
                    defaultValue={isMaterialLocked}
                  >
                    <Radio value={false}>Allow Preview</Radio>
                    <Radio value={true}>Disable Preview</Radio>
                  </Radio.Group>
                </div>
              : materialType === 'Video'
                  ? <div className="materialForm">
                      <Select
                        placeholder="Select Section"
                        style={{width: '100% ', marginBottom: '5%'}}
                        onChange={handleNewMaterialSection}
                      >
                        {courseSectionArr.map (item => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                      </Select>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Video Title"
                          value={materialTitle}
                          onChange={e => {
                            setMaterialTitle (e.target.value);
                          }}
                        />
                      </div>

                      <textarea
                        width="100%"
                        value={materialTextData}
                        onChange={e => {
                          setMaterialTextData (e.target.value);
                        }}
                        placeholder="Enter Any Description"
                      />
                      <Radio.Group
                        onChange={handleMaterialPreview}
                        defaultValue={isMaterialLocked}
                      >
                        <Radio value={false}>Allow Preview</Radio>
                        <Radio value={true}>Disable Preview</Radio>
                      </Radio.Group>

                      <Upload.Dragger
                        name="files"
                        beforeUpload={beforeUpload}
                        onRemove={() => {
                          setFileList ([]);
                        }}
                        className="uploader"
                        fileList={fileList}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined style={{color: 'var(--green)'}} />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag Video to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          You can upload video files such as .mp4,.mkv, .wmv or .avi
                        </p>
                      </Upload.Dragger>
                      <button
                        type="button"
                        disabled={
                          uploading ||
                            fileList.length === 0 ||
                            uploadBtnDisabled
                        }
                        className="primaryBtn"
                        style={{marginTop: '5px', marginBottom: '5px'}}
                        onClick={handleUpload}
                      >
                        {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
                      </button>
                    </div>
                  : (materialType === 'Resource')
                      ? <div className="materialForm">
                          <Select
                            placeholder="Select Section"
                            style={{width: '100% ', marginBottom: '5%'}}
                            onChange={handleNewMaterialSection}
                          >
                            {courseSectionArr.map (item => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                          <div>
                            <input
                              type="text"
                              value={materialTitle}
                              onChange={e => {
                                setMaterialTitle (e.target.value);
                              }}
                              placeholder="Enter Resource Title"
                            />
                          </div>

                          <textarea
                            width="100%"
                            value={materialTextData}
                            onChange={e => {
                              setMaterialTextData (e.target.value);
                            }}
                            placeholder="Enter Any Description"
                          />
                          <Radio.Group
                            onChange={handleMaterialPreview}
                            defaultValue={isMaterialLocked}
                          >
                            <Radio value={false}>Allow Preview</Radio>
                            <Radio value={true}>Disable Preview</Radio>
                          </Radio.Group>

                          <Upload.Dragger
                            name="files"
                            beforeUpload={beforeUpload}
                            onRemove={() => {
                              setFileList ([]);
                            }}
                            className="uploader"
                            fileList={fileList}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{color: 'var(--green)'}} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag File to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              You upload any resource file such as .zip, .rar, .pdf, .docx etc.
                            </p>
                          </Upload.Dragger>
                          <button
                            type="button"
                            disabled={
                              uploading ||
                                fileList.length === 0 ||
                                uploadBtnDisabled
                            }
                            className="primaryBtn"
                            style={{marginTop: '5px', marginBottom: '5px'}}
                            onClick={handleUpload}
                          >
                            {uploading
                              ? <Spin indicator={spinIcon} />
                              : 'Upload'}
                          </button>
                        </div>
                        :
                         materialType==='Document' ? 
                      <div className="materialForm">
                          <Select
                            placeholder="Select Section"
                            style={{width: '100% ', marginBottom: '5%'}}
                            onChange={handleNewMaterialSection}
                          >
                            {courseSectionArr.map (item => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                          <div>
                            <input
                              type="text"
                              value={materialTitle}
                              onChange={e => {
                                setMaterialTitle (e.target.value);
                              }}
                              placeholder="Enter Material Title"
                            />
                          </div>

                          <textarea
                            width="100%"
                            value={materialTextData}
                            onChange={e => {
                              setMaterialTextData (e.target.value);
                            }}
                            placeholder="Enter Any Description"
                          />
                          <Radio.Group
                            onChange={handleMaterialPreview}
                            defaultValue={isMaterialLocked}
                          >
                            <Radio value={false}>Allow Preview</Radio>
                            <Radio value={true}>Disable Preview</Radio>
                          </Radio.Group>

                          <Upload.Dragger
                            name="files"
                            beforeUpload={beforeUpload}
                            onRemove={() => {
                              setFileList ([]);
                            }}
                            className="uploader"
                            fileList={fileList}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{color: 'var(--green)'}} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag File to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              You can upload document file such as .pdf, .docx etc for preview.
                            </p>
                          </Upload.Dragger>
                          <button
                            type="button"
                            disabled={
                              uploading ||
                                fileList.length === 0 ||
                                uploadBtnDisabled
                            }
                            className="primaryBtn"
                            style={{marginTop: '5px', marginBottom: '5px'}}
                            onClick={handleUpload}
                          >
                            {uploading
                              ? <Spin indicator={spinIcon} />
                              : 'Upload'}
                          </button>
                        </div>  
                      : materialType === 'Rich Text'
                          ? <div className="materialForm richEditorModal">
                              <Select
                                placeholder="Select Section"
                                style={{width: '100% ', marginBottom: '5%'}}
                                onChange={handleNewMaterialSection}
                              >
                                {courseSectionArr.map (item => {
                                  return (
                                    <Option value={item.id} key={item.id}>
                                      {item.title}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <input
                                type="text"
                                value={materialTitle}
                                onChange={e => {
                                  setMaterialTitle (e.target.value);
                                }}
                                placeholder="Enter Text Title"
                              />
                              <br />
                              <Radio.Group
                                onChange={handleMaterialPreview}
                                defaultValue={isMaterialLocked}
                              >
                                <Radio value={false}>Allow Preview</Radio>
                                <Radio value={true}>Disable Preview</Radio>
                              </Radio.Group>

                              <h2>
                                Please create your course material Data into the editor!
                              </h2>
                              <button
                                disabled={!materialTitle || !newMaterialSection}
                                className="primaryBtn"
                                onClick={goToEditor}
                              >

                                Go to Editor
                              </button>
                            </div>
                          : 
                        materialType === 'Notebook' 
                        ?
                        <div className="materialForm">
                          <Select
                            placeholder="Select Section"
                            style={{width: '100% ', marginBottom: '5%'}}
                            onChange={handleNewMaterialSection}
                          >
                            {courseSectionArr.map (item => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                          <div>
                            <input
                              type="text"
                              value={materialTitle}
                              onChange={e => {
                                setMaterialTitle (e.target.value);
                              }}
                              placeholder="Enter Resource Title"
                            />
                          </div>

                          <textarea
                            width="100%"
                            value={materialTextData}
                            onChange={e => {
                              setMaterialTextData (e.target.value);
                            }}
                            placeholder="Enter Any Description"
                          />
                          <Radio.Group
                            onChange={handleMaterialPreview}
                            defaultValue={isMaterialLocked}
                          >
                            <Radio value={false}>Allow Preview</Radio>
                            <Radio value={true}>Disable Preview</Radio>
                          </Radio.Group>

                             <br/> 

                             <Radio.Group
                                onChange={(e)=>setIsDownloadAllowed(e.target.value)}
                                defaultValue={isDownloadAllowed}
                              >
                                <Radio value={true}>Allow Download</Radio>
                                <Radio value={false}>Disable Download</Radio>
                              </Radio.Group>

                            <br/>
                           <div><b>Select a Notebook: </b></div>  
                           <div className="uploadBtnWrapper"> 
                           <Upload
                            name="files1"
                            beforeUpload={beforeNotebookUpload}
                            onRemove={() => {
                              setFileList ([]);
                            }}
                            className="uploader"
                            fileList={fileList}
                            accept='text/html'
                          >
                         
                          <Tooltip title="Make Sure notebook format should be html"><Button icon={<UploadOutlined />}>Select a Notebook</Button></Tooltip>

                          
                          </Upload>
                        
                          <button
                            type="button"
                            disabled={
                              uploading ||
                                fileList.length === 0 ||
                                uploadBtnDisabled
                            }
                            className="primaryBtn"
                            style={{marginTop: '5px', marginBottom: '5px'}}
                            onClick={handleUpload}
                          >
                            {uploading
                              ? <Spin indicator={spinIcon} />
                              : 'Upload Notebook'}
                          </button>
                          </div>

                         <br/>
                          <div><b>Select an Explanation Video: </b></div>
                         <div className='uploadBtnWrapper'>
                          <Upload
                            
                            name="files"
                            beforeUpload={beforeExplanationUpload}
                            onRemove={() => {
                              setExplanationFile ([]);
                            }}
                            className="uploader"
                            fileList={explanationFile}
                            accept='video/mp4'
                          >

                            <Tooltip title="Make Sure video format should be mp4"> <Button icon={<UploadOutlined />}>Select Video</Button></Tooltip>
                           
                            
                          </Upload>

                           <button
                            type="button"
                            disabled={
                              uploadingExpFile ||
                                explanationFile.length === 0
                                || explanationDisableBtn
                            }
                            className="primaryBtn"
                            style={{marginTop: '5px', marginBottom: '5px'}}
                            onClick={handleExplanationUpload}
                          >
                            {uploadingExpFile
                              ? <Spin indicator={spinIcon} />
                              : 'Upload Video'}
                          </button>
                         </div>
                        </div>
                        :
                          
                          null}

        {/* {
        materialType==="" ? null : 
        <div style={{marginTop:'1%',marginBottom:'1%'}}>
         <label><b>Enter Material Order:</b> </label>
        <InputNumber defaultValue={orderId} style={{marginLeft:'2%'}} onChange={(value)=>{setOrderId(value)}} />
        </div>
        } */}

      </Modal>

      <Modal
        title="Edit Section Name"
        visible={editSectionModal}
        confirmLoading={loading}
        okText={'Update'}
        onOk={handleConfirmEditSection}
        onCancel={handleEditSectionCancelModal}
      >

        <div className="materialForm">
          <label>
            <b>Section Name</b>
          </label>

          <input
            placeholder="Enter Updated Section Name"
            type="text"
            onChange={e => {
              setCurrentSectionName (e.target.value);
            }}
            value={currentSectionName}
          />
          
        </div>
        {currentEditSectionItem.order===null &&
        <div style={{marginTop:'10px'}}>
        <label>Enter Section Order</label>
        <InputNumber  style={{marginLeft:'2%',display:'block'}} onChange={value => {
              setSectionOrder (value);
            }}
            value={sectionOrder} />
           
         
        </div>
      }
      </Modal>

      <Modal
        title="Update Order of Material"
        visible={updateOrderModal}
        confirmLoading={loading}
        okText={'Update'}
        onOk={handleUpdateOrder}
        onCancel={() => {
          setUpdateOrderModal (false);
        }}
      >

        <div className="materialForm">
          <label><b>Enter Material Order:</b></label>
          <InputNumber
            defaultValue={updatedOrderId}
            onChange={value => {
              setUpdatedOrderId (value);
            }}
          />
        </div>
      </Modal>

      <Modal
        title="Add New Section"
        visible={isAddSection}
        confirmLoading={loading}
        okText={'Add'}
        onOk={handleConfirmAddSection}
        onCancel={handleCancelSection}
      >

        <div className="materialForm">
          <input
            placeholder="Enter New Section Name"
            type="text"
            onChange={e => {
              setNewSection (e.target.value);
            }}
            value={newSection}
          />
        </div>

      </Modal>
     <DragDropContext onDragEnd={handleDragSections}>
            <Droppable droppableId='sections'>
              {(provided)=>(
                <div {...provided.droppableProps} ref={provided.innerRef}>
                {updatedSections?.map ((item, index) => {
        return (
            <Draggable index={index} key={item.id} draggableId={item.id.toString()}>
              {(provided)=>{
                return(
                  <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                  <DragDropContext onDragEnd={onMaterialDrag}>
                    <Droppable droppableId={item.id}>
                      {(provided)=>{
                        return(
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            
                  <Collapse className="courseSectionCollapse" >

<Panel header={item.title}  extra={getExtra (item)}>
  {item?.material?.map ((material, index) => {
      return (
       <Draggable key={material.id} index={index} draggableId={material.id}>
        {(provided)=>{
          return(
            <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} className="materialBox">
          <Link
            to={{
              pathname: `/admin/dashboard/view-course-material/${item.id}`,
              state: {
                courseId: props.courseDetails.id,
                courseName: props.courseDetails.title,
                section: item,
                isSingleMaterial: true,
                materialId: material.id,
              },
            }}
          >
            <b>
              {material.title}
            </b>
          </Link>
         {!material.order &&  <span>
            <EditOutlined
              style={{color: '#01DF5A', marginLeft: '2%'}}
              onClick={() => {
                handleOrderUpdateModal (material);
              }}
            />{' '}
          </span>}
        </div>
          )
        }}
       </Draggable>
      );
    })}

  
</Panel>

                    </Collapse>
                    {provided.placeholder}
                          </div>
                          
                        )
                       
                      }}
                      
                    </Droppable>
                  </DragDropContext>

                  </div>
                )
              }}
            </Draggable>
              );
                })}
                {provided.placeholder}
                </div>
              )}
            </Droppable>

     </DragDropContext>

    </div>
  );
}

export default CourseMaterial;
