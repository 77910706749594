import {Menu, Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const ProfileDropdown = props => {
  const menu = (
    <Menu>
      {props?.user?.roles[0].toLowerCase () === 'role_admin'
        ? <Menu.Item key="profile">
            <Link to="/admin/dashboard">
              Dashboard
            </Link>
          </Menu.Item>
        : <Menu.Item key="profile">
            <Link to="/dashboard">
              Dashboard
            </Link>
          </Menu.Item>}
      <Menu.Item key="logout" danger onClick={props.logout}>Logout</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <a
        href="/"
        style={{display: 'flex', color: 'black'}}
        onClick={e => e.preventDefault ()}
      >
        <div>{props.name}</div>
        <div style={{marginLeft: '8px'}}>
          <DownOutlined />
        </div>

      </a>
    </Dropdown>
  );
};

export default ProfileDropdown;
