import React, {useEffect, useState} from 'react';
import {message,Select,Tooltip, Modal, Spin, Upload, Input} from 'antd';
import {BsPlusLg} from 'react-icons/bs';
import '../../AdminCourse/CourseMaterial/CourseMaterial.css';
import {DeleteOutlined, EditOutlined, InboxOutlined, LoadingOutlined} from '@ant-design/icons';
import CourseServices from '../../../../services/courses.service';
import ProjectServices from '../../../../services/projects.service';
import {useSelector} from 'react-redux';
import ReactPlayer from 'react-player';

const {TextArea} = Input

const {Option} = Select;
const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);

function ProjectMaterial({projectDetails,userId}) {
  const [isAddModal, setIsAddModal] = useState (false);
  const [loading, setLoading] = useState (false);
  const [materialTitle, setMaterialTitle] = useState ('');
  const [materialTextData, setMaterialTextData] = useState ('');
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState (false);
  const [videoId,setVideoId] = useState("");
  const [videoType,setVideoType] = useState("custom video");
  const [allProjectMaterial,setAllProjectMaterial] = useState([]);
  const {user:currentUser} = useSelector((state)=>state.auth);
  const [currentEditProject,setCurrentEditProject] = useState("");
  const [isEditingProject,setIsEditingProject] = useState(false);
  const [updatedFileList,setUpdatedFileList] = useState([]);
  const [updatedFileUrl,setUpdatedFileUrl] = useState("");
  const [updatedMaterialTitle,setUpdatedMaterialTitle] = useState("");
  const [updatedMaterialTextData,setUpdatedMaterialTextData] = useState("");
  const [updatedVideoType,setUpdatedVideoType] = useState("");
  const [updatedVideoId,setUpdatedVideoId] = useState("");
  


  useEffect(()=>{
    let isMounted = true;
    const getAllProjectMaterialByProjectId = async ()=>{
      try{
        const response = await ProjectServices.getProjectMaterialByProjectId(projectDetails.id)
        console.log(response.data);
        setAllProjectMaterial(response.data);
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'Something went wrong!');
      }
    }

    if(isMounted){
      getAllProjectMaterialByProjectId();
    }

    return ()=>{
      isMounted = false
    }

  },[projectDetails]);

  const getAllProjectMaterialByProjectId = async ()=>{
    try{
      const response = await ProjectServices.getProjectMaterialByProjectId(projectDetails.id)
      console.log(response.data);
      setAllProjectMaterial(response.data);
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : 'Something went wrong!');
    }
  }

  const handleAddCourseMaterial = () => {
    // console.log ('Add Project Modal');
    setIsAddModal (true);
  };

  const handleConfirmAddMaterial = async () => {
      setLoading(true);
      try{
          let data = {
              title:materialTitle,
              textData:materialTextData,
              type:videoType,
              projectId:projectDetails.id,
              fileLink:fileUrl,
              userId:userId
          }
          if(videoType==="youtube"){
              let yUrl = `https://www.youtube.com/embed/${videoId}`
              data.fileLink = yUrl;
          }
        const response = await ProjectServices.createProjectMaterial(data);
        console.log(response.data);
        message.success(`${materialTitle} Added in Project Material Successfully!`);
        setLoading(false);
        setIsAddModal(false);
        setMaterialTitle("");
        setMaterialTextData("");
        setVideoId("");
        getAllProjectMaterialByProjectId();
      }catch(err){
          console.log(err);
          setLoading(false);
          message.error(err.message ? err.message : 'something went wrong!');
      }
  };

  const handleCancelAddModal = () => {
    setIsAddModal (false);
  };

  function beforeUpload (file) {
    if (file) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    return false;
  }

  const handleUpload = async () => {
    setUploading (true);
    const files = fileList;

    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    let changedName = finalDate.toString () + '__' + files[0].name;
// let changedName = files[0].name;
    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      message.success ('File Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };


  function beforeEditUpload (file) {
    if (file) {
      setUpdatedFileList ([file]);
      setUploadBtnDisabled (false);
    }
    return false;
  }

  const handleUpdatedUpload = async () => {
    setUploading (true);
    const files = updatedFileList;

    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    let changedName = finalDate.toString () + '__' + files[0].name;
// let changedName = files[0].name;
    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setUpdatedFileUrl (response.data.url);
      message.success ('File Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const removePrevItemFromAws = key => {
    // console.log ('key to remove : ', key);
    CourseServices.removeItemFromBucket ({userId: currentUser.id, key: key})
      .then (result => {
        console.log (result);
        message.info ('Deleted Previous Image from Bucket!');
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };



  const handleProjectDelete = async (project)=>{
    try {
      const response = await ProjectServices.deleteProjectMaterialById (
        project.id,
        currentUser.id
      );
      console.log(response.data);
      message.success ('Deleted Project Material Successfully!');
      let removedItemKey = '';
      // console.log("Project to delete :  ",project)
      if (project.fileLink && project.type ==="custom video") {
        // console.log (project.fileLink);
        let fileToDeleteUrl = project.fileLink.split ('/');
        // console.log (fileToDeleteUrl);
        removedItemKey = fileToDeleteUrl[fileToDeleteUrl.length - 1];
        // console.log("removed item key : ",removedItemKey);
      }

      if(removedItemKey){
        removePrevItemFromAws(removedItemKey);
      }
      
      getAllProjectMaterialByProjectId();
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }

  }

  const handleProjectEdit = (project)=>{
    setCurrentEditProject(project);
    setUpdatedMaterialTextData(project.description);
    setUpdatedMaterialTitle(project.title);
    setUpdatedVideoType(project.type);
    setUpdatedFileUrl(project.fileLink)
    setIsEditingProject(true);
  }

  const handleConfirmEditModal = async ()=>{
    setLoading(true);
    try{
        let data = {
            id:currentEditProject.id,
            title:updatedMaterialTitle,
            textData:updatedMaterialTextData,
            type:updatedVideoType,
            projectId:projectDetails.id,
            fileLink:updatedFileUrl,
            userId:userId
        }
        if(updatedVideoType==="youtube" && updatedVideoId){
            let yUrl = `https://www.youtube.com/embed/${updatedVideoId}`
            data.fileLink = yUrl;
        }
        console.log("final updated data : ",data);
      const response = await ProjectServices.updateProjectMaterialById(data);
      console.log(response.data);
      message.success(`${updatedMaterialTitle} Updated in Project Material Successfully!`);
      setLoading(false);
      setIsEditingProject(false);
      setUpdatedMaterialTitle("");
      setUpdatedMaterialTextData("");
      setUpdatedVideoId("");
      getAllProjectMaterialByProjectId();
      let removedItemKey = "";
      if (currentEditProject.fileLink && currentEditProject.type ==="custom video") {
        // console.log (project.fileLink);
        let fileToDeleteUrl = currentEditProject.fileLink.split ('/');
        // console.log (fileToDeleteUrl);
        removedItemKey = fileToDeleteUrl[fileToDeleteUrl.length - 1];
        // console.log("removed item key : ",removedItemKey);
      }

      if(removedItemKey){
        removePrevItemFromAws(removedItemKey);
      }

      setCurrentEditProject("");
    }catch(err){
        console.log(err);
        setLoading(false);
        message.error(err.message ? err.message : 'something went wrong!');
    }
  }

  const handleCancelEditModal = ()=>{
    setIsEditingProject(false);
  }
  return (
    <div className="addCourseMaterial">
      <div className="addAreaWrapper">
        <div className="addArea1">
          <h1>Add Material</h1>
          <div className="addButton" onClick={handleAddCourseMaterial}>
            <BsPlusLg className="plusIcon" onClick={handleAddCourseMaterial} />
          </div>
        </div>
      </div>
      <div className='allProjectMaterial'>
          {allProjectMaterial.map((item)=>{
            return(
              <div className='projectMaterialCard' key={item.id}>
                
                <ReactPlayer
                  url={item.fileLink}
                  width={'100%'}
                  playing={false}
                  controls={true}
                  />
                
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <div className='project-control-area'>
                 <Tooltip title="Delete?" >
                 <DeleteOutlined  style={{marginRight:"10px"}} className='icon' onClick={()=>handleProjectDelete(item)}/>
                 </Tooltip>
                 <Tooltip title="Edit?">
                 <EditOutlined  className='icon' onClick={()=>handleProjectEdit(item)}/>
                 </Tooltip>
                 
                </div>
              </div>
            )
          })}
      </div>


      <Modal
        title="Add New Project Material"
        visible={isAddModal}
        confirmLoading={loading}
        okText={'Create'}
        onOk={handleConfirmAddMaterial}
        onCancel={handleCancelAddModal}
      >

        <div className="materialForm">

          <div>
            <input
              type="text"
              placeholder="Enter Video Title"
              value={materialTitle}
              onChange={e => {
                setMaterialTitle (e.target.value);
              }}
            />
          </div>

          <TextArea
            showCount
            maxLength={255}
            width="100%"
            value={materialTextData}
            onChange={e => {
              setMaterialTextData (e.target.value);
            }}
            placeholder="Enter Any Description"
          />

          <div style={{marginTop:"10px",marginBottom:"10px"}}>
         

            <Select style={{width:'100%'}} placeholder="Select Video Type" value={videoType} onChange={(value)=>setVideoType(value)}>
                <Option value="custom video">Custom Video</Option>
                <Option value="youtube">Youtube Video</Option>
            </Select>
           
            <br />
          </div>


          {videoType==="youtube" ? 
          <div style={{marginTop:"10px",marginBottom:"10px"}}>
            <input
              type="text"
              placeholder="Enter Youtube Video Id"
              value={videoId}
              onChange={e => {
                setVideoId (e.target.value);
              }}
            />
            
          </div>
          :

          <>
          <Upload.Dragger
            name="files"
            beforeUpload={beforeUpload}
            onRemove={() => {
              setFileList ([]);
            }}
            className="uploader"
            fileList={fileList}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{color: 'var(--green)'}} />
            </p>
            <p className="ant-upload-text">
              Click or drag Video to this area to upload
            </p>
            <p className="ant-upload-hint">
              You can upload video files such as .mp4,.mkv, .wmv or .avi
            </p>
          </Upload.Dragger>
          <button
            type="button"
            disabled={uploading || fileList.length === 0 || uploadBtnDisabled}
            className="primaryBtn"
            style={{marginTop: '5px', marginBottom: '5px'}}
            onClick={handleUpload}
          >
            {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
          </button>
          </>

          }

         
        </div>

      </Modal>


      <Modal
        title={`Update ${currentEditProject.title}`}
        visible={isEditingProject}
        confirmLoading={loading}
        okText={'Update'}
        onOk={handleConfirmEditModal}
        onCancel={handleCancelEditModal}
      >

        <div className="materialForm">

          <div>
            <Input
              type="text"
              placeholder="Enter Updated Video Title"
              value={updatedMaterialTitle}
              onChange={e => {
                setUpdatedMaterialTitle (e.target.value);
              }}
            />
          </div>

          <TextArea
            showCount
            maxLength={255}
            width="100%"
            value={updatedMaterialTextData}
            onChange={e => {
              setUpdatedMaterialTextData (e.target.value);
            }}
            placeholder="Enter Updated Description"
          />

        <div>
          <ReactPlayer
            url={updatedFileUrl}
            width={'100%'}
            height={"10rem"}
            playing={false}
            controls={true}
            />   
          </div>

          <br />

          <div style={{marginTop:"10px",marginBottom:"10px"}}>
         

            <Select style={{width:'100%'}} placeholder="Select Video Type" value={updatedVideoType} onChange={(value)=>setUpdatedVideoType(value)}>
                <Option value="custom video">Custom Video</Option>
                <Option value="youtube">Youtube Video</Option>
            </Select>
           
            <br />
          </div>
          
        


          {updatedVideoType==="youtube" ? 
          <div style={{marginTop:"10px",marginBottom:"10px"}}>
            <Input
              type="text"
              placeholder="Enter Updated Youtube Video Id"
              value={updatedVideoId}
              onChange={e => {
                setUpdatedVideoId (e.target.value);
              }}
            />
            
          </div>
          :

          <>
          
          <Upload.Dragger
            name="files"
            beforeUpload={beforeEditUpload}
            onRemove={() => {
              setUpdatedFileList ([]);
            }}
            className="uploader"
            fileList={updatedFileList}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{color: 'var(--green)'}} />
            </p>
            <p className="ant-upload-text">
              Click or drag Video to this area to upload
            </p>
            <p className="ant-upload-hint">
              You can upload video files such as .mp4,.mkv, .wmv or .avi
            </p>
          </Upload.Dragger>
          <button
            type="button"
            disabled={uploading || updatedFileList.length === 0 || uploadBtnDisabled}
            className="primaryBtn"
            style={{marginTop: '5px', marginBottom: '5px'}}
            onClick={handleUpdatedUpload}
          >
            {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
          </button>
          </>

          }

         
        </div>

      </Modal>
      {/* <iframe
        id="player"
        type="text/html"
        title="youtube"
        style={{width: '100%', height: '500px'}}
        src={`https://www.youtube.com/embed/lT4uJI6TXAg`}
        frameborder="0"
      /> */}
    </div>
  );
}

export default ProjectMaterial;
