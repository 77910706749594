import React, {useEffect, useState} from 'react';
import {
  HomeOutlined,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Spin,
  Tabs,
  Upload,
} from 'antd';
import {BiBookReader} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import CourseServices from '../../../services/courses.service';
import {useSelector} from 'react-redux';
import './AdminCourse.css';
import CourseMaterial from './CourseMaterial/CourseMaterial';
import StudentServices from '../../../services/students.service';
import ProjectServices from '../../../services/projects.service';
import ModuleQuiz from './ModuleQuiz/ModuleQuiz';

const {TabPane} = Tabs;

const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);
function AdminCourse () {
  const {id} = useParams ();
  const [courseDetails, setCourseDetails] = useState (null);
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const [loading, setLoading] = useState (false);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState (false);
  const [title, setTitle] = useState ('');
  const [description, setDescription] = useState ('');
  const [learningPointsArr, setLearningPointsArr] = useState ([]);
  const [price, setPrice] = useState (0);
  const [currency,setCurrency] = useState("");
  const [enrolledStudents, setEnrolledStudents] = useState ([]);

  const {user: currentUser} = useSelector (state => state.auth);
  const [isCategoryModal, setIsCategoryModal] = useState (false);
  const [isCategoryAdding, setIsCategoryAdding] = useState (false);
  const [allProjectCategory, setAllProjectCategory] = useState ([]);
  const [newCategory, setNewCategory] = useState ('');
  const [finalCategory, setFinalCategory] = useState ('');
  const [promoFileList, setPromoFileList] = useState ([]);
  const [uploadingPromo, setUploadingPromo] = useState (false);
  const [courseFormat,setCourseFormat] = useState('');
  const [promoFileUrl, setPromoFileUrl] = useState ('');
  const [uploadPromoBtnDisabled, setUploadPromoBtnDisabled] = useState (false);
  const [usdPrices, setAllUsdPrices] = useState ([]);
  const [inrPrices, setAllInrPrices] = useState ([]);
 


  const getAllPrices = async () => {
    try {
      const response = await CourseServices.getAllPrice ({userId:currentUser.id});
      // console.log (response.data);
      let usdPrice = response.data?.filter((item)=>item?.currency==="usd");
      let inrPrice = response.data?.filter((item)=>item?.currency==="inr");
      setAllUsdPrices(usdPrice);
      setAllInrPrices(inrPrice);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to fetch prices!');
    }
  };
  useEffect (() => {
    getAllPrices ();
  }, []);

  async function getCourseData () {
    try {
      const response = await CourseServices.getCourseById (id);
      // console.log("Course details : ",response.data);
      setCourseDetails (response.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch course details!'
      );
    }
  }

  useEffect (
    () => {
      async function getCourseDetails () {
        try {
          const response = await CourseServices.getCourseById (id);
          // console.log("Course details : ",response.data);
          setCourseDetails (response.data);
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch course details!'
          );
        }
      }
      getCourseDetails ();
    },
    [id]
  );

  useEffect (
    () => {
      if (courseDetails) {
        const getEnrolledStudentsList = async () => {
          try {
            const response = await StudentServices.getEnrolledStudents (
              courseDetails.id,
              currentUser.id
            );
            // console.log (response.data);
            if (response) {
              setEnrolledStudents (response.data);
            }
          } catch (err) {
            console.log (err);
            message.error (
              err.message ? err.message : 'Unable to fetch enrolled students!'
            );
          }
        };

        getEnrolledStudentsList ();
      }
    },
    [courseDetails, currentUser]
  );

  useEffect (
    () => {
      if (courseDetails) {
        setDescription (courseDetails.description);
        setTitle (courseDetails.title);
        setFinalCategory (courseDetails.category ? courseDetails.category : '');
        setCourseFormat(courseDetails.courseFormat ? courseDetails.courseFormat : '');
        setCurrency(courseDetails?.currency);
        if (courseDetails.price && courseDetails.price > 0) {
          setPrice (courseDetails.price);
        }
        if (courseDetails.learningPoints) {
          setLearningPointsArr (courseDetails.learningPoints);
        }
      }
    },
    [courseDetails]
  );

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          // console.log (response.data);
          setAllProjectCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const onFinish = async () => {
    setLoading (true);
    let newLearningPoints = [];
    learningPointsArr.forEach (item => {
      if (item.length > 1) {
        newLearningPoints.push (item);
      }
    });

    let data = {
      id: courseDetails.id,
      title: title,
      price: price,
      learningPoints: newLearningPoints,
      description: description,
      imageUrl: fileUrl ? fileUrl : courseDetails.imageUrl,
      userId: currentUser.id,
      category: finalCategory,
      currency:currency,
      promoVideo: promoFileUrl ? promoFileUrl : courseDetails.promoVideo,
      courseFormat:courseFormat
    };
    // console.log(data);
    try {
      const response = await CourseServices.updateCourseById (data);
      // console.log(response.data);
      if (response.status === 200) {
        console.log ('success!');
      }
      message.success ('Updated Course Successfully!');
      setLoading (false);
      getCourseData ();
      // onReset();
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  function beforeUpload (file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (file) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    if (!isJpgOrPng) {
      message.error ('You can only upload JPG/PNG file!');
      setUploadBtnDisabled (true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error ('Image must smaller than 2MB!');
      setUploadBtnDisabled (true);
    }

    return false;
  }

  const removePrevItemFromAws = key => {
    console.log ('key to remove : ', key);
    CourseServices.removeItemFromBucket ({userId: currentUser.id, key: key})
      .then (result => {
        console.log (result);
        message.info ('Deleted Previous Image from Bucket!');
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleUpload = async () => {
    setUploading (true);
    const files = fileList;
    let removedItemKey = '';
    if (courseDetails.imageUrl) {
      console.log (courseDetails.imageUrl);
      let imageUrlArr = courseDetails.imageUrl.split ('/');
      console.log (imageUrlArr);
      removedItemKey = imageUrlArr[imageUrlArr.length - 1];
    }

    let changedName = '';
    // console.log("File List : ",files);
    // if (courseDetails.imageUrl) {
    //   let changedArr = courseDetails.imageUrl.split ('/');
    //   changedName = changedArr[changedArr.length - 1];
    // } else {
    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    changedName = finalDate.toString () + '__' + files[0].name;
    // }
    // console.log("Changed Arr : ",changedArr);
    // let changedName="8-2-2022_at_13:13:5__nodejs_banner.jpg";

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      message.success ('Image Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }

    if (removedItemKey) {
      removePrevItemFromAws (removedItemKey);
    }
  };

  const handleUpdatedPoint = (value, index) => {
    let prevLearningPoints = learningPointsArr;
    prevLearningPoints[index] = value;
    // console.log("Updated Value : ",prevLearningPoints[index]);
    setLearningPointsArr (prevLearningPoints);
  };

  const add = () => {
    setLearningPointsArr (prev => {
      return [...prev, ' '];
    });
  };

  const getAllProjectCategory = () => {
    ProjectServices.getAllProjectCategory ()
      .then (response => {
        console.log (response.data);
        setAllProjectCategory (response.data);
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleCategorySubmit = async () => {
    setIsCategoryAdding (true);
    try {
      const response = await ProjectServices.createNewProjectCategory (
        newCategory,
        currentUser.id
      );
      //   console.log (response);
      if (response) {
        message.success (`Added ${newCategory} Successfully!`);
        setNewCategory ('');
        getAllProjectCategory ();
        setIsCategoryAdding (false);
        setIsCategoryModal (false);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setIsCategoryAdding (false);
    }
  };

  function beforeVideoUpload (file) {
    if (file) {
      setPromoFileList ([file]);
      setUploadPromoBtnDisabled (false);
    }

    const isLt4M = file.size / 1024 / 1024 <= 4;
    if (!isLt4M) {
      message.error ('Video must smaller than 4MB!');
      setUploadPromoBtnDisabled (true);
    }

    return false;
  }

  const handleVideoUpload = async () => {
    setUploadingPromo (true);
    const files = promoFileList;
    // console.log("File List : ",files);
    let removedItemKey = '';
    if (courseDetails.promoVideo) {
      let imageVideoArr = courseDetails.promoVideo.split ('/');
      removedItemKey = [imageVideoArr.length - 1];
    }

    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    let changedName = finalDate.toString () + '__' + files[0].name;
    // let changedName="8-2-2022_at_13:13:5__nodejs_banner.jpg";

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploadingPromo (false);
      // console.log("Data : ",response.data);
      setPromoFileUrl (response.data.url);
      message.success ('Promo Video Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploadingPromo (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }

    if (removedItemKey) {
      removePrevItemFromAws (removedItemKey);
    }
  };

  // useEffect(()=>{
  //   console.log("Learning Points Arr : ",learningPointsArr);
  // },[learningPointsArr]);

  // useEffect(()=>{
  //   console.log("price : ",price);
  // },[price])

  return (
    <div className="adminCourse">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumb className="breadCrumb">
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard">
              <HomeOutlined style={{marginRight: '5px'}} />
              <span>Dashboard</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard/manage-courses">
              <BiBookReader
                style={{
                  marginRight: '5px',
                  fontSize: '1rem',
                  marginBottom: '-2px',
                }}
              />
              <span>All Courses</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {courseDetails ? courseDetails.title : id}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div>
          <Button
            type="primary"
            className="btn"
            onClick={() => setIsCategoryModal (true)}
          >
            <PlusOutlined /> Add Category
          </Button>
        </div>

      </div>
      <div className="editCourse">
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="Basic Details" key="1">
            <div className="createCourseContainer">
              <h1>Update The Course Details</h1>
              <form>
                <div className="form-control">
                  <label>Title</label>
                  <input
                    type="text"
                    onChange={e => setTitle (e.target.value)}
                    value={title}
                    placeholder="e.g. Python complete Bootcamp"
                  />
                </div>

                <div className="form-control">
                  <label>Category: </label>
                  <br />
                  <Select
                    showSearch
                    mode='multiple'
                    allowClear
                    style={{width: '100%'}}
                    placeholder="e.g. Web Development"
                    value={finalCategory}
                    onChange={value => setFinalCategory (value)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes (input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase ()
                        .localeCompare (optionB.children.toLowerCase ())}
                  >
                    {allProjectCategory.map (item => {
                      return (
                        <Select.Option key={item.id} value={item.title}>
                          {item.title}
                        </Select.Option>
                      );
                    })}
                  </Select>

                </div>

                <div className="form-control">
                  <label>Course Format: </label>
                  <br />
                  <Select
                    
                    style={{width: '100%'}}
                    placeholder="e.g. Short"
                    value={courseFormat}
                    onChange={value => setCourseFormat (value)}
                  >
                      <Select.Option  value={"Short"}>Short</Select.Option>
                      <Select.Option  value={"Long"}>Long</Select.Option>
                  </Select>

                </div>


                <div style={{display:'flex',alignItems:'center'}}>

        <div
         className='form-control'
         style={{width:'80%'}}

         >
          <label>Price</label>
         <Select
            showSearch
            placeholder="e.g. $19.99"
            optionFilterProp="children"
            style={{width:'100%'}}
            onChange={(value)=>setPrice(value)}
            value={price}
            filterOption={(input, option) => option.children.includes (input)}
          
           >
               {currency==="usd" ? 
               usdPrices.map((item)=> <Select.Option value={item.value}>${item.value} {item?.tier}</Select.Option>) 
               :
               inrPrices.map((item)=> <Select.Option value={item.value}>&#8377; {item.value} {item?.tier}</Select.Option>) 
             }
           </Select>
 </div>
          <div
          className='form-control'
          >
           
           <label>Currency: </label>
          <Select
           
           placeholder="e.g. USD"
          style={{margin:'10px',width:'100%'}}
           value={currency}
           
           onChange={(value)=>setCurrency(value)}
         
          >
              <Select.Option  value={"usd"}>USD</Select.Option>
              <Select.Option  value={"inr"}>INR</Select.Option>
          </Select>
          </div>

</div>


              
                <div className="form-control">
                  <label>Description</label>
                  <textarea
                    onChange={e => setDescription (e.target.value)}
                    placeholder="Enter any description for the course"
                    value={description}
                  />
                </div>

                <div>
                  {learningPointsArr.map ((point, index) => {
                    return (
                      point &&
                      <Input
                        defaultValue={point}
                        key={index}
                        style={{marginTop: '10px'}}
                        onChange={e =>
                          handleUpdatedPoint (e.target.value, index)}
                      />
                    );
                  })}
                </div>
                <div>

                  <Button
                    type="dashed"
                    onClick={() => add ()}
                    style={{
                      width: '100%',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                    icon={<PlusOutlined />}
                    className="left"
                  >
                    Add Learning point
                  </Button>
                </div>
                <br />
                <br />
                <br />

                <Upload.Dragger
                  name="files"
                  beforeUpload={beforeUpload}
                  onRemove={() => {
                    setFileList ([]);
                  }}
                  fileList={fileList}
                >
                  <p className="ant-upload-drag-icon">
                    {courseDetails && courseDetails.imageUrl
                      ? <img
                          src={courseDetails.imageUrl}
                          className="uploadDefaultImg"
                          alt="img"
                        />
                      : <InboxOutlined
                          style={{color: 'var(--green)', fontSize: '2rem'}}
                        />}
                  </p>
                  <p className="ant-upload-text">
                    Click or drag image to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Upload a Single Image File with maximum 2 MB of size.
                  </p>
                </Upload.Dragger>

                <button
                  type="button"
                  disabled={
                    uploading || fileList.length === 0 || uploadBtnDisabled
                  }
                  className="primaryBtn"
                  style={{marginTop: '5px', marginBottom: '5px'}}
                  onClick={handleUpload}
                >
                  {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
                </button>

                <br />
                <br />

                <Upload.Dragger
                  name="promo"
                  beforeUpload={beforeVideoUpload}
                  onRemove={() => {
                    setPromoFileList ([]);
                  }}
                  fileList={promoFileList}
                >
                  {courseDetails && courseDetails.promoVideo
                    ? <video className="uploadDefaultVideo" autoplay>
                        <source src={courseDetails.promoVideo} />
                      </video>
                    : <InboxOutlined
                        style={{color: 'var(--green)', fontSize: '2rem'}}
                      />}
                  <p className="ant-upload-text">
                    Click or drag video to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Upload a Single Video File with maximum 4 MB of size.
                  </p>
                </Upload.Dragger>
                <button
                  type="button"
                  disabled={
                    uploadingPromo ||
                      promoFileList.length === 0 ||
                      uploadPromoBtnDisabled
                  }
                  className="primaryBtn"
                  style={{marginTop: '5px', marginBottom: '5px'}}
                  onClick={handleVideoUpload}
                >
                  {uploadingPromo ? <Spin indicator={spinIcon} /> : 'Upload'}
                </button>

                <div className="enrolled__students">
                  <label>Enrolled Students :</label><br />
                  {enrolledStudents.map (item => {
                    return <span className="badge">{item.username}</span>;
                  })}
                </div>

                <Button
                  type="primary"
                  loading={loading}
                  onClick={onFinish}
                  className="btn"
                >
                  Update
                </Button>

              </form>

              <Modal
                title="Add New Category"
                visible={isCategoryModal}
                confirmLoading={isCategoryAdding}
                okText={'Add'}
                onOk={handleCategorySubmit}
                onCancel={() => setIsCategoryModal (false)}
              >

                <div className="materialForm">
                  <label>
                    <b>Category Name</b>
                  </label>

                  <Input
                    placeholder="e.g. Web Development"
                    type="text"
                    onChange={e => {
                      setNewCategory (e.target.value);
                    }}
                    value={newCategory}
                  />
                </div>
              </Modal>
            </div>
          </TabPane>
          <TabPane tab="Course Material" key="2" disabled={!courseDetails}>
            <CourseMaterial courseDetails={courseDetails} />
          </TabPane>
          <TabPane tab="Quizzes" key="3" disabled={!courseDetails}>
            <ModuleQuiz courseDetails={courseDetails} />
          </TabPane>
        </Tabs>

      </div>
    </div>
  );
}

export default AdminCourse;
