import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    INVALID_PASSWORD,
    RESET_INVALID_PASSWORD,
  } from "../actions/types";
  
  const user = JSON.parse(localStorage.getItem("user"));
  
  const initialState = user
    ? { isLoggedIn: true, user,invalidPassword:0,tokenExpired:false}
    : { isLoggedIn: false, user: null ,invalidPassword:0,tokenExpired:false };
  
  export default function auth(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
          tokenExpired:false
        };
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          tokenExpired:false
          
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          invalidPassword:0,
          tokenExpired:false
        };
      case INVALID_PASSWORD:
        return{
        ...state,
        invalidPassword:state.invalidPassword + 1
      }
      case RESET_INVALID_PASSWORD:
        return{
          ...state,
          invalidPassword:0
        }
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          tokenExpired:false
        };
      case LOGOUT:
        return {
          ...state,
          invalidPassword:0,
          isLoggedIn: false,
          user: null,
          tokenExpired:false
        };
      
      default:
        return state;
    }
  }
  