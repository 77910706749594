import {EyeOutlined, MoreOutlined, SearchOutlined} from '@ant-design/icons';
import {Dropdown, Input, Menu, message, Table} from 'antd';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import StudentServices from '../../../services/students.service';

function ManageStudents () {
  const [students, setStudents] = useState ([]);
  const [filteredStudents, setFilteredStudents] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      let isMount = true;
      let getAllEnrolledStudents = async () => {
        try {
          const response = await StudentServices.getAllEnrolledStudents (
            currentUser.id
          );
          let filteredStudents = [];
          // console.log (response.data);
          if (response.data.length > 0) {
            filteredStudents = response.data.filter (
              (value, index, self) =>
                index ===
                self.findIndex (
                  t =>
                    t.username === value.username && t.userId === value.userId
                )
            );
          }

          // console.log ('Filtered Result : ', filteredStudents);
          if (isMount) {
            setStudents (filteredStudents);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'unable to fetch students record!'
          );
        }
      };

      if (currentUser) {
        getAllEnrolledStudents ();
      }

      return () => {
        isMount = false;
      };
    },
    [currentUser]
  );

  const handleSearch = e => {
    // console.log(e);
    let query = e.target.value;
    // console.log("Query : ",query);
    let filteredResult = [];
    filteredResult = students.filter (item => {
      return (
        item.username &&
        item.username.toLowerCase ().match (query.toLowerCase ())
      );
    });
    // console.log(filteredResult);
    setFilteredStudents (filteredResult);
  };

  const columns = [
    {
      title: 'User Id',
      dataIndex: 'userId',
      key: 'userId',
      render: id => {
        return <b>#{id}</b>;
      },
    },

    {
      title: 'Username',
      key: 'username',
      render: record => {
        return (
          <Link to={`/admin/dashboard/view-student-details/${record.userId}`}>
            <b>{record.username}</b>
          </Link>
        );
      },
    },

    {
      title: 'Action',
      key: 'action',
      render: record => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <EyeOutlined />
                  <Link
                    to={`/admin/dashboard/view-student-details/${record.userId}`}
                  />
                  View Details
                </Menu.Item>

              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{
                  cursor: 'pointer',
                  color: 'black',
                  transform: '90deg',
                  fontSize: '1.5rem',
                }}
              />
            </p>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <div>
      <div className="d-flex justify-between">
        <h1 className="t__heading">All Students </h1>
        <div className="searchArea">
          <Input
            className="searchInput"
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="Search for students"
            allowClear
            onChange={handleSearch}
          />
        </div>
      </div>
      <Table
        dataSource={filteredStudents.length > 0 ? filteredStudents : students}
        columns={columns}
      />
    </div>
  );
}

export default ManageStudents;
