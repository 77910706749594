import React,{useEffect,useState} from 'react'
import './Navbar.css'
import {TiThMenu,TiTimes} from 'react-icons/ti';
import { FiUserPlus} from 'react-icons/fi';
import {BiLogIn,BiHomeCircle,BiBookReader} from 'react-icons/bi';
import logo from '../../assets/codersartsLogo.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from '../../actions/auth';
import ProfileDropdown from './ProfileDropdown';
import { history } from '../../helpers/history';
import { useDispatch } from 'react-redux';
import avatar  from '../../assets/login.png';
import {MdPeopleOutline} from 'react-icons/md';
import { AiOutlineCode } from 'react-icons/ai';
import {GrDocumentStore} from 'react-icons/gr';

export default function Navbar() {
    const [toggleMenu,setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const {user:currentUser} = useSelector((state)=>state.auth);
    
   // console.log(currentUser);
    const dispatch = useDispatch();
    const toggleNav = ()=>{
        setToggleMenu(!toggleMenu);
    }

    useEffect(() => {

        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
      }, [])

      const logOut = () => {
        dispatch(logout());
        history.push('/login');
      }

  return (
    <nav>
        <div className={`logo ${toggleMenu ? 'inToggle':null}`}>
            <img src={logo} alt="logo" />
            <div className='logoTitles'>
                <div className='logoText'>Codersarts</div>
                <small>Learning</small>
            </div>
        </div>
        {(toggleMenu || screenWidth > 500) &&
        <ul className="list">
          <li className="items"><Link to="/" className='navLink'><BiHomeCircle className='navIcon' /><span>Home</span></Link></li>
          <li className="items"><Link to="/courses" className='navLink'><BiBookReader className='navIcon'/><span>Courses</span></Link></li>

         <li className="items"><Link to="/all-projects" className='navLink'><GrDocumentStore className='navIcon'/><span>Projects</span></Link></li>
         {/* <li className="items"><Link to="/all-programs" className='navLink'><FiTarget className='navIcon'/><span>Programs</span></Link></li> */}
          <li className="items"><Link to="/code-editor" className='navLink'><AiOutlineCode className='navIcon'/><span>Editor</span></Link></li>
          <li className="items"><a href="#about" className='navLink'><MdPeopleOutline className='navIcon' /><span>About</span></a></li>
          {currentUser ? 
            <>
            <div className="nav-item profile-items">
              <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} className="profile-avatar" alt="img" />
            </div>
            <ProfileDropdown user={currentUser} name={currentUser.username} logout={logOut} />
           
          </>
          :
          <li className='items loginArea'>
           <Link to="/login"><BiLogIn className='loginIcon'/><span>Login</span></Link>
            <Link to={'/register'}><FiUserPlus className='signUpIcon' /><span>Sign Up</span></Link>
          </li>
          }
         

        </ul>
        }
       
       
      
      {toggleMenu ? <TiTimes className="btn" onClick={toggleNav}/> : <TiThMenu className="btn" onClick={toggleNav}/>}
    </nav>
  )
}
