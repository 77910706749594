import {message, Progress} from 'antd';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import StudentServices from '../../../services/students.service';
import './UserDashboardHome.css';
import {Link} from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons';
import styles from '../MyProjects/MyProject.module.css';

function UserDashboardHome () {
  const [enrolledCourses, setEnrolledCourses] = useState ([]);
  const [filteredCourses,setFilteredCourses] = useState([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      if (currentUser) {
        const getEnrolledCourses = async () => {
          try {
            const response = await StudentServices.getAllEnrolledCoursesForUser ({
              userId: currentUser.id,
            });
            console.log(response.data);
            if (response) {
                let data = []
             
            if(response.data){
                let finalDate = "N/A"
                response.data.forEach((item)=>{
                    if(item.courseDetails.createdAt){
                        const date = new Date(item.courseDetails.createdAt);
                        let dd = date.getDate();
                        let mm = date.getMonth() + 1;
                        let yyyy = date.getFullYear();
                        let hh = date.getHours();
                        let minutes = date.getMinutes();
                        let ss = date.getSeconds();
                       finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                    // console.log(finalDate);
                    }
                    let updatedData = {
                        ...item,
                        progress:item.progress.progress,
                        joinedAt:finalDate
                    }

                    data.push(updatedData);
                   
                })
            }
            console.log(data);
              setEnrolledCourses (data);
            }
          } catch (err) {
            console.log (err);
            message.error (
              err.message ? err.message : 'Unable to fetch Enrolled Courses!'
            );
          }
        };

        getEnrolledCourses ();
      }
    },
    [currentUser]
  );

  const handleSearchCourse = query => {
    const filteredData = enrolledCourses.filter (item => {
      return (
        item.courseDetails && item.courseDetails?.title.toLowerCase ().match(query.toLowerCase ())
      );
    });
    // console.log (filteredData);
    setFilteredCourses(filteredData);
  };

  return (
    <div className="user__dashboard__home">
     <div className="user__dashboard__search">
        <SearchOutlined className='user__dashboard__searchIcon' />
        <input
          type="text"
          placeholder='Search enrolled courses'
          onChange={e => {
            handleSearchCourse (e.target.value);
          }}
        />
      </div>
      <h1>My Courses</h1>
     

      <div className="user__dashboard__courses__list">
          {filteredCourses.length > 0 ?
          filteredCourses.map((item,index)=>{
              return(
                  <div className='user__dashboard__course__card' key={index}>
                    <div className='user__dashboard__course__card__img'>
                        <img src={item?.courseDetails?.imageUrl} alt={item?.courseDetails?.title} />
                        <div className='user__dashboard__course__join'>
                                Joined At {item?.joinedAt}
                            </div>
                    </div>
                    <div className='user__dashboard__details__wrapper'>
                        <div className={styles.projectCard__header}>
                          <h1>{item?.courseDetails?.title}</h1>
                          
                        </div>
                        <div className='user__dashboard__course__details'>
                            <p>{item?.courseDetails?.description.slice(0,200)}...</p>
                           
                        </div>
                        <div className='user__dashboard__course__footer'>
                        <Progress size='large' className={styles.progress} percent={item?.progress ? item?.progress : 0} status="active" />
                           
                           
                                <Link 
                                to={`/courses/learn-course/${item?.courseDetails?.id}`}
                                className="primaryBtn"><b>{item?.progress >=100 ? "Revise" : "Continue"}</b></Link>
                           
                        </div>
                    </div>
                  </div>
              )
          }):
          enrolledCourses && enrolledCourses.map((item,index)=>{
              return(
                <div className='user__dashboard__course__card' key={index}>
                    <div className='user__dashboard__course__card__img'>
                        <img src={item?.courseDetails?.imageUrl} alt={item?.courseDetails?.title} />
                        <div className='user__dashboard__course__join'>
                                Joined At {item?.joinedAt}
                            </div>
                    </div>
                    <div className='user__dashboard__details__wrapper'>
                        <div className={styles.projectCard__header}>
                          <h1>{item?.courseDetails?.title}</h1>
                         
                        </div>
                        <div className='user__dashboard__course__details'>
                            <p>{item?.courseDetails?.description.slice(0,60)}...</p>
                           
                        </div>
                        <div className='user__dashboard__course__footer'>
                        <Progress size="large" className={styles.progress} percent={item?.progress ? item?.progress : 0} status="active" />
                           
                           
                                <Link 
                                to={`/courses/learn-course/${item?.courseDetails?.id}`}
                                className="primaryBtn"><b>{item?.progress >=100 ? "Revise" : "Continue"}</b></Link>
                           
                        </div>
                    </div>
                  </div>
              )
          })
          }
      </div>

    </div>
  );
}

export default UserDashboardHome;
