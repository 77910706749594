import React,{useEffect, useState} from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, HomeOutlined, InboxOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, InputNumber, message,Modal, Radio, Spin,Tabs,Tooltip, Upload } from 'antd';
import {useParams,Link,useLocation} from 'react-router-dom';
import {BiBookReader} from 'react-icons/bi';
import './CourseMaterialDetails.css';
import CourseServices from '../../../../services/courses.service';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import {MdFileDownload} from 'react-icons/md';
import ReactQuill,{Quill}  from 'react-quill';
import { HiUpload } from 'react-icons/hi';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';

// import draftToHtml from "draftjs-to-html";
// import { ContentState,Editor,convertFromHTML, EditorState } from 'draft-js';
// import ReactHtmlParser from 'react-html-parser';

// import play from '../../../../assets/play.png';

// const { confirm } = Modal;
const spinIcon = <LoadingOutlined style={{ color:'white',fontSize: 24 }} spin />;
const {TabPane} = Tabs;


const  modules  = {
    syntax: {highlight: text => hljs.highlightAuto (text).value},
    toolbar:{
        container: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
        ],
        handlers: {
            image: imageHandler
        }
    } ,
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
     }

};


function imageHandler() {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;
  
    tooltip.save = function () {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function () {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'Embed URL';
  }


function CourseMaterialDetails() {
    const {id} = useParams();
    const location = useLocation();
    const {courseId,courseName,section,materialId,isSingleMaterial} = location.state
    const [courseMaterialArr,setCourseMaterialArr] = useState([]); 
    const [currentUpdateMaterial,setCurrentUpdateMaterial] = useState("");
    const  [isUpdateModal,setIsUpdateModal] = useState(false);
    const [courseMaterialTitle,setCourseMaterialTitle] = useState("");
    const [courseMaterialDescription,setCourseMaterialDescription] = useState("");
    const [fileUrl,setFileUrl] = useState("");
    const [fileList,setFileList] = useState([]);
    const [uploading,setUploading] = useState(false);
    const [uploadBtnDisabled,setUploadBtnDisabled] = useState(false);
    const [loading,setLoading] = useState(false);
    const [updatedRichTextData,setUpdatedRichTextData] = useState("");
    const [isChanged,setIsChanged] = useState(false);
    const [uploadModal,setUploadModal] = useState(false);
    const [richFileList,setRichFileList] = useState([]);
    const [isMaterialLocked,setIsMaterialLocked] = useState(true);
    const [richFileUrl,setRichFileUrl] = useState("");
    const [orderId,setOrderId] = useState("");
    const {user:currentUser} = useSelector((state)=>state.auth);
    const [explanationDisableBtn,setExplanationFileDisabledBtn] = useState(true);
    const [explanationFile,setExplanationFile] = useState([]);
    const [explanationFileUrl,setExplanationFileUrl] = useState("");
    const [uploadingExpFile,setUploadingExpFile] = useState(false);
    const [isDownloadAllowed,setIsDownloadAllowed] = useState(false);

    useEffect(()=>{
        // console.log("updated rich text data : ",updatedRichTextData);
        if(updatedRichTextData){
            setIsChanged(true);
        }
    },[updatedRichTextData])
    const getCourseMaterial = async ()=>{
        // console.log("Calling material!");
        if(isSingleMaterial){
            try{
                const response = await CourseServices.getCourseMaterialById(materialId);
                // console.log("material : ",response.data);
            setCourseMaterialArr(response.data);
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Unable to fetch course section!');
            }
        }else{
            try{
                const response = await CourseServices.getCourseMaterialBySection(id);
                // console.log("Data : ",response);
    
                    setCourseMaterialArr(response.data);
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Unable to fetch course section!');
            }
        }
        
    }

    
    useEffect(()=>{
        let isMount = true;
        const getCourseMaterial = async ()=>{
            // console.log("Calling material!");
            if(isSingleMaterial){
                try{
                    const response = await CourseServices.getCourseMaterialById(materialId);
                    // console.log("Data : ",response);
                    console.log("material : ",response.data);
                    
                    if(isMount){
                        setCourseMaterialArr(response.data);
                    }
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : 'Unable to fetch course section!');
                }
            }else{
                try{
                    const response = await CourseServices.getCourseMaterialBySection(id);
                    // console.log("Data : ",response);
    
                    if(isMount){
                        setCourseMaterialArr(response.data);
                    }
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : 'Unable to fetch course section!');
                }
            }
        }

        getCourseMaterial()
    },[id,isSingleMaterial,materialId]);

    const handleMaterialPreview = (event)=>{
        //   console.log("Is Locked : ",event.target.value);
          setIsMaterialLocked(event.target.value);
      }

    const deleteCourseMaterial = async (item)=>{
        // console.log("deleting ",item);
        try{
            const response = await CourseServices.deleteCourseMaterialById({userId:currentUser.id,materialId:item.id})
            // console.log(response.data);
           if(response){
            message.success(`Deleted ${item.title} Successfully!`);
           }
           getCourseMaterial();

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to delete course material!');
        }
        
    }
    const showDeleteConfirm = (item)=>{
        Modal.confirm({
          title: 'Confirm Deletion',
          icon: <ExclamationCircleOutlined />,
          content: 'Are you sure you want to Delete this Course Material ? ',
          okText: 'Yes',
          cancelText: 'No',
          onOk:()=>{deleteCourseMaterial(item)}
        });
      }



    const handleMaterialEdit = (item)=>{
        setCurrentUpdateMaterial(item);
        setCourseMaterialTitle(item.title);
        setIsDownloadAllowed(item?.isDownloadAllowed);
        if(item.order){
            setOrderId(item.order)
        }
        setCourseMaterialDescription(item.textData);

        setIsUpdateModal(true);
    }

    function beforeNotebookUpload (file) {
        const isHtml = file.type === 'text/html';
        if (!isHtml) {
          message.error(`${file.name} is not a html file`);
        }
        if (file && isHtml) {
          setFileList ([file]);
          setUploadBtnDisabled (false);
        }
        return isHtml || false;
      }
    
      
    
    
      function beforeExplanationUpload(file){
        const isMp4 = file.type === 'video/mp4';
        if (!isMp4) {
          message.error(`${file.name} is not a mp4 file`);
        }
    
        if (file && isMp4) {
          setExplanationFile ([file]);
          setExplanationFileDisabledBtn(false);
        }
        return isMp4 || false;
      }
    
      const handleExplanationUpload = async () => {
        setUploadingExpFile (true);
        const files = explanationFile;
        console.log(files);
        
        try {
        let date = new Date ();
        let dd = date.getDate ();
        let mm = date.getMonth () + 1;
        let yyyy = date.getFullYear ();
    
        let finalDate = currentUpdateMaterial.title + '__' +
          dd + '-' + mm + '-' + yyyy;
        let changedName = finalDate.toString () + '__' + files[0].name;
    
        const formData = new FormData ();
        files.forEach (file => {
          formData.append ('file', file);
        });
    
        // console.log("changedName",changedName);
    
          const response = await CourseServices.uploadCourseImg (
            formData,
            changedName
          );
          setUploadingExpFile (false);
          // console.log("Data : ",response.data);
          setExplanationFileUrl (response.data.url);
          message.success ('Explanation Video Uploaded Successfully!');
        } catch (err) {
          console.log (err);
          setUploadingExpFile (false);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

    const confirmUpdateMaterial = async ()=>{
        // console.log("Current Update Material : ",currentUpdateMaterial);
        setLoading(true);
        let updatedData = {
            ...currentUpdateMaterial,
            userId:currentUser.id,
            title:courseMaterialTitle,
            textData:courseMaterialDescription,
            isLocked:isMaterialLocked,
            fileLink:fileUrl ? fileUrl : currentUpdateMaterial.fileUrl,
            explanationVideo:explanationFileUrl ? explanationFileUrl : currentUpdateMaterial.explanationVideo,
            order:orderId ? orderId : currentUpdateMaterial.order ? currentUpdateMaterial.order : '',
            isDownloadAllowed:isDownloadAllowed
        }
        console.log("Data to Update : ",updatedData)
        try{
            const response = await CourseServices.updateCourseMaterial(updatedData);
            // console.log(response.data);
            setLoading(false);
            setIsUpdateModal(false);
            getCourseMaterial();

            if(response){
                message.success(`Updated ${currentUpdateMaterial.title} successfully!`);
            }

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Unable to update!");
            setLoading(false);
        }
    }

    const cancelUpdateModal = ()=>{
        setIsUpdateModal(false);
    }

    function beforeUpload(file) {
        if(file){
          setFileList([file]);
          setUploadBtnDisabled(false);
        }
        return false;
    }


    
    const handleUpload = async ()=>{
        setUploading(true);
        const files = fileList;

        let date = new Date();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear()
        let finalDate = currentUpdateMaterial.title + '__' +
        dd + '-' + mm + '-' + yyyy;
        let changedName = finalDate.toString () + '__' + files[0].name;
        // let changedName = "23-2-2022_at_15:15:50__test3.mp4";

  
        const formData = new FormData();
        files.forEach(file => {
          formData.append('file', file);
        });
       
        // console.log("changedName",changedName);
  
        try{
            const response = await CourseServices.uploadCourseImg(formData,changedName);
            setUploading(false);
            // console.log("Data : ",response.data);
            setFileUrl(response.data.url);
            message.success("File Uploaded Successfully!");
  
        }catch(err){
          console.log(err);
          setUploading(false);
          message.error(err.message ? err.message : 'Something went wrong!');
          
        }
      
  
      }


      
      



    //   const getEditorState = (htmlData)=>{
    //       console.log("Html Data : ",htmlData);
    //     const html = htmlData;
    //     const contentBlock = convertFromHTML(html);
    //     if (contentBlock) {
    //         const contentState =ContentState.createFromBlockArray(
    //             contentBlock.contentBlocks,
    //             contentBlock.entityMap,
    //           );
    //         const editorState = EditorState.createWithContent(contentState);
    //         return editorState
    //     }

    //   }


    const handleUpdateRichText = async (currentItem)=>{
        setLoading(true);
        let updatedData = {
            ...currentItem,
            userId:currentUser.id,
            textData:updatedRichTextData,
            fileLink:fileUrl ? fileUrl : currentItem.fileUrl
        }
        // console.log("Data to Update : ",updatedData)
        try{
            const response = await CourseServices.updateCourseMaterial(updatedData);
            // console.log(response.data);
            setLoading(false);
            setIsChanged(false);
            getCourseMaterial();

            if(response){
                message.success(`Updated ${currentItem.title} Rich Text successfully!`);
            }

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Unable to update!");
            setLoading(false);
        }
    }


    const handleUploadModal = ()=>{
        setUploadModal(true);
      }
     
      const handleCancelUploadModal = ()=>{
        setUploadModal(false);
      } 
      function beforeRichUpload(file) {
        if(file){
          setRichFileList([file]);
        }
        return false;
    }


    
  const handleFileUpload = async ()=>{
    // console.log("uploading!");
    setUploading(true);
    const files = richFileList;

    // let date = new Date();
    // let dd = date.getDate();
    // let mm = date.getMonth() + 1;
    // let yyyy = date.getFullYear()
    // let hh = date.getHours();
    // let minutes = date.getMinutes();
    // let ss = date.getSeconds();
    // let finalDate =  dd + "-" + mm + "-"+yyyy + "_at_"+hh + ":" +minutes + ":"+ss;
  //  let changedName = finalDate.toString()+"__"+files[0].name
  let changedName = files[0].name


    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });
   
    // console.log("changedName",changedName);
    try{
        const response = await CourseServices.uploadCourseImg(formData,changedName);
        setUploading(false);
        // console.log("Data : ",response.data);
        setRichFileUrl(response.data.url);
        navigator.clipboard.writeText(response.data.url)
        message.success("File Uploaded Successfully!");
        message.success("Link copied to clipboard!");

    }catch(err){
      console.log(err);
      setUploading(false);
      message.error(err.message ? err.message : 'Something went wrong!');
      
    }

  }


  return (
    <div className="course-material-details">
        <div>
        <Breadcrumb className='breadCrumb'>
        <Breadcrumb.Item className='breadcrumbLink'>
            <Link to="/admin/dashboard">
            <HomeOutlined  style={{marginRight:'5px'}}/>
            <span>Dashboard</span>
            </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item  className='breadcrumbLink'>
            <Link to="/admin/dashboard/manage-courses">
            <BiBookReader style={{marginRight:'5px',fontSize:'1rem',marginBottom:'-2px'}}/>
            <span>All Courses</span>
            </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item  className='breadcrumbLink'>
            <Link to={`/admin/dashboard/edit-course-details/${courseId}`}>
            <span>{courseName}</span>
            </Link>
            </Breadcrumb.Item>
        <Breadcrumb.Item>Section Details</Breadcrumb.Item>
      </Breadcrumb>
        </div>
       <div className='section-header'>
       <h1>{section.title}</h1>
       </div>

       <div className='courseMaterialSection'>
           {courseMaterialArr.map((item)=>{
               return item.type==="Text" ?
               (
                   <div className='courseMaterialCard' key={item.id}>
                        <div className="courseMaterialCardHeader">
                        <h1>{item.title}</h1>
                        <div className='materialControl'>
                        <Tooltip title="Edit Course Material?" color={"#001529"}>
                        <EditOutlined className='controlEditIcon' onClick={()=>{handleMaterialEdit(item)}} />
                        </Tooltip>
                        <Tooltip title="Delete Course Material?" color={"#001529"}>
                        <DeleteOutlined className='controlDeleteIcon' onClick={()=>{showDeleteConfirm(item)}} />
                        </Tooltip>
                       
                        </div>
                        </div>
                        <p>{item.textData}</p>
                   </div>
               ):
               item.type==="Video" ?
               <div className='courseMaterialCard' key={item.id}>
               {/* <iframe src={item.fileLink} frameBorder="0" allowFullScreen title={item.title} width={'100%'} /> */}
               <div style={{display:'flex',justifyContent:'space-between'}}>
               <h2>{item.title}</h2>
               <div className='materialControl'>
                    <Tooltip title="Edit Course Material?" color={"#001529"}>
                    <EditOutlined className='controlEditIcon' onClick={()=>{handleMaterialEdit(item)}} />
                    </Tooltip>
                    <Tooltip title="Delete Course Material?" color={"#001529"}>
                    <DeleteOutlined className='controlDeleteIcon' onClick={()=>{showDeleteConfirm(item)}} />
                    </Tooltip> 
                </div>
               </div>
              

               <ReactPlayer url={item.fileLink} 
               width={'100%'} 
               playing={false}
               controls={true}

               />
                <div className="description">
               {item.textData && item.textData}
               </div>
               
               </div>
               :
               item.type==="Resource" ?
               <div className='courseMaterialCard' style={{textAlign:'center'}} key={item.id}>
               <div style={{display:'flex',justifyContent:'flex-end'}}>
               <div className='materialControl'>
                    <Tooltip title="Edit Course Material?" color={"#001529"}>
                    <EditOutlined className='controlEditIcon' onClick={()=>{handleMaterialEdit(item)}} />
                    </Tooltip>
                    <Tooltip title="Delete Course Material?" color={"#001529"}>
                    <DeleteOutlined className='controlDeleteIcon' onClick={()=>{showDeleteConfirm(item)}} />
                    </Tooltip> 
                </div>
               </div>

               <a href={item.fileLink} target={"_blank"} download rel="noreferrer" style={{fontWeight:'bold',color:'var(--green)'}}>
               <MdFileDownload style={{fontSize:'5rem',color:'var(--green)'}}/>
               <br />
               {item.title}
               </a>
               <div className="description">
               {item.textData && item.textData}
               </div>
               </div>
               :
               item.type==="Notebook" ?

               <div className='courseMaterialCard' style={{textAlign:'center'}} key={item.id}>
               <div style={{display:'flex',justifyContent:'flex-end'}}>
               <div className='materialControl'>
                    <Tooltip title="Edit Course Material?" color={"#001529"}>
                    <EditOutlined className='controlEditIcon' onClick={()=>{handleMaterialEdit(item)}} />
                    </Tooltip>
                    <Tooltip title="Delete Course Material?" color={"#001529"}>
                    <DeleteOutlined className='controlDeleteIcon' onClick={()=>{showDeleteConfirm(item)}} />
                    </Tooltip> 
                </div>
               </div>

               <Tabs
        defaultActiveKey="1"
        type="card"
       
        
      >
        <TabPane tab={"Notebook"} key="1">
        <div className='courseMaterialNotebook'>
                <h3>{item.title}</h3>
                <iframe title={item.title} src={item.fileLink} width={"100%"}></iframe>
            </div>
        </TabPane>

        <TabPane tab={'Explanation Video'} key="2">
        <div className='courseMaterialNotebook'>
                <video width={"100%"} controls>
                    <source type="video/mp4" src={item.explanationVideo} />
                </video>
            </div> 
        </TabPane>
      </Tabs>  


                  
               </div>
               
              
               
               :
               item.type ==='Document' ? 
               <div className='courseMaterialCard' style={{textAlign:'center'}} key={item.id}>
               <div style={{display:'flex',justifyContent:'flex-end'}}>
               <div className='materialControl'>
                    <Tooltip title="Edit Course Material?" color={"#001529"}>
                    <EditOutlined className='controlEditIcon' onClick={()=>{handleMaterialEdit(item)}} />
                    </Tooltip>
                    <Tooltip title="Delete Course Material?" color={"#001529"}>
                    <DeleteOutlined className='controlDeleteIcon' onClick={()=>{showDeleteConfirm(item)}} />
                    </Tooltip> 
                </div>
               </div>
               <iframe title={item.title} width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${item.fileLink}&embedded=true`}></iframe>

               <a href={item.fileLink} target={"_blank"} download rel="noreferrer" style={{fontWeight:'bold',color:'var(--green)'}}>
               <br />
               Download {item.title}
               </a>
               <div className="description">
               {item.textData && item.textData}
               </div>
               </div>
               :
               item.type==="Rich Text" ?
               <div className='courseMaterialCard'  key={item.id}>
               <div style={{display:'flex',justifyContent:'flex-end'}}>
               <div className='materialControl'>
                    <Tooltip title="Edit Course Material?" color={"#001529"}>
                    <EditOutlined className='controlEditIcon' onClick={()=>{handleMaterialEdit(item)}} />
                    </Tooltip>
                    <Tooltip title="Delete Course Material?" color={"#001529"}>
                    <DeleteOutlined className='controlDeleteIcon' onClick={()=>{showDeleteConfirm(item)}} />
                    </Tooltip> 
                </div>
               </div>
               {/* <Editor editorState={getEditorState(JSON.parse(item.textData))} readOnly={true} />  */}
               {/* <div dangerouslySetInnerHTML={{__html: item.textData}}></div>; */}
               <button className="primaryBtn" onClick={handleUploadModal} style={{float:'right',margin:'0'}}><HiUpload style={{fontSize:'1rem'}}/>Upload File</button>
               <ReactQuill modules={modules} defaultValue={item.textData} onChange={setUpdatedRichTextData} theme="snow" />
               {isChanged && <button className="primaryBtn" onClick={()=>{handleUpdateRichText(item)}}>
               {loading ? <Spin indicator={spinIcon} /> : 'Update'}
               </button>}
               </div>
               :
               null

           })}
       </div>

       <Modal title="Edit Material" 
        visible={isUpdateModal} 
        confirmLoading={loading}
        okText={"Update"}
        onOk={confirmUpdateMaterial}
        onCancel={cancelUpdateModal}>
        
       <div className='materialForm'>
       <input 
       placeholder="Enter Course Material Title"
       type="text" 
       onChange={(e)=>{setCourseMaterialTitle(e.target.value)}} 
       value={courseMaterialTitle}/>
       </div>
       <div>
       <label>Enter Material Order:</label>
       <InputNumber defaultValue={orderId}  onChange={(value)=>{setOrderId(value)}} />
       </div>

      { currentUpdateMaterial.type === 'Rich Text' ? null : 
       <textarea width="100%" 
            className="modal-textarea"
            value={courseMaterialDescription} 
            onChange={(e)=>{setCourseMaterialDescription(e.target.value)}}
            placeholder='Enter Text Data'>
       </textarea>
       }

       {(currentUpdateMaterial.type==='Video' || currentUpdateMaterial.type==='Resource') ?
           <div>
           <Upload.Dragger name="files" 
            beforeUpload={beforeUpload} 
            onRemove={()=>{setFileList([])}}
            className="uploader"
            fileList={fileList}
            >
                <p className="ant-upload-drag-icon">
                <InboxOutlined style={{color:'var(--green)'}}/>
                </p>
                {currentUpdateMaterial.type==='Video' ? 
                <>
                    <p className="ant-upload-text">Click or drag Video to this area to upload</p>
                    <p className="ant-upload-hint">You can upload video files such as .mp4,.mkv, .wmv or .avi</p>
                </>
                :
                <>
                <p className="ant-upload-text">Click or drag File to this area to upload</p>
                <p className="ant-upload-hint">You upload any resource file such as .zip, .rar, .pdf, .docx etc.</p>
                </>
                }
               
            </Upload.Dragger>
                <button 
                type="button" 
                disabled={uploading || fileList.length===0 || uploadBtnDisabled}
                className="primaryBtn" 
                style={{marginTop:'5px',marginBottom:'5px'}} 
                onClick={handleUpload}>
                {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
                </button>
           </div>
           :
           null
       }

       <Radio.Group onChange={handleMaterialPreview} defaultValue={isMaterialLocked}>
                <Radio value={false}>Allow Preview</Radio>
                <Radio value={true}>Disable Preview</Radio>
        </Radio.Group>

        {currentUpdateMaterial.type==='Notebook' &&      
       <>
        <br/> 
        <Radio.Group
                                onChange={(e)=>setIsDownloadAllowed(e.target.value)}
                                defaultValue={isDownloadAllowed}
                              >
                                <Radio value={true}>Allow Download</Radio>
                                <Radio value={false}>Disable Download</Radio>
                              </Radio.Group>

       <br />
        <div><b>Select a Notebook: </b></div>  
        <div className="uploadBtnWrapper"> 
        <Upload
         name="files1"
         beforeUpload={beforeNotebookUpload}
         onRemove={() => {
           setFileList ([]);
         }}
         className="uploader"
         fileList={fileList}
         accept='text/html'
       >
      
       <Tooltip title="Make Sure notebook format should be html"><Button icon={<UploadOutlined />}>Select a Notebook</Button></Tooltip>

       
       </Upload>
     
       <button
         type="button"
         disabled={
           uploading ||
             fileList.length === 0 ||
             uploadBtnDisabled
         }
         className="primaryBtn"
         style={{marginTop: '5px', marginBottom: '5px'}}
         onClick={handleUpload}
       >
         {uploading
           ? <Spin indicator={spinIcon} />
           : 'Upload Notebook'}
       </button>
       </div>

      <br/>
       <div><b>Select an Explanation Video: </b></div>
      <div className='uploadBtnWrapper'>
       <Upload
         
         name="files"
         beforeUpload={beforeExplanationUpload}
         onRemove={() => {
           setExplanationFile ([]);
         }}
         className="uploader"
         fileList={explanationFile}
         accept='video/mp4'
       >

         <Tooltip title="Make Sure video format should be mp4"> <Button icon={<UploadOutlined />}>Select Video</Button></Tooltip>
        
         
       </Upload>

        <button
         type="button"
         disabled={
           uploadingExpFile ||
             explanationFile.length === 0
             || explanationDisableBtn
         }
         className="primaryBtn"
         style={{marginTop: '5px', marginBottom: '5px'}}
         onClick={handleExplanationUpload}
       >
         {uploadingExpFile
           ? <Spin indicator={spinIcon} />
           : 'Upload Video'}
       </button>
      </div>
       </>
        }
        

      </Modal>


      <Modal title="Upload a File" 
        visible={uploadModal} 
        confirmLoading={uploading}
        okText={"Upload"}
        onOk={handleFileUpload}
        onCancel={handleCancelUploadModal}>
       {richFileUrl &&
        <div>
          <div><b>Recent File Link: </b></div>
         <a href={richFileUrl} style={{marginBottom:'2%'}} rel="noreferrer" target={'_blank'}>{richFileUrl}</a>
        </div>
       } 
       
        <Upload.Dragger name="files" 
            beforeUpload={beforeRichUpload} 
            onRemove={()=>{setRichFileList([])}}
            className="uploader"
            fileList={richFileList}
            >
                <p className="ant-upload-drag-icon">
                <InboxOutlined style={{color:'var(--green)'}}/>
                </p>
                <p className="ant-upload-text">Click or drag File to this area to upload</p>
                <p className="ant-upload-hint">You upload any resource file such as .zip, .rar, .pdf, .docx etc.</p>
      </Upload.Dragger>
     

      </Modal>



    </div>
  )
}

export default CourseMaterialDetails