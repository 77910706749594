import React, {useEffect, useState} from 'react';
import styles from './AllPrograms.module.css';
import ProjectServices from '../../services/projects.service';
import {message, Input} from 'antd';
import ProgramServices from '../../services/programs.service';
import {Link} from 'react-router-dom';

function AllPrograms () {
  const [allProgramCategory, setAllProgramCategory] = useState ([]);
  const [allPrograms, setAllPrograms] = useState ([]);
  const [filteredPrograms, setFilteredPrograms] = useState ([]);
  const [currentCategory, setCurrentCategory] = useState ('All Programs');

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          // console.log (response.data);
          setAllProgramCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    const getAllPrograms = async () => {
      ProgramServices.getAllPrograms ()
        .then (response => {
          console.log (response.data);
          setAllPrograms (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
      getAllPrograms ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCategoryChange = title => {
    setCurrentCategory (title);
    const filteredArr = allPrograms.filter (item => {
      return item.category.toLowerCase () === title.toLowerCase ();
    });

    if (filteredArr.length === 0 && title !== 'All Programs') {
      message.info (`No Programs found with ${title} category!`);
      setCurrentCategory ('All Programs');
    }

    setFilteredPrograms (filteredArr);
  };

  const handleSearchProgram = e => {
    let query = e.target.value;

    const filteredArr = allPrograms.filter (item => {
      return item.category.toLowerCase ().match (query.toLowerCase ());
    });

    setFilteredPrograms (filteredArr);
    setCurrentCategory ('All Programs');
  };

  return (
    <div className={styles.allPrograms}>
      <div className={styles.programsHeader}>
        <h1>Achieve your dream Job by <br />choosing a career program</h1>
        <div className={styles.programs__btnWrapper}>
          <a href="#allPrograms" className="outlineBtn">Explore Programs</a>
        </div>
      </div>

      <div className={styles.popularProgram}>
        <h2>Popular Programs</h2>
        <div className={styles.programsCardGroup}>
          {allPrograms.map (program => {
            return (
              <div className={styles.programCard}>
                <div
                  className={styles.programCard__top}
                  style={{
                    backgroundImage: `
                            linear-gradient(to bottom,  rgba(0, 12, 53, 0.8),rgba(4, 44, 155, 0.7)),
                            url('${program.imageUrl}')`,
                    backgroundSize: 'cover',
                  }}
                >
                  <h3>{program.title}</h3>
                </div>
                <div className={styles.programCard__body}>
                  <p>{program.description}</p>
                  <Link
                    to={`/program-details/${program.id}`}
                    className={styles.programCardBtn}
                  >
                    View Details
                  </Link>
                </div>
              </div>
            );
          })}
        </div>

      </div>

      <div className={styles.allPrograms__list} id="allPrograms">
        <div className={styles.programListHeader}>
          <h2>Find your perfect program</h2>
          <Input
            style={{width: '30%'}}
            onChange={handleSearchProgram}
            placeholder="Search Program by title"
          />
        </div>

        <div className={styles.programsList__area}>
          <div className={styles.programs__category}>
            <div className={styles.program__category__itemWrapper}>
              <div
                className={`${styles.program__category__item} ${currentCategory === 'All Programs' ? styles.activeCategory : ''}`}
                onClick={() => handleCategoryChange ('All Programs')}
              >
                All Programs
              </div>
              {allProgramCategory.map (c => {
                return (
                  <div
                    className={`${styles.program__category__item} ${currentCategory === c.title ? styles.activeCategory : ''}`}
                    key={c.id}
                    onClick={() => handleCategoryChange (c.title)}
                  >
                    {c.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.programs__list}>
            <div className={styles.programsCardGroup}>
              {filteredPrograms.length > 0
                ? filteredPrograms.map (program => {
                    return (
                      <div className={styles.programCard}>
                        <div
                          className={styles.programCard__top}
                          style={{
                            backgroundImage: `
                            linear-gradient(to bottom,  rgba(0, 12, 53, 0.8),rgba(4, 44, 155, 0.7)),
                            url('${program.imageUrl}')`,
                            backgroundSize: 'cover',
                          }}
                        >
                          <h3>{program.title}</h3>
                        </div>
                        <div className={styles.programCard__body}>
                          <p>{program.description}</p>
                          <Link
                            to={`/program-details/${program.id}`}
                            className={styles.programCardBtn}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    );
                  })
                : allPrograms.map (program => {
                    return (
                      <div className={styles.programCard}>
                        <div
                          className={styles.programCard__top}
                          style={{
                            backgroundImage: `
                            linear-gradient(to bottom,  rgba(0, 12, 53, 0.8),rgba(4, 44, 155, 0.7)),
                            url('${program.imageUrl}')`,
                            backgroundSize: 'cover',
                          }}
                        >
                          <h3>{program.title}</h3>
                        </div>
                        <div className={styles.programCard__body}>
                          <p>{program.description}</p>
                          <Link
                            to={`/program-details/${program.id}`}
                            className={styles.programCardBtn}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AllPrograms;
