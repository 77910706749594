import styles from './StartProject.module.css';
import {Button,Tooltip, Collapse,Input,Checkbox, message, Progress, Skeleton, Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';
import ProjectServices from '../../services/projects.service';
import ReactPlayer from 'react-player/lazy';
import { FaPlay,FaPause } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import avatar  from '../../assets/login.png';
import {AiOutlineLike,AiFillTrophy,AiFillLike} from 'react-icons/ai';
import {GiAchievement} from 'react-icons/gi';
import congrats from '../../assets/congrats.png';
import {EditOutlined} from '@ant-design/icons';

const {TextArea} = Input;

const {Panel} = Collapse
function StartProject () {
    const {id} = useParams ();
    const [allProjectMaterial, setAllProjectMaterial] = useState ([]);
    const [projectDetails, setProjectDetails] = useState ('');
    const [currentVideo,setCurrentVideo] = useState("");
    const [comment,setComment] = useState("");
    const {user:currentUser} = useSelector((state)=>state.auth);
    const [isFocused,setIsFocused] = useState(false);
    const [allProjectComments,setAllProjectComments] = useState([]);
    const [isCommenting,setIsCommenting] = useState(false);
    const [currentReply,setCurrentReply] = useState("");
    const [isReplyFocused,setIsReplyFocused] = useState(false);
    const [isReplying,setIsReplying] = useState(false);
    const [currentProjectStudent,setCurrentProjectStudent] = useState("");
    const [reply,setReply] = useState("");
    const [currentProgress,setCurrentProgress] = useState("");
    const [showFeedbackModal,setShowFeedbackModal] = useState(false);
    const [currentStar,setCurrentStar] = useState(0);
    const [currentDescription,setCurrentDescription] = useState("");
    const [feedbackLoading,setFeebackLoading] = useState("");
    useEffect (
        () => {
          let isMounted = true;
          const getProjectMaterial = async () => {
            try {
              const response = await ProjectServices.getProjectMaterialByProjectId (
                id
              );
              //   console.log (response.data);
              setAllProjectMaterial (response.data);
              setCurrentVideo(response.data[0]);
            } catch (err) {
              console.log (err);
              message.error (err.message ? err.message : 'something went wrong!');
            }
          };
         
    
          const getProjectDetails = async () => {
            try {
              const response = await ProjectServices.getProjectById (id);
              console.log (response.data);
              setProjectDetails (response.data);
            } catch (err) {
              console.log (err);
              message.error (err.message ? err.message : 'something went wrong!');
            }
          };
          if (isMounted) {
            getProjectMaterial ();
            getProjectDetails ();
           
          }
    
          return () => {
            isMounted = false;
          };
        },
        [id]
      );

      useEffect(()=>{
        let isMounted = true;
       

        let getProjectStudentDetails = async ()=>{
          try{
            const response = await ProjectServices.getProjectStudentDetails({projectId:projectDetails.id,userId:currentUser.id});
            console.log(response.data);
            if(response.data){
              setCurrentProjectStudent(response.data);
            }

           

          }catch(err){
            console.log(err);
            message.error (err.message ? err.message : 'something went wrong!');
          }
        }

        if(isMounted && projectDetails && currentUser){
        
          getProjectStudentDetails();
        }
        return ()=>{
          isMounted = false;
        }
      },[projectDetails,currentUser]);

      useEffect(()=>{
        let isMounted = true;
        let getProgress = async ()=>{
          try{
            const response = await ProjectServices.getProgressByProjectId({projectId:projectDetails.id,ProjectStudentId:currentProjectStudent.id});
            // console.log(response);
            if(response.data){
              setCurrentProgress(response.data);

            }
          }catch(err){
            console.log(err);
            message.error (err.message ? err.message : 'something went wrong!');
          }
        }

        if(isMounted && projectDetails && currentProjectStudent){
          getProgress()
        }

        return ()=>{
          isMounted = false;
        }
      },[projectDetails,currentProjectStudent]);

      useEffect(()=>{
        if(currentProgress && currentProgress.progress >=100){
            setShowFeedbackModal(true);
        }
      },[currentProgress])

     

      const handleAddLike = async (item)=>{
        let prevLike = item.likes ? item.likes : 0;
        let likedBy = item.likedBy;
        likedBy.push(currentUser.id);
        let finalData = {
          ...item,
          likes:prevLike+1,
          likedBy:likedBy
        }

        console.log(finalData);

        try{
          const response = await ProjectServices.updateComment(finalData);
          console.log(response.data);
          if(response){
            message.success("Liked Comment!");
            getProjectComment();
          }
        }catch(err){
          console.log(err);
          message.error (err.message ? err.message : 'something went wrong!');
        }

      }

      const handleRemoveLike = async (item)=>{
       
        let prevLike = item.likes ? item.likes : 0;
        let likedBy = item.likedBy;
        for( var i = 0; i < likedBy.length; i++){ 
    
          if ( likedBy[i] === currentUser.id) { 
      
            likedBy.splice(i, 1); 
          }
      
      }
        let finalData = {
          ...item,
          likes:prevLike-1,
          likedBy:likedBy
        }

        console.log(finalData);

        try{
          const response = await ProjectServices.updateComment(finalData);
          console.log(response.data);
          if(response){
            message.success("Removed Like!");
            getProjectComment();
          }
        }catch(err){
          console.log(err);
          message.error (err.message ? err.message : 'something went wrong!');
        }

      }

      useEffect(()=>{
        let isMounted = true;
        const getProjectComment = async () => {
          try {
            if(currentVideo){
              const response = await ProjectServices.getAllProjectMaterialComments (
                { projectMaterialId:currentVideo?.id}
              );
                console.log (response.data);
                let data = []
                response.data.forEach(item=>{
                  let isLiked = item.likedBy.includes(currentUser.id);
                  let updatedData = {
                    ...item,
                    isLiked:isLiked
                  }
                  data.push(updatedData);
                })

              setAllProjectComments(data);
            }
          } catch (err) {
            console.log (err);
            message.error (err.message ? err.message : 'something went wrong!');
          }
        };
        if(isMounted){
          getProjectComment();
        }

        return ()=>{
          isMounted = false;
        }
       
      },[currentVideo,currentUser.id])

      const getProjectComment = async () => {
        try {
          if(currentVideo){
            const response = await ProjectServices.getAllProjectMaterialComments (
              { projectMaterialId:currentVideo?.id}
            );
              console.log (response.data);
              let data = []
              response.data.forEach(item=>{
                let isLiked = item.likedBy.includes(currentUser.id);
                let updatedData = {
                  ...item,
                  isLiked:isLiked
                }
                data.push(updatedData);
              })

            setAllProjectComments(data);
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      const playCurrentVideo = (video)=>{
          setCurrentVideo(video)
      }

      const handleAddComment = async ()=>{
        setIsCommenting(true);
        try{
          let data = {
            comment:comment,
            userDetails:{
              username:currentUser?.username,
              profilePic:currentUser?.profilePicture,
             
            },
            projectMaterialId:currentVideo.id
          }
          console.log(data);
          const response = await ProjectServices.createComment(data);
          if(response){
            console.log(response.data);
            message.success("Added Your Comment Successfully!");
            setIsCommenting(false);
            getProjectComment();
          }
        }catch(err){
          console.log(err);
          message.error(err.message ? err.message : 'something went wrong!');
          setIsCommenting(false);
      }
        
      }

      const handleRemoveComment = ()=>{
        setComment("");
        setIsFocused(false);
      }

      const handleAddReply = async (item)=>{
        setIsReplying(true);
      
        try{
          let  replyData = {
            username:currentUser.username,
            profilePic:currentUser.profilePicture,
            reply:reply
          }
          let replyArr = item.replies;
          replyArr.push(replyData);
          let finalData = {
            ...item,
            reply:replyArr
          }
          const response = await ProjectServices.updateComment(finalData);
          console.log(response.data);
          if(response){
            setIsReplying(false);
            message.success("Added Reply Successfully!");
            getProjectComment();
            handleRemoveReply();
          }
        }catch(err){
          console.log(err);
          setIsReplying(false);
          message.error (err.message ? err.message : 'something went wrong!');
        }

      }
      const handleRemoveReply = ()=>{
        setReply("");
        setIsReplyFocused(false);
        setCurrentReply("");
      }

      const updatePercentage = (completedData)=>{
        console.log(completedData);
        let totalVideo = allProjectMaterial.length;
        let percentage = Math.floor((completedData.materialCompleted.length/totalVideo)*100);
        console.log("percentage : ",percentage);
        
        setCurrentProgress((prev)=>{
          let data = {...prev,progress:percentage}
          return data
        });
        ProjectServices.updateProjectProgress({progress:percentage,projectId:projectDetails.id,userId:currentUser.id,id:currentProgress.id,courseId:null}).then((result)=>{
          console.log(result);
        }).catch(err=>{
          console.log(err);
          message.error(err.message ? err.message : 'something went wrong!');
        })
      }

      const handleProjectMaterialComplete = async (material,isCompleted)=>{
        console.log(material,isCompleted);
       if(isCompleted){
        let materialCompleted = currentProjectStudent.materialCompleted
        if(materialCompleted.includes(material.id)){
          return;
        }
        materialCompleted.push(material.id);
        let data = {
          ...currentProjectStudent,
          materialCompleted:materialCompleted
        }
        try{
          const response = await ProjectServices.updateProjectStudents(data);
          console.log(response.data);
          updatePercentage(data);
        }catch(err){
          console.log(err);
          message.error(err.message ? err.message:'something went wrong!');
        }
       }else{
        let materialCompleted = currentProjectStudent.materialCompleted
        for( var i = 0; i < materialCompleted.length; i++){ 
    
          if ( materialCompleted[i] === material.id) { 
      
            materialCompleted.splice(i, 1); 
          }
      
      }
        let data = {
          ...currentProjectStudent,
          materialCompleted:materialCompleted
        }
        try{
          const response = await ProjectServices.updateProjectStudents(data);
          console.log(response.data);
          updatePercentage(data);
        }catch(err){
          console.log(err);
          message.error(err.message ? err.message:'something went wrong!');
        }
       }
      }

     const handleFeedback = async ()=>{
      setFeebackLoading(true);
       try{
         let data = {
           projectId:projectDetails.id,
          rating:currentStar,
          description:currentDescription,
          projectStudentId:currentProjectStudent.id
        }
        console.log(data);
        const response = await ProjectServices.createNewFeedback(data);
        console.log(response.data);
        if(response.data){
          message.success("Thank you for your feedback!");
          setFeebackLoading(false);
          setShowFeedbackModal(false);
          setCurrentDescription("");
          setCurrentStar(0);
        }
       }catch(err){
        setFeebackLoading(false);
         console.log(err);
         message.error(err.message ? err.message : 'something went wrong!');
       }
     }
     const handleFeedbackCancel = ()=>{
       setShowFeedbackModal(false);
     }
  return (
    <>
    <div className={styles.projectInfo}>
        <h2>{projectDetails?.title}<span className={styles.badge}>{projectDetails?.category}</span></h2>
        <div className={styles.progress}>
        {currentProgress?.progress < 100 ? <GiAchievement className={styles.progressIcon}/> : <AiFillTrophy className={styles.progressIcon}/> }
        <Progress  type="circle" width={30} percent={currentProgress.progress ? currentProgress.progress : 0} />
        </div>
    </div>

    <div className={styles.startProjectWrapper}>
      <div className={styles.currentVideoArea}>
            {currentVideo && 
            <div>
                <div className={styles.currentVideo}>
                <ReactPlayer fallback={<Skeleton active />} onEnded={()=>handleProjectMaterialComplete(currentVideo,true)} width={"100%"} height={"70vh"} url={currentVideo?.fileLink} controls={true} />
                </div>
                <div className={styles.currentVideoInfo}>
                    <h2>{currentVideo.title}</h2>
                    <p>{currentVideo.description}</p>
                </div>
            </div>
            }
        <div className={styles.addComment}>
        <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} className={styles.profilePic} alt="img" />
        <input onFocus={()=>setIsFocused(true)} type="text" placeholder={`Commenting publicly as ${currentUser?.username}`} value={comment} onChange={(e)=>setComment(e.target.value)} className={styles.addCommentInput} />
          
        </div>
        {isFocused &&
            <div className={styles.commentBtnArea}>
              <Button onClick={handleRemoveComment} style={{marginRight:'10px'}}>Cancel</Button>
              <Button type='primary'  disabled={comment===""} loading={isCommenting} onClick={handleAddComment}>Comment</Button>
            </div>
            }
        <div className={styles.allCommentArea}>
          <h3>Recent Comments</h3>
          {allProjectComments.map((item)=>{
            return(
              <div key={item.id}>
              <div className={styles.singleComment}>
                <div>
                {  <img src={item.userDetails.profilePic ? item.userDetails.profilePic : avatar} className={styles.profilePic} alt="img" />}
                </div>
               <div className={styles.singleComment__info}>
                <div><b>{item?.userDetails?.username}</b></div>
                <div>{item?.comment}</div>
                  
                  <div className={styles.likeArea}>
                  {item.isLiked ? 
                    <AiFillLike title={"Remove Like?"} style={{fontSize:'1rem',cursor:'pointer',color:'var(--green)'}} onClick={()=>handleRemoveLike(item)}/>
                  :
                  <AiOutlineLike title={"Like?"} style={{fontSize:'1rem',cursor:'pointer'}} onClick={()=>handleAddLike(item)}/>
                  }
                 
                  <span>{item.likes > 0 && item.likes}</span>
                  <b className={styles.replyText} onClick={()=>setCurrentReply(item.id)}>Reply</b>
                  </div>
                  

               </div>
              </div>
             
              {currentReply===item.id && 
              <div className={styles.replyArea}>
              <div className={styles.addComment}>
                  <img src={currentUser.profilePicture ? currentUser.profilePicture : avatar} style={{width:'30px',height:'30px'}} className={styles.profilePic} alt="img" />
                  <input onFocus={()=>setIsReplyFocused(true)} type="text" placeholder={`Replying publicly as ${currentUser?.username}`} value={reply} onChange={(e)=>setReply(e.target.value)} className={styles.addCommentInput} />
                  
                </div>
              {isReplyFocused &&
            <div className={styles.commentBtnArea}>
              <Button onClick={handleRemoveReply} style={{marginRight:'10px'}}>Cancel</Button>
              <Button type='primary' disabled={reply===""} loading={isReplying} onClick={()=>handleAddReply(item)}>Reply</Button>
            </div>
            }
              </div>
               
              }

              {item.replies.length > 0 &&
                <Collapse  ghost style={{marginLeft:'6%'}}>
                  <Panel header={`${item.replies.length} Replies`} key={item.id}>
                  {item.replies.map((reply,index)=>{
                    return(
                      <div className={styles.singleComment} key={index}>
                          <div>
                          {  <img style={{width:'30px',height:'30px'}} src={reply.profilePic ? reply.profilePic : avatar} className={styles.profilePic} alt="img" />}
                          </div>
                        <div className={styles.singleComment__info} style={{fontSize:'12px'}}>
                          <div><b>{reply?.username}</b></div>
                          <div>{reply?.reply}</div>
                        </div>
                      </div>
                    )
                  })}
                  </Panel>
                </Collapse>
              }

             
             
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.allProjectVideo}>
      <h3>Project Material</h3>
      {allProjectMaterial.map((item,index)=>{
                return(
                  <div className={styles.projectMaterialWrapper} key={index}>
                  <Checkbox checked={currentProjectStudent?.materialCompleted?.includes(item.id)} onChange={(e)=>handleProjectMaterialComplete(item,e.target.checked)}></Checkbox>
                  <div onClick={()=>playCurrentVideo(item)} className={`${styles.videoWrapper} ${item.id===currentVideo.id && styles.activeVideo}`} >
                        <div style={{
                                backgroundImage: `
                                linear-gradient(to bottom,  rgba(0, 12, 53, 0.83),rgba(4, 44, 155, 0.82)),
                                url('${projectDetails?.imageUrl}')`,
                                backgroundSize: 'cover',height:'70px',width:'120px'}}
                                className={styles.videoImg}
                                >
                    {item.id===currentVideo.id ? <FaPause onClick={()=>playCurrentVideo(item)} className={styles.playIcon} />:<FaPlay onClick={()=>playCurrentVideo(item)} className={styles.playIcon} />}
                        </div>
                        <div className={styles.videoInfo}>
                            <h4>{index+1}. {item.title.length > 50 ? item.title.slice(0,50)+'...' : item.title}</h4>
                        </div>
                    </div>
                  </div>
                )
            })}
 
      </div>

      <Modal confirmLoading={feedbackLoading} okText={"Submit"} title="Give Feedback" visible={showFeedbackModal} onOk={handleFeedback} onCancel={handleFeedbackCancel}>
            <img  src={congrats} alt="congrats" className={styles.congratsImg} />
            <div className={styles.feedbackStars}>
            {
              currentStar===0 ?
              [...Array(5)].map((e,i)=>{
              return(<Tooltip title={`${i+1} Stars`} placement="bottom"><div onClick={()=>setCurrentStar(i+1)} className={styles.star} key={i}></div></Tooltip>)
            })
            :
            [...Array(currentStar)].map((e,i)=>{
              return(<Tooltip title={`${i+1} Stars`} placement="bottom"><div onClick={()=>setCurrentStar(i+1)} className={`${(i+1) > currentStar ? styles.star : styles.filledStar}`} key={i}></div></Tooltip>)
            })
            
            }

            {currentStar > 0 && 
            <EditOutlined className={styles.editIcon} onClick={()=>setCurrentStar(0)}/>
            }

            </div>
            
            <div>
              <TextArea value={currentDescription} className={styles.description} placeholder='Please Give your feedback details' maxLength={255} showCount onChange={(e)=>setCurrentDescription(e.target.value)} />
            </div>

      </Modal>
    </div>
    </>
  );
}

export default StartProject;
