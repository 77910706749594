import axios from 'axios';

 //var API_URL = 'http://localhost:5000/api/auth/';
var API_URL = 'https://dashboard.codersarts.com/api/auth/';

const register = data => {
  return axios.post (API_URL + 'signup', {
    data,
  });
};

const login = async (username, password) => {
  return axios
    .post (API_URL + 'signin', {
      username,
      password,
    })
    .then (response => {
      if (response.data.accessToken) {
        localStorage.setItem ('user', JSON.stringify (response.data));
      }
      return response.data;
    });
};

const sendOtpFromBackend = async userMail => {
  return axios.post (API_URL + 'sendOtpToMail', {
    userMail: userMail,
    apiConfig: '59c79b3b096c6c4f9d23',
  });
};

const logout = () => {
  localStorage.removeItem ('user');
};

const AuthService = {
  register,
  login,
  logout,
  sendOtpFromBackend,
};

export default AuthService;
