import {
  HomeOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  ProjectOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Spin,
  Button,
  Upload,
  Form,
  Input,
  message,
  Modal,
  Select,
  Checkbox
} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import '../../ManageCourses/CreateCourses/CreateCourses.css';
import ProjectServices from '../../../../services/projects.service';
import CourseServices from '../../../../services/courses.service';
import {useSelector} from 'react-redux';

const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);

function CreateProjects () {
  const [form] = Form.useForm ();
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const [loading, setLoading] = useState (false);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState (false);
  const [isCategoryModal, setIsCategoryModal] = useState (false);
  const [isCategoryAdding, setIsCategoryAdding] = useState (false);
  const [allProjectCategory, setAllProjectCategory] = useState ([]);
  // const [complexity, setComplexity] = useState ('Beginner');
  // const [isFeatured, setIsFeatured] = useState (false);
  // const [learningOutcome, setLearningOutcome] = useState ([]);
  const [newCategory, setNewCategory] = useState ('');

  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          // console.log (response.data);
          setAllProjectCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  const onReset = () => {
    form.resetFields ();
  };

  const getAllProjectCategory = () => {
    ProjectServices.getAllProjectCategory ()
      .then (response => {
        console.log (response.data);
        setAllProjectCategory (response.data);
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleCategorySubmit = async () => {
    setIsCategoryAdding (true);
    try {
      const response = await ProjectServices.createNewProjectCategory (
        newCategory,
        currentUser.id
      );
      //   console.log (response);
      if (response) {
        message.success (`Added ${newCategory} Successfully!`);
        setNewCategory ('');
        getAllProjectCategory ();
        setIsCategoryAdding (false);
        setIsCategoryModal (false);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setIsCategoryAdding (false);
    }
  };

  const onFinish = async values => {
    setLoading (true);
    let data = {
      ...values,
      imageUrl: fileUrl,
      userId: currentUser.id,
    };
    // console.log (data);
    try {
      const response = await ProjectServices.createNewProject (data);
      console.log (response.data);
      message.success ('Created Project Successfully!');
      setLoading (false);
      // onReset();
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  function beforeUpload (file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (file) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    if (!isJpgOrPng) {
      message.error ('You can only upload JPG/PNG file!');
      setUploadBtnDisabled (true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error ('Image must smaller than 2MB!');
      setUploadBtnDisabled (true);
    }

    return false;
  }

  const handleUpload = async () => {
    setUploading (true);
    const files = fileList;
    // console.log("File List : ",files);
    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    let changedName =
      finalDate.toString () + '__' + files[0].name + '__project';
    // let changedName="8-2-2022_at_13:13:5__nodejs_banner.jpg";

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      message.success ('Image Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  return (
    <div className="createCourses">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumb className="breadCrumb">
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard">
              <HomeOutlined style={{marginRight: '5px'}} />
              <span>Dashboard</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard/manage-projects">
              <ProjectOutlined
                style={{
                  marginRight: '5px',
                  fontSize: '1rem',
                  marginBottom: '-2px',
                }}
              />
              <span>All Projects</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Create Project</Breadcrumb.Item>
        </Breadcrumb>

        <div>
          <Button
            type="primary"
            className="btn"
            onClick={() => setIsCategoryModal (true)}
          >
            <PlusOutlined /> Add Category
          </Button>
        </div>
      </div>

      <Modal
        title="Add New Project Category"
        visible={isCategoryModal}
        confirmLoading={isCategoryAdding}
        okText={'Add'}
        onOk={handleCategorySubmit}
        onCancel={() => setIsCategoryModal (false)}
      >

        <div className="materialForm">
          <label>
            <b>Category Name</b>
          </label>

          <Input
            placeholder="e.g. Web Development"
            type="text"
            onChange={e => {
              setNewCategory (e.target.value);
            }}
            value={newCategory}
          />
        </div>
      </Modal>

      <div className="createCourseContainer">
        <h1>Fill The Project Details</h1>
        <Form
          requiredMark={true}
          scrollToFirstError={true}
          className="createForm"
          layout={'vertical'}
          form={form}
          name="course-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="title"
            label="Title"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="e.g. Javascript Counter" />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              placeholder="e.g. Web Development"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes (input)}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase ()
                  .localeCompare (optionB.children.toLowerCase ())}
            >
              {allProjectCategory.map (item => {
                return (
                  <Select.Option key={item.id} value={item.title}>{item.title}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="complexity"
            label="Complexity"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
             defaultValue={"Beginner"}
            >
              <Select.Option value={'Beginner'}>Beginner</Select.Option>
              <Select.Option value={'Intermediate'}>Intermediate</Select.Option>
              <Select.Option value={'Advanced'}>Advanced</Select.Option>
            </Select>
          </Form.Item>

          <Form.List
        name="learningOutcome"
        rules={[
          {
            validator: async (_, learningOutcome) => {
              if (!learningOutcome || learningOutcome.length < 2) {
                return Promise.reject(new Error('Please add atleast 2 Learning Points'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
               
                label={index === 0 ? 'Learning Points' : ''}
                required={true}
                key={field.key}
                className="form-group"
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input learning point or delete this field.",
                    },
                  ]}
                  className="form-group"
                  noStyle
                >
                  <Input placeholder="e.g. You will learn about managing React states" 
                  style={{ width: '90%' }} />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '100%' }}
                icon={<PlusOutlined />}
                className="left"
              >
                Add Learning
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>


          <Form.Item
            name="description"
            label="Description"
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: 'Please input description',
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={255}
              placeholder="Enter Details of the Project"
            />
          </Form.Item>

          <Form.Item
            name="isFeatured"
            valuePropName="checked"
            style={{textAlign:'left'}}
          >
            <Checkbox>is Project Featured?</Checkbox>
          </Form.Item>

          <br />

          <Form.Item label="Upload Image">

            <Form.Item name="file" initialValue={fileUrl} noStyle>
              <Upload.Dragger
                name="files"
                beforeUpload={beforeUpload}
                onRemove={() => {
                  setFileList ([]);
                }}
                fileList={fileList}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{color: 'var(--green)'}} />
                </p>
                <p className="ant-upload-text">
                  Click or drag image to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Upload a Single Image File with maximum 2 MB of size.
                </p>
              </Upload.Dragger>
              <button
                type="button"
                disabled={
                  uploading || fileList.length === 0 || uploadBtnDisabled
                }
                className="primaryBtn"
                style={{marginTop: '5px', marginBottom: '5px'}}
                onClick={handleUpload}
              >
                {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
              </button>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              loading={loading}
              className="btn"
              htmlType="submit"
            >
              Submit
            </Button>
            <Button htmlType="button" className="btnOutline" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>

      </div>
    </div>
  );
}

export default CreateProjects;
