import React, {useEffect} from 'react';
import styles from './CheckoutForm.module.css';
import PaymentServices from '../../services/payments.services';
import {useParams} from 'react-router';
import { message } from 'antd';
import StudentServices from '../../services/students.service';
import { CheckCircleOutlined } from '@ant-design/icons';
import { history } from '../../helpers/history';
import { useSelector } from 'react-redux';

function PaymentSuccess () {
  const params = useParams ();
  const {courseId,userId,intent} = params;
  const {user: currentUser} = useSelector (state => state.auth);


  const createProgress = async ()=>{
    try{
      const response = await StudentServices.createCourseProgress(
        {userId:currentUser.id,
        courseId:courseId});
        //console.log("progress created : ",response.data)
        if(response.data){
          history.push (`/courses/learn-course/${courseId}`);
        }
    }catch(err){  
      console.log(err);
      message.error(err.message ? err.message : 'something went wrong!');
    }
  
  }


  useEffect (
    () => {
      let isMounted = true;
      
   
      // console.log (intent);
      const enrollNow = async () => {
     
        try {
          let response = await StudentServices.enrollNewStudent ({
            userId,
            username: currentUser.username,
            courseId: courseId,
            userDetails:currentUser
          });
          let studentId = "";
          //console.log(response.data);

          if(response.status===200){
            message.success (`You're Enrolled successfully!`);
            studentId = response.data?.id;
          }

          return studentId;
          
         
        } catch (err) {
        
          const msg =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString ();
          console.log (err);
          message.error (msg);
        }
      };

    

      const savePaymentDetails = async () => {
        try {
          if (intent) {
            const paymentIntentResponse = await PaymentServices.getPaymentIntent (
              intent
            );
            //console.log (paymentIntentResponse);
            const currentPaymentIntent = paymentIntentResponse.data;
            if(paymentIntentResponse){
              const studentId = await enrollNow();
              let paymentData ={
                amount:parseFloat(currentPaymentIntent?.amount)/100,
                currency:currentPaymentIntent?.currency,
                transaction_id:intent,
                paymentMethod:currentPaymentIntent.paymentMethod,
                type:"Stripe",
                StudentId:studentId,
                status:true
              }
              //console.log(paymentData);
              const paymentResponse = await PaymentServices.createNewPayment(paymentData);
              //console.log(paymentResponse.data);
              
            }

           
          }
        } catch (err) {
          console.log (err);
        }
      };

      if (intent && isMounted && userId) {
        savePaymentDetails ();
      }



      return () => {
        isMounted = false;
      };
    },
    [userId]
  );
  return (
    <div className={styles.paymentSuccessCard}>
      <CheckCircleOutlined
        style={{fontSize: '6rem'}}
        className={styles.paymentSuccessCardIcon}
      />
      <h2>Payment Successful</h2>
  
      <br />
      <button className='primaryBtn' onClick={createProgress}>Go to Course</button>
    </div>
  );
}

export default PaymentSuccess;
