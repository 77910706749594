import axios from 'axios';
import AuthHeader from './auth-header';

// const SERVER_URL = 'http://localhost:8000';
const SERVER_URL = 'https://learn.codersarts.com/';
const createNewPayment = data => {
  return axios.post (SERVER_URL+'/api/payments/create-new-payment', data, {
    headers: AuthHeader (),
  });
};

const getAllPayments = () => {
  return axios.get (SERVER_URL+'/api/payments/get-all-payments', {
    headers: AuthHeader (),
  });
};

const createRazorpayCoursePayment = (data)=>{
  return axios.post (SERVER_URL+'/create-razorpay-course-payment', data, {
    headers: AuthHeader (),
  });
}

const captureRazorpayment = (paymentId, data)=>{
  return axios.post (SERVER_URL+'/capture/'+paymentId, data, {
    headers: AuthHeader (),
  });
}

const getPaymentIntent = client_secret => {
  return axios.get (SERVER_URL+'/api/payments/intent/' + client_secret, {
    headers: AuthHeader (),
  });
};

const getAllUserPayments = id => {
  return axios.post (
    SERVER_URL+'/api/payments/get-user-payments',
    {userId: id},
    {
      headers: AuthHeader (),
    }
  );
};

const getSinglePaymentById = id => {
  return axios.post (
    SERVER_URL+'/api/payments/get-payment-by-id',
    {id: id},
    {
      headers: AuthHeader (),
    }
  );
};

// const getSinglePaymentByBookingId = id => {
//   return axios.post (
//     SERVER_URL+'/api/payments/get-payment-by-booking-id',
//     {id: id},
//     {
//       headers: AuthHeader (),
//     }
//   );
// };

const createCoursePaymentIntent = id => {
  return axios.post (
    SERVER_URL+'/api/payments/create-course-payment-intent/'+id,
    {
      headers: AuthHeader (),
    }
  );
};


const updatePaymentStatus = data => {
  return axios.post (SERVER_URL+'/api/payments/update-payment-status', data, {
    headers: AuthHeader (),
  });
};

const PaymentServices = {
  createNewPayment,
  getAllPayments,
  getPaymentIntent,
  getAllUserPayments,
  getSinglePaymentById,
  updatePaymentStatus,
  createCoursePaymentIntent,
  createRazorpayCoursePayment,
  captureRazorpayment
};

export default PaymentServices;
