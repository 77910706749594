import axios from 'axios';
import authHeader from './auth-header';

// const SERVER_URL = 'http://localhost:8000/';
const SERVER_URL = 'https://learn.codersarts.com/';
const API_CONFIG = '59c79b3b096c6c4f9d23';

const createNewCourse = data => {
  return axios.post (
    SERVER_URL + 'create-new-course',
    {...data},
    {headers: authHeader ()}
  );
};

const getAllCourses = () => {
  return axios.post (SERVER_URL + 'get-all-courses');
};

const getAiQuery = (data) => {
  return axios.post (SERVER_URL + 'get-query-for-question',data);
};


//admin route
const deleteCourseById = (id, userId) => {
  return axios.post (
    SERVER_URL + 'delete-course-by-id',
    {id: id, userId: userId, apiConfig: API_CONFIG},
    {headers: authHeader ()}
  );
};

const getCourseById = id => {
  return axios.post (SERVER_URL + 'get-course-by-id', {id: id});
};

const uploadCourseImg = (data, changedName) => {
  return axios.post (SERVER_URL + 'upload-course-img', data, {
    headers: {changedName: changedName},
  });
};

const removeItemFromBucket = data => {
  return axios.post (SERVER_URL + 'delete-item-from-bucket', data, {
    headers: authHeader (),
  });
};

const updateCourseById = data => {
  return axios.post (
    SERVER_URL + 'update-course-by-id',
    {...data},
    {headers: authHeader ()}
  );
};

const createNewCourseSection = data => {
  return axios.post (SERVER_URL + 'create-course-section', data, {
    headers: authHeader (),
  });
};

const getCourseSection = courseId => {
  return axios.post (
    SERVER_URL + 'get-course-section',
    {courseId: courseId},
    {headers: authHeader ()}
  );
};

const createNewMaterial = data => {
  return axios.post (SERVER_URL + 'create-course-material', data, {
    headers: authHeader (),
  });
};

const getCourseMaterial = courseId => {
  return axios.post (
    SERVER_URL + 'get-course-material',
    {courseId: courseId},
    {headers: authHeader ()}
  );
};

const getCourseMaterialBySection = sectionId => {
  return axios.post (
    SERVER_URL + 'get-course-material-by-section',
    {sectionId: sectionId},
    {headers: authHeader ()}
  );
};

const updateCourseMaterial = data => {
  return axios.post (SERVER_URL + 'update-course-material', data, {
    headers: authHeader (),
  });
};

const deleteCourseMaterialById = data => {
  return axios.post (SERVER_URL + 'delete-course-material', data, {
    headers: authHeader (),
  });
};

const updateCourseSection = data => {
  return axios.post (SERVER_URL + 'update-course-section', data, {
    headers: authHeader (),
  });
};

const getCourseMaterialById = id => {
  // console.log("Id : ",id);
  return axios.post (
    SERVER_URL + 'get-course-material-by-id',
    {id: id},
    {headers: authHeader ()}
  );
};

const deleteCourseSectionById = data => {
  return axios.post (SERVER_URL + 'delete-course-section-by-id', data, {
    headers: authHeader (),
  });
};

const createNewModuleQuiz = data => {
  return axios.post (SERVER_URL + 'module-quiz/add-new-quiz', data, {
    headers: authHeader (),
  });
};

const updateModuleQuiz = data => {
  return axios.post (SERVER_URL + 'module-quiz/update-module-quiz', data, {
    headers: authHeader (),
  });
};

const deleteModuleQuiz = data => {
  return axios.post (SERVER_URL + 'module-quiz/delete-module-quiz', data, {
    headers: authHeader (),
  });
};

const getAllModuleQuiz = () => {
  return axios.get (SERVER_URL + 'module-quiz/get-all-module-quiz', {
    headers: authHeader (),
  });
};

const getSingleModuleQuiz = moduleId => {
  return axios.get (
    SERVER_URL + `module-quiz/get-single-module-quiz/${moduleId}`,
    {
      headers: authHeader (),
    }
  );
};

const handleUpdateMaterialOrder = async data => {
  return axios.post (SERVER_URL + 'update-course-material-order', data, {
    headers: authHeader (),
  });
};

const handleUpdateSectionOrder = async data => {
  return axios.post (SERVER_URL + 'update-course-section-order', data, {
    headers: authHeader (),
  });

};


const createCourseComment = data => {
  return axios.post (SERVER_URL + 'create-course-material-comment', data);
};

const getAllCourseMaterialComments = data => {
  return axios.post (SERVER_URL + 'get-course-material-comment', data);
};

const updateCourseComment = data => {
  return axios.post (SERVER_URL + 'update-course-material-comment', data);
};

const uploadPdfForChat = (data)=>{
  return axios.post (SERVER_URL + 'upload-pdf-for-chat', data);
}

const getDocQuery = (data)=>{
  return axios.post (SERVER_URL + 'get-doc-query', data);
}

 const createNewPrice = (data) => {
  return axios.post (SERVER_URL + 'api/price/create',data,{
    headers:authHeader()
  });
};

const updatePriceById = (data) => {
  return axios.post (SERVER_URL + 'api/price/update',data,{
    headers:authHeader()
  });
};

const getAllPrice = (data) => {
  return axios.post (SERVER_URL + 'api/price/get-all',data,{
    headers:authHeader()
  });
};

const findPrice = (data) => {
  return axios.post (SERVER_URL + 'api/price/get-corresponding-price',data,{
    headers:authHeader()
  });
};

const deletePriceById = (data)=>{
  return axios.post (SERVER_URL + 'api/price/delete',data,{
    headers:authHeader()
  });
}

const CourseServices = {
  createNewCourse,
  updateCourseById,
  getAllCourses,
  getCourseById,
  deleteCourseById,
  handleUpdateMaterialOrder,
  handleUpdateSectionOrder,
  uploadCourseImg,
  createNewCourseSection,
  getCourseSection,
  createNewMaterial,
  getCourseMaterial,
  getCourseMaterialBySection,
  updateCourseMaterial,
  deleteCourseMaterialById,
  updateCourseSection,
  getCourseMaterialById,
  deleteCourseSectionById,
  removeItemFromBucket,
  createNewModuleQuiz,
  updateModuleQuiz,
  deleteModuleQuiz,
  getAllModuleQuiz,
  getSingleModuleQuiz,
  createCourseComment,
  getAllCourseMaterialComments,
  updateCourseComment,
  getAiQuery,
  uploadPdfForChat,
  getDocQuery,
  createNewPrice,
  deletePriceById,
  getAllPrice,
  updatePriceById,
  findPrice

};

export default CourseServices;
