import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom';
import './PreviewCourse.css';
import {message} from  'antd';
import CourseServices from '../../../services/courses.service';
import ReactPlayer from 'react-player/lazy';
import { MdFileDownload } from 'react-icons/md';
import ReactQuill from 'react-quill';
import {history} from '../../../helpers/history'


const modules = {
    toolbar:false
  }


function PreviewCourse() {
    const {id} = useParams();
    const [material,setMaterial] = useState(null)

    useEffect(()=>{
        const getMaterialById = async ()=>{
            try{
                const response = await CourseServices.getCourseMaterialById(id);
                // console.log("material : ",response.data);
               
                if(response.data[0].isLocked){
                    history.push('/courses');
                }else{
                    setMaterial(response.data[0])
                }
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Unable to fetch course section!');
            }
        }
        if(id){
            getMaterialById();
        }
    },[id])
  return (
    <div className='previewCourse'>
        <div style={{ padding: 24, minHeight: 360 }}>
            
             { material &&
             <div className="course-material-wrapper">
             <h1>{material.title}</h1>
             <div className="material-content">
             {material.type==='Text' ? 
             <div className="text-data" >
                {material.textData}
             </div>
             :
             material.type==="Video" ?
             <div className='video-data'>
             <ReactPlayer url={material.fileLink} 
               width={'100%'} 
               playing={false}
               controls={true}

               />
               <div className="description">
               {material.textData && material.textData}
               </div>
             </div>
             :
             material.type==="Resource" ?
             <div className='resource-data'>
                <a href={material.fileLink} target={"_blank"} download rel="noreferrer" style={{fontWeight:'bold',color:'var(--green)'}}>
               <MdFileDownload style={{fontSize:'5rem',color:'var(--green)'}}/>
               <br />
               Download {material.title}
               </a>
               <div className="description">
               {material.textData && material.textData}
               </div>
             </div>
             :
             material.type==="Rich Text" ?
             <div style={{textAlign:'left'}}>
               {/* <Editor editorState={getEditorState(JSON.parse(item.textData))} readOnly={true} />  */}
               {/* <div>{ ReactHtmlParser(material.textData) }</div>; */}
               <ReactQuill modules={modules} value={material.textData} readOnly theme="snow" />
            </div>
             :
             null
             }
             </div>
             </div>
             }
        </div>
    </div>
  )
}

export default PreviewCourse