import axios from 'axios';
import authHeader from './auth-header';

// const SERVER_URL = 'http://localhost:8000/students';
const SERVER_URL = 'https://learn.codersarts.com/students';
const API_CONFIG = '59c79b3b096c6c4f9d23';

const enrollNewStudent = data => {
  return axios.post (SERVER_URL + '/enroll-student', data, {
    headers: authHeader (),
  });
};

const checkIsEnrolled = data => {
  return axios.post (SERVER_URL + '/check-enroll', data, {
    headers: authHeader (),
  });
};

const getEnrolledCourses = data => {
  return axios.post (SERVER_URL + '/get-enrolled-courses', data, {
    headers: authHeader (),
  });
};

const getEnrolledStudents = (courseId, userId) => {
  return axios.post (
    SERVER_URL + '/get-enrolled-students-by-courseid',
    {
      courseId: courseId,
      userId: userId,
      apiConfig: API_CONFIG,
    },
    {
      headers: authHeader (),
    }
  );
};

const getEnrolledStudentsLength = courseId => {
  return axios.post (
    SERVER_URL + '/get-enrolled-students-length',
    {
      courseId: courseId,
    },
    {
      headers: authHeader (),
    }
  );
};

const getAllEnrolledStudents = userId => {
  return axios.post (
    SERVER_URL + '/get-all-enrolled-students',
    {
      userId: userId,
      apiConfig: API_CONFIG,
    },
    {
      headers: authHeader (),
    }
  );
};

const updateCourseStudents = data => {
  return axios.post (SERVER_URL + '/update-course-student', data, {
    headers: authHeader (),
  });
};

const getSingleStudentInCourse = data => {
  return axios.post (SERVER_URL + '/get-single-student-in-course', data, {
    headers: authHeader (),
  });
};

const createCourseProgress = data => {
  return axios.post (SERVER_URL + '/create-course-progress', data, {
    headers: authHeader (),
  });
};

const updateCourseProgress = data => {
  return axios.post (SERVER_URL + '/update-course-progress', data, {
    headers: authHeader (),
  });
};

const getCourseProgress = data => {
  return axios.post (SERVER_URL + '/get-course-progress', data, {
    headers: authHeader (),
  });
};

const getAllEnrolledCoursesForUser = data => {
  return axios.post (SERVER_URL + '/get-all-enrolled-courses', data, {
    headers: authHeader (),
  });
};

const StudentServices = {
  enrollNewStudent,
  checkIsEnrolled,
  getEnrolledCourses,
  getEnrolledStudents,
  getEnrolledStudentsLength,
  getAllEnrolledStudents,
  updateCourseStudents,
  getSingleStudentInCourse,
  createCourseProgress,
  updateCourseProgress,
  getCourseProgress,
  getAllEnrolledCoursesForUser,
};

export default StudentServices;
