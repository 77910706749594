import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Collapse, message,Drawer, Spin, Tag, Divider, Modal} from 'antd';
import CourseService from '../../../services/courses.service';
import './SingleCourseDetails.css';
import cap from '../../../assets/student.png';
import check from '../../../assets/check.png';
import {Link} from 'react-router-dom';
import CourseServices from '../../../services/courses.service';
import {HiOutlineLockClosed, HiOutlineEye} from 'react-icons/hi';
import StudentServices from '../../../services/students.service';
import {DollarCircleOutlined, DollarOutlined, PlayCircleOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import ReactPlayer from 'react-player';
import { IoAlarmOutline } from 'react-icons/io5';
import { FaRupeeSign } from 'react-icons/fa';

const {Panel} = Collapse;


function SingleCourseDetails () {
  let {slug} = useParams ();
  const [courseDetails, setCourseDetails] = useState (null);
  const [courseMaterialArr, setCourseMaterialArr] = useState ([]);
  const [courseSectionArr, setCourseSectionArr] = useState ([]);
 
  const [isEnrolled, setIsEnrolled] = useState (false);
  const [enrolledStudents,setEnrolledStudents] = useState(0);
  const {user: currentUser} = useSelector (state => state.auth);
  const [isCoursePreview,setIsCoursePreview] = useState(false);
  const [isPayModal,setIsPayModal] = useState("");

  useEffect (
    () => {
      async function getCourseDetails () {
        try {
          const response = await CourseService.getCourseById (slug);
          // console.log("Course details : ",response.data);
          setCourseDetails (response.data);
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch course details!'
          );
        }
      }
      getCourseDetails ();
    },
    [slug]
  );

  useEffect (
    () => {
      if (courseDetails) {
        const getCourseSection = async () => {
          try {
            const response = await CourseServices.getCourseSection (
              courseDetails.id
            );
            // console.log(response.data);
            let data = response.data?.sort((a,b)=>a?.order - b?.order);
            setCourseSectionArr (data);
          } catch (err) {
            console.log (err);
            message.error (
              err.message ? err.message : 'Unable to fetch course section!'
            );
          }
        };

        const getCourseMaterial = async () => {
          try {
            const response = await CourseServices.getCourseMaterial (
              courseDetails.id
            );
            // console.log(response.data);
            let data = response.data?.sort((a,b)=>a?.order - b?.order);
            setCourseMaterialArr (data);
          } catch (err) {
            console.log (err);
            message.error (
              err.message ? err.message : 'Unable to fetch course section!'
            );
          }
        };

        getCourseSection ();
        getCourseMaterial ();
      }
    },
    [courseDetails]
  );

  useEffect (
    () => {
      if (courseDetails && currentUser) {
        const isStudentEnrolled = async () => {
          try {
            const result = await StudentServices.checkIsEnrolled ({
              userId: currentUser.id,
              courseId: courseDetails.id,
            });
            if (result.data.isEnrolled) {
              setIsEnrolled (true);
            }
          } catch (err) {
            console.log (err);
            message.error (err.message ? err.message : 'something went wrong!');
          }
        };

        isStudentEnrolled ();
      }
    },
    [courseDetails, currentUser]
  );


  useEffect(()=>{
    if(courseDetails){
      const getEnrolledStudents = async ()=>{
        try{
          const response = await StudentServices.getEnrolledStudentsLength(courseDetails.id);
          if(response){
            setEnrolledStudents(response.data?.enrolled);
          }
        }catch(err){
          console.log(err);
          message.error(err.message ? err.message : 'unable to fetch enrolled students list!');
        }
      }

      getEnrolledStudents();
    }
  },[courseDetails])


  




  

  const openPreview = ()=>{
    setIsCoursePreview(true);
  }
  return (
    <div className="courseDetails">
      
      <div className='detailBannerWrapper'>
     

     <div className='course__info__card'>
      <div>
      <h1>{courseDetails?.title}</h1>
      <div className='courseTags'>
      <Tag color="#108ee9">{courseDetails?.category}</Tag>
      <Tag>{courseDetails?.courseFormat}</Tag>
      </div>
      </div>

      <p>{courseDetails && courseDetails?.description.slice(0,300)}...</p>
     

    
     </div>


     <div className={"preview__cardWrapper"}>

     <div
        className="detailBanner"
        style={{'--img': `url(${courseDetails ? courseDetails.imageUrl : ''})`}}
      >
        {courseDetails?.promoVideo &&

          <div>
          <PlayCircleOutlined  onClick={openPreview} className="playIcon" />
          <h3>Preview This Course</h3>
          </div> 
        }
      </div>

     
      
      
        <div className='preview__cardBody'>

         {!isEnrolled && <div className='preview__cardHeader'>
         <div className='priceTextWrapper'>
        <h2 className='priceText'> {courseDetails?.price} {courseDetails?.currency.toUpperCase()}</h2>
        <p className='subTitle'>
          <IoAlarmOutline className='subIcon' />
          <span>Limited Period Offer</span>
          </p>
        </div>
         <div className="enrollDiv">
          <img src={cap} alt="icon" />
          <div>
          <div className="enrollNum">
           {enrolledStudents ? enrolledStudents : 0}
          </div>
          <div className="enrollText">
            Enrolled
          </div>
          </div>
        </div>
          </div>} 
       

        {isEnrolled
                ? <Link
                    className="primaryBtn"
                    to={`/courses/learn-course/${courseDetails?.id}`}
                  >
                    View Course
                  </Link>
                : 
                <div className='enrollBtnWrapper'>
                  
                  <button
                    type="button"
                    className="primaryBtn"
                    onClick={()=>setIsPayModal(true)}
                   
                  >
                    Buy Course
                    
                  </button>
                </div>  
                  }   
        </div>
      </div> 
   
    
      </div>
      
   
      <div className="detailWrapper">
       
       <div className='detailsCard'>
            <h2>Description</h2>
            <p>{courseDetails && courseDetails.description}</p>
       </div> 
        
        

      </div>
      <div className="learningPointWrapper">
        {courseDetails &&
          courseDetails.learningPoints &&
          courseDetails.learningPoints.length > 0 &&
          <h2>What you will learn?</h2>}
        {courseDetails &&
          courseDetails.learningPoints &&
          courseDetails.learningPoints.length > 0 &&
          courseDetails.learningPoints.map ((item, index) => {
            return (
              item &&
              <div className="learningPoint" key={index}>
                <div>
                  <img src={check} alt="*" />
                </div>
                <div className="point">
                  {item}
                </div>
              </div>
            );
          })}
      </div>

      <div className="courseSectionDetailsList">
        <h2>Course Modules</h2>
        <Collapse className="courseSectionCollapse">
          {courseSectionArr.map ((item, index) => {
            return (
              <Panel header={item.title} key={index}>
                {courseMaterialArr.map ((material, index) => {
                  if (material.CourseSectionId === item.id) {
                    return (
                      <div
                        key={index}
                        className={`materialLink ${material.isLocked && 'gray'}`}
                      >
                        {material.isLocked
                          ? <div>
                              <span style={{marginRight: '5px'}}>
                                <HiOutlineLockClosed />
                              </span>
                              <b>{material.title}</b>
                            </div>
                          : <div>
                              <span
                                style={{
                                  color: 'var(--green)',
                                  marginRight: '5px',
                                }}
                              >
                                <HiOutlineEye />
                              </span>
                              <Link
                                style={{color: 'var(--green)'}}
                                to={`/courses/material-preview/${material.id}`}
                              >
                                <b>{material.title}</b>
                              </Link>
                            </div>}

                      </div>
                    );
                  }
                  return null;
                })}
              </Panel>
            );
          })}

        </Collapse>

        <Drawer
        title={courseDetails?.title}
        placement={"top"}
        closable={true}
        onClose={()=>setIsCoursePreview(false)}
        visible={isCoursePreview}
        key={"top"}
        height="100vh"
      >
       <ReactPlayer url={courseDetails?.promoVideo} controls={true} width={'100%'} height={'85%'} />
      </Drawer>

      </div>
      <Modal
        title="Select Payment Mode"
        visible={isPayModal}
        width={'60%'}
        onCancel={()=>setIsPayModal(false)}
        footer={null}
      >
        <div className={"payment__card__wrapper"}>
            <Link  to={`/courses/pay/${courseDetails?.id}`} className="paymentCard">
                <DollarOutlined className='paymentIcon' />
                <h3>Non-INR Payment</h3>
               
            </Link>
            <Link  to={`/courses/in/pay/${courseDetails?.id}`} className="paymentCard">
            <FaRupeeSign className='paymentIcon' />
                <h3>INR Payment</h3>
               
            </Link>
        </div>

      </Modal>
    </div>
  );
}

export default SingleCourseDetails;
