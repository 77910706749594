import React, {useEffect, useState} from 'react';
import {
  HomeOutlined,
  InboxOutlined,
  LoadingOutlined,
  ProjectOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Select,
  Modal,
  Input,
  message,
  Spin,
  Tabs,
  Upload,
  Checkbox,
} from 'antd';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import '../../AdminCourse/AdminCourse.css';
import ProjectServices from '../../../../services/projects.service';
import CourseServices from '../../../../services/courses.service';
import ProjectMaterial from './ProjectMaterial';

const {TabPane} = Tabs;

const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);
function EditProject () {
  const {id} = useParams ();
  const [projectDetails, setProjectDetails] = useState (null);
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const [loading, setLoading] = useState (false);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState (false);
  const [title, setTitle] = useState ('');
  const [description, setDescription] = useState ('');
  const [category, setCategory] = useState ('');
  const [isCategoryModal, setIsCategoryModal] = useState (false);
  const [isCategoryAdding, setIsCategoryAdding] = useState (false);
  const [allProjectCategory, setAllProjectCategory] = useState ([]);
  const [newCategory, setNewCategory] = useState ('');
  const [learningPointsArr, setLearningPointsArr] = useState ([]);
  const [complexity, setComplexity] = useState ('Beginner');
  const [isFeatured, setIsFeatured] = useState (false);

  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          console.log (response.data);
          setAllProjectCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getAllProjectCategory = () => {
    ProjectServices.getAllProjectCategory ()
      .then (response => {
        console.log (response.data);
        setAllProjectCategory (response.data);
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleCategorySubmit = async () => {
    setIsCategoryAdding (true);
    try {
      const response = await ProjectServices.createNewProjectCategory (
        newCategory,
        currentUser.id
      );
      //   console.log (response);
      if (response) {
        message.success (`Added ${newCategory} Successfully!`);
        setNewCategory ('');
        getAllProjectCategory ();
        setIsCategoryAdding (false);
        setIsCategoryModal (false);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setIsCategoryAdding (false);
    }
  };

  async function getProjectDetails () {
    try {
      const response = await ProjectServices.getProjectById (id);
      setProjectDetails (response.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch project details!'
      );
    }
  }

  useEffect (
    () => {
      async function getProjectDetails () {
        try {
          const response = await ProjectServices.getProjectById (id);

          setProjectDetails (response.data);
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch project details!'
          );
        }
      }
      getProjectDetails ();
    },
    [id]
  );

  useEffect (
    () => {
      if (projectDetails) {
        setDescription (projectDetails.description);
        setTitle (projectDetails.title);
        setCategory (projectDetails.category);
        if (projectDetails.learningOutcome) {
          setLearningPointsArr (projectDetails.learningOutcome);
        }
        if (projectDetails.complexity) {
          setComplexity (projectDetails.complexity);
        }
        if (projectDetails.isFeatured) {
          setIsFeatured (projectDetails.isFeatured);
        }
      }
    },
    [projectDetails]
  );

  const handleUpdatedPoint = (value, index) => {
    let prevLearningPoints = learningPointsArr;
    prevLearningPoints[index] = value;
    // console.log("Updated Value : ",prevLearningPoints[index]);
    setLearningPointsArr (prevLearningPoints);
  };

  const onFinish = async () => {
    setLoading (true);

    let data = {
      id: projectDetails.id,
      title: title,
      description: description,
      category: category,
      imageUrl: fileUrl ? fileUrl : projectDetails.imageUrl,
      userId: currentUser.id,
      learningOutcome: learningPointsArr,
      isFeatured: isFeatured,
      complexity: complexity,
    };
    // console.log(data);
    try {
      const response = await ProjectServices.updateProjectById (data);
      // console.log(response.data);
      if (response.status === 200) {
        console.log ('success!');
      }
      message.success ('Updated Project Successfully!');
      setLoading (false);
      getProjectDetails ();
      // onReset();
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  function beforeUpload (file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (file) {
      setFileList ([file]);
      setUploadBtnDisabled (false);
    }
    if (!isJpgOrPng) {
      message.error ('You can only upload JPG/PNG file!');
      setUploadBtnDisabled (true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error ('Image must smaller than 2MB!');
      setUploadBtnDisabled (true);
    }

    return false;
  }

  const removePrevItemFromAws = key => {
    console.log ('key to remove : ', key);
    CourseServices.removeItemFromBucket ({userId: currentUser.id, key: key})
      .then (result => {
        console.log (result);
        message.info ('Deleted Previous Image from Bucket!');
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      });
  };

  const handleUpload = async () => {
    setUploading (true);
    const files = fileList;
    // console.log("File List : ",files);
    let removedItemKey = '';
    if (projectDetails.imageUrl) {
      console.log (projectDetails.imageUrl);
      let imageUrlArr = projectDetails.imageUrl.split ('/');
      console.log (imageUrlArr);
      removedItemKey = imageUrlArr[imageUrlArr.length - 1];
    }

    let changedName = '';
    // console.log("File List : ",files);
    // if (courseDetails.imageUrl) {
    //   let changedArr = courseDetails.imageUrl.split ('/');
    //   changedName = changedArr[changedArr.length - 1];
    // } else {
    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let hh = date.getHours ();
    let minutes = date.getMinutes ();
    let ss = date.getSeconds ();
    let finalDate =
      dd + '-' + mm + '-' + yyyy + '_at_' + hh + ':' + minutes + ':' + ss;
    changedName = finalDate.toString () + '__' + files[0].name;
    // console.log("Changed Arr : ",changedArr);
    // let changedName="8-2-2022_at_13:13:5__nodejs_banner.jpg";

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);

    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      message.success ('Image Uploaded Successfully!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }

    if (removedItemKey) {
      removePrevItemFromAws (removedItemKey);
    }
  };

  const add = () => {
    setLearningPointsArr (prev => {
      return [...prev, ' '];
    });
  };

  return (
    <div className="adminCourse">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumb className="breadCrumb">
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard">
              <HomeOutlined style={{marginRight: '5px'}} />
              <span>Dashboard</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard/manage-projects">
              <ProjectOutlined
                style={{
                  marginRight: '5px',
                  fontSize: '1rem',
                  marginBottom: '-2px',
                }}
              />
              <span>All Projects</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {projectDetails ? projectDetails.title : id}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div>
          <Button
            type="primary"
            className="btn"
            onClick={() => setIsCategoryModal (true)}
          >
            <PlusOutlined /> Add Category
          </Button>
        </div>
      </div>

      <Modal
        title="Add New Project Category"
        visible={isCategoryModal}
        confirmLoading={isCategoryAdding}
        okText={'Add'}
        onOk={handleCategorySubmit}
        onCancel={() => setIsCategoryModal (false)}
      >

        <div className="materialForm">
          <label>
            <b>Category Name</b>
          </label>

          <Input
            placeholder="e.g. Web Development"
            type="text"
            onChange={e => {
              setNewCategory (e.target.value);
            }}
            value={newCategory}
          />
        </div>
      </Modal>

      <div className="editCourse">
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="Basic Details" key="1">
            <div className="createCourseContainer">
              <h1>Update The Project Details</h1>
              <form>
                <div className="form-control">
                  <label>Title</label>
                  <input
                    type="text"
                    onChange={e => setTitle (e.target.value)}
                    value={title}
                    placeholder="e.g. Javascript Counter"
                  />
                </div>

                <div className="form-control">
                  <label>Description</label>
                  <textarea
                    onChange={e => setDescription (e.target.value)}
                    placeholder="Enter any description for the project"
                    value={description}
                  />
                </div>

                <div className="form-control">
                  <label>Select Updated Category</label>
                  <br />
                  <Select
                    showSearch
                    value={category}
                    style={{width: '100%'}}
                    onChange={value => setCategory (value)}
                    placeholder="e.g. Web Development"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes (input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase ()
                        .localeCompare (optionB.children.toLowerCase ())}
                  >
                    {allProjectCategory.map (item => {
                      return (
                        <Select.Option key={item.id} value={item.title}>
                          {item.title}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>

                <div className="form-control">
                  <label>Select Updated Complexity:</label>
                  <br />
                  <Select
                    value={complexity}
                    onChange={value => setComplexity (value)}
                    style={{width: '100%'}}
                  >
                    <Select.Option value={'Beginner'}>Beginner</Select.Option>
                    <Select.Option value={'Intermediate'}>
                      Intermediate
                    </Select.Option>
                    <Select.Option value={'Advanced'}>Advanced</Select.Option>
                  </Select>
                </div>

                <div className="form-control">
                  <label>Learning Points</label><br />
                  {learningPointsArr.map ((point, index) => {
                    return (
                      point &&
                      <Input
                        defaultValue={point}
                        key={index}
                        style={{marginTop: '10px'}}
                        onChange={e =>
                          handleUpdatedPoint (e.target.value, index)}
                      />
                    );
                  })}
                </div>
                <div>
                  <Button
                    type="dashed"
                    onClick={() => add ()}
                    style={{
                      width: '20%',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                    icon={<PlusOutlined />}
                    className="left"
                  >
                    Add Learning
                  </Button>
                </div>
                <br />
                <br />
                <div className="form-control">
                  <Checkbox
                    checked={isFeatured}
                    onChange={e => setIsFeatured (e.target.checked)}
                  >
                    is Project Featured?
                  </Checkbox>
                </div>

                <br />
                <br />

                <Upload.Dragger
                  name="files"
                  beforeUpload={beforeUpload}
                  onRemove={() => {
                    setFileList ([]);
                  }}
                  fileList={fileList}
                >
                  <p className="ant-upload-drag-icon">
                    {projectDetails
                      ? <img
                          src={projectDetails.imageUrl}
                          className="uploadDefaultImg"
                          alt="img"
                        />
                      : <InboxOutlined style={{color: 'var(--green)'}} />}
                  </p>
                  <p className="ant-upload-text">
                    Click or drag image to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Upload a Single Image File with maximum 2 MB of size.
                  </p>
                </Upload.Dragger>

                <button
                  type="button"
                  disabled={
                    uploading || fileList.length === 0 || uploadBtnDisabled
                  }
                  className="primaryBtn"
                  style={{marginTop: '5px', marginBottom: '5px'}}
                  onClick={handleUpload}
                >
                  {uploading ? <Spin indicator={spinIcon} /> : 'Upload'}
                </button>

                <Button
                  type="primary"
                  loading={loading}
                  onClick={onFinish}
                  className="btn"
                >
                  Update
                </Button>

              </form>
            </div>
          </TabPane>
          <TabPane tab="Project Material" key="2" disabled={!projectDetails}>
            <h1>Project Material</h1>
            <ProjectMaterial
              userId={currentUser.id}
              projectDetails={projectDetails}
            />
          </TabPane>
        </Tabs>

      </div>
    </div>
  );
}

export default EditProject;
