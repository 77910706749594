import {message, Progress} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import ProjectServices from '../../../services/projects.service';
import styles from './MyProject.module.css';

function MyProjects () {
  const [allEnrolledProjects, setAllEnrolledProjects] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      let isMounted = true;

      const getAllEnrolledProjects = async () => {
        try {
          const response = await ProjectServices.getAllEnrolledProjects ({
            userId: currentUser.id,
          });
        //   console.log (response.data);
        setAllEnrolledProjects(response.data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      if (isMounted && currentUser) {
        getAllEnrolledProjects ();
      }

      return () => {
        isMounted = false;
      };
    },
    [currentUser]
  );
  return (
    <div className={'myProject__wrapper'}>
      <h1 className={styles.myProject__heading}>My Projects</h1>

        <div className={styles.allProjects}>
            {
                allEnrolledProjects.map((item,index)=>{
                    return(
                        <div key={index} className={styles.projectCard}>
                            <div className={styles.projectCard__img} style={{

          backgroundImage: `
        linear-gradient(to bottom,  rgba(0, 12, 53, 0.83),rgba(4, 44, 155, 0.82)),
        url('${item?.projectDetails?.imageUrl}')`,
          backgroundSize: 'cover',height:'100%',width:'30%'}}>
                            </div>
                            <div className={styles.projectCard__info}>
                                <div className={styles.projectCard__header}>
                                <h1>{item?.projectDetails?.title}</h1>
                                <Progress className={styles.progress} percent={item?.progress.progress ? item?.progress.progress : 0} status="active" />
                                </div>
                              
                                <p>{item?.projectDetails?.description > 40 ? item.projectDetails.description.slice(0,40) : item?.projectDetails?.description}</p>
                                
                                <Link 
                    to={`/project-details/start/${item?.projectDetails?.id}`} className="primaryBtn">{item?.progress.progress >=100 ? "Revise" : "Continue"}</Link>
                            </div>
                        </div>
                    )
                })
            }
        </div>
      

    </div>
  );
}

export default MyProjects;
