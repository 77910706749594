import {HomeOutlined, UserOutlined} from '@ant-design/icons';
import {Breadcrumb, message} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import StudentServices from '../../../services/students.service';
import './StudentDetails.css';
import avatar from '../../../assets/login.png';
import courseImg from '../../../assets/courseImage.svg';
import CourseCard from '../../Courses/CourseCard/CourseCard';

function StudentsDetail () {
  const {id} = useParams ();
  const [enrolledCourses, setEnrolledCourses] = useState ('');

  useEffect (
    () => {
      if (id) {
        const getEnrolledCourses = async () => {
          try {
            const response = await StudentServices.getEnrolledCourses ({
              userId: id,
            });
           
            if (response) {
              let data = [];
              console.log (response.data);
              if (response.data) {
                let finalDate = 'N/A';
                response.data.forEach (item => {
                  if (item.createdAt) {
                    const date = new Date (item.createdAt);
                    let dd = date.getDate ();
                    let mm = date.getMonth () + 1;
                    let yyyy = date.getFullYear ();
                    let hh = date.getHours ();
                    let minutes = date.getMinutes ();
                    let ss = date.getSeconds ();
                    finalDate =
                      dd +
                      '-' +
                      mm +
                      '-' +
                      yyyy +
                      ' at ' +
                      hh +
                      ':' +
                      minutes +
                      ':' +
                      ss;
                    // console.log(finalDate);
                  }
                  let updatedData = {
                    ...item,
                    joinedAt: finalDate,
                  };

                  data.push (updatedData);
                });
              }
              console.log(data);
              setEnrolledCourses (data);
            }
          } catch (err) {
            console.log (err);
            message.error (err.message ? err.message : 'Unable to details!');
          }
        };

        if(id){
            getEnrolledCourses ();
        }
      }
    },
    [id]
  );

  return (
    <div>
      <div>
        <Breadcrumb className="breadCrumb">
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard">
              <HomeOutlined style={{marginRight: '5px'}} />
              <span>Dashboard</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumbLink">
            <Link to="/admin/dashboard/manage-students">
              <UserOutlined
                style={{
                  marginRight: '5px',
                  fontSize: '1rem',
                  marginBottom: '-2px',
                }}
              />
              <span>Manage Students</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Student Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <br />
      <br />

      <div className="student__Details__wrapper">

        <div className="student__Details__card">
        <div className="students__Details__cardHeader">
        <div className='d-flex'>
          <div>
          <img src={enrolledCourses && enrolledCourses[0]?.userDetails?.profilePicture ? enrolledCourses[0]?.userDetails?.profilePicture : avatar } alt={enrolledCourses && enrolledCourses[0]?.username} />
          </div>
          <div style={{marginLeft:'2%'}}>
            <div>
            <b style={{fontSize:'1.2rem',marginBottom:'0px'}}>{enrolledCourses && enrolledCourses[0]?.username }</b>
            </div>
            <div style={{marginTop:'-5px'}}>
            <small className="gray__text">{enrolledCourses && enrolledCourses[0]?.userDetails?.email ? enrolledCourses[0]?.userDetails?.email : 'N/A'}</small>
            </div>
           
          </div>
        </div>

       <div>
       <div className='badge'>
           <h5> {enrolledCourses.length}</h5>
        </div>
        <div style={{textAlign:'center',marginTop:'-5px'}}>
          <small className="gray__text">Courses Enrolled</small>
        </div>
       </div>
        </div>
        <hr />

        <div className='student__details__basicDetails'>
          <h2>Basic Details</h2>
          <table>
            <tr>
              <th>Name : </th>
              <td>{enrolledCourses && enrolledCourses[0]?.userDetails?.name && enrolledCourses[0]?.userDetails?.name }</td>
            </tr>
            <tr>
              <th>Gender : </th>
              <td>{enrolledCourses && enrolledCourses[0]?.userDetails?.gender && enrolledCourses[0]?.userDetails?.gender }</td>
            </tr>
            <tr>
              <th>DOB : </th>
              <td>{enrolledCourses && enrolledCourses[0]?.userDetails?.dob && enrolledCourses[0]?.userDetails?.dob }</td>
            </tr>
            <tr>
              <th>Contact : </th>
              <td>{enrolledCourses && enrolledCourses[0]?.userDetails?.contact && enrolledCourses[0]?.userDetails?.contact }</td>
            </tr>
            <tr>
              <th>Address : </th>
              <td>{enrolledCourses && enrolledCourses[0]?.userDetails?.address && enrolledCourses[0]?.userDetails?.address }</td>
            </tr>
          </table>
        </div>

        <div className='students__details__enrolled'>
          <h2>Enrolled Courses</h2>


          <div className='d-flex' style={{textAlign:'center',flexWrap:'wrap'}}>

          {enrolledCourses.length > 0 && enrolledCourses.map((item,index)=>{
            
            return(
              <CourseCard   
                img={item?.course.imageUrl ? item?.course.imageUrl : courseImg} 
                isShadow={"no"}
                key={index}
                title={item?.course?.title} 
                joinedAt={item?.joinedAt}
                id={item?.course?.id} 
                description={item?.course?.description}/>
            )
          }) }
         

          </div>
         

        </div>

        </div>

      </div>
    </div>
  );
}

export default StudentsDetail;
