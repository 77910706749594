import React, {useState} from 'react';
import {Menu, Layout} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {Route} from 'react-router';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {BiBookReader, BiBookHeart, BiHomeCircle} from 'react-icons/bi';
import {logout} from '../../actions/auth';
import {history} from '../../helpers/history';
import ProfileDropdown from '../../components/Navbar/ProfileDropdown';
import logo from '../../assets/codersartsLogo.png';
import avatar from '../../assets/login.png';
import UserDashboardHome from './UserDashboardHome/UserDashboardHome';
import MyProjects from './MyProjects/MyProjects';

const {Header, Sider, Content} = Layout;

function Dashboard () {
  const [collapsed, setCollapsed] = useState (false);
  const {user: currentUser} = useSelector (state => state.auth);
  const dispatch = useDispatch ();
  const toggle = () => {
    setCollapsed (prevValue => !prevValue);
  };

  const logOut = () => {
    dispatch (logout ());
    history.push ('/login');
  };

  return (
    <div>
      <Layout className="adminDashboard">
        <Sider
          trigger={null}
          className="sider"
          collapsible
          collapsed={collapsed}
        >
          <div className="logo" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item
              key="1"
              icon={<BiBookHeart />}
              className="customSideLink"
            >
              My Courses
              <Link to="/dashboard" />
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<ProjectOutlined />}
              className="customSideLink"
            >
              My Projects
              <Link to="/dashboard/my-projects" />
            </Menu.Item>

          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background adminNav">
            {React.createElement (
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: toggle,
              }
            )}
            <div className="logoArea">
              <div> <img src={logo} alt="logo" /></div>
              <div className="logoTitles">
                <div className="logoText">
                  Codersarts <span className="green">Learning </span>
                </div>
              </div>
            </div>
            <div className="navigationLinks">
              <Link to="/" className="navLink">
                <BiHomeCircle className="navIcon" /> Home
              </Link>
              <Link to="/courses" className="navLink">
                <BiBookReader className="navIcon" />Courses
              </Link>

              <Link to="/all-projects" className="navLink">
                <ProjectOutlined className="navIcon" />Projects
              </Link>

            </div>
            <div className="profileItems">
              <div className="nav-item profile-items">
                <img
                  src={
                    currentUser.profilePicture
                      ? currentUser.profilePicture
                      : avatar
                  }
                  className="profile-avatar"
                  alt="img"
                />
              </div>
              <ProfileDropdown
                user={currentUser}
                name={currentUser.username}
                logout={logOut}
              />
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 70,
              minHeight: 280,
            }}
          >
            <Route exact path="/dashboard" component={UserDashboardHome} />
            <Route exact path="/dashboard/my-projects" component={MyProjects} />

          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Dashboard;
