import React, {useState, useEffect} from 'react';
import styles from './AllProjects.module.css';
import projectLanding from '../../assets/projectLanding.png';
import ProjectServices from '../../services/projects.service';
import {Card,Tag, message, Menu, Badge, Empty} from 'antd';
import {AiOutlineSearch} from 'react-icons/ai';
import {FiFilter} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {BsArrowRight} from 'react-icons/bs';
import { useSelector } from 'react-redux';

const {Meta} = Card;

function AllProjects () {
  const [allProjects, setAllProjects] = useState ([]);
  const [filteredArr, setFilteredArr] = useState ([]);
  const [allProjectCategory, setAllProjectCategory] = useState ([]);
  const {user:currentUser} = useSelector((state)=>state.auth);

  const getAllProjects = async () => {
    try {
      const response = await ProjectServices.getAllProjects ();
      console.log (response);
      let data = [];

      response.data.forEach (item => {
        let finalDate = '';
        if (item.createdAt) {
          const date = new Date (item.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }
        let modifiedData = {
          ...item,
          createdAt: finalDate ? finalDate : item.createdAt,
          sortDate: item.createdAt,
          key: item.id,
        };
        // console.log("Modifed Data : ",modifiedData);
        data.push (modifiedData);
      });
      setAllProjects (data);
      setFilteredArr(data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to fetch projects!');
    }
  };
  useEffect (() => {
    getAllProjects ();
  }, []);

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          console.log (response.data);
          setAllProjectCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearch = query => {
    // console.log("Query : ",query);
    let filteredResult = [];
    filteredResult = allProjects.filter (item => {
      return (
        item.title && item.title.toLowerCase ().match (query.toLowerCase ())
      );
    });
    // console.log(filteredResult);
    setFilteredArr (filteredResult);
  };

  const handleFilter = category => {
    console.log(category);
    if (category !== 'All Projects') {
      let filteredResult = [];
    filteredResult = allProjects.filter (item => {
      return (
        item.category &&
        item.category.toLowerCase ().match (category.toLowerCase ())
      );
    });
    // console.log(filteredResult);
    setFilteredArr (filteredResult);
    }else{
      setFilteredArr(allProjects);
    }

   
  };

  return (
    <div className={styles.allProjects}>
      <div className={styles.landing__page}>
        <div className={styles.landing__page__img}>
          <div className={styles.circle1} />
          <div className={styles.circle2} />
          <img src={projectLanding} alt="landing-page" />
        </div>
        <div className={styles.landing__page__heading}>
          <h1>
            Start Learning Tech <br /> By Building
            {' '}
            <span className={styles.highlight}>Projects</span>
          </h1>
          <a
            href="#allProjects"
            className="primaryBtn"
            style={{
              padding: '12px',
              width: '150px',
              borderRadius: '0px',
              fontWeight: 'bold',
              boxShadow: '4px 7px 12px #d4d6d4',
            }}
          >
            Explore
          </a>

          <div className={styles.shape} />

        </div>

      </div>

      <div className={styles.featuredProjectsWrapper}>
        <h2>Popular Projects</h2>
           <div className={styles.featuredProjects}>
           {allProjects.map((item)=>{
              return(
              <Badge.Ribbon text={item.category} color={'#10CF4E'}  key={item.key}>
                      <Card
                        className={styles.projectCard}
                        cover={<img alt={item.title} src={item.imageUrl} />}
                      >
                        <Meta
                          title={item.title}
                          description={item?.description.length > 100 ? item?.description.slice(0,100) + '...' : item?.description}
                        />
                        {item?.description.length > 100 && <Link to={`/project-details/${item.id}`}>More</Link>}
                        <div className={styles.projectFooter}>
                        <Tag
                          style={{marginTop:'10px'}}
                          color={`${item.complexity ==='Beginner' ? 'success': item.complexity ==='Intermediate' ? 'warning' : 'error'}`}
                        >
                        {item?.complexity}
                        </Tag>
                        {currentUser ?   
                        <Link to={`/project-details/${item.id}`}  className={styles.projectLink}>
                        
                        View Project <BsArrowRight style={{marginLeft:'7px',marginTop:'2px'}} />
                        </Link>
                        :
                        <Link to={`/login`}  className={styles.projectLink}>
                        
                        Login to Start <BsArrowRight style={{marginLeft:'7px',marginTop:'2px'}} />
                        </Link>
                        }
                        </div>
                      
                      </Card>
                    </Badge.Ribbon>
             )
             
            
           })}
           </div>
           
     
      </div>


      <div className={styles.featuredProjectsWrapper}>
        <h2>Featured Projects</h2>
           <div className={styles.featuredProjects}>
           {allProjects.map((item,index)=>{
             if(item.isFeatured && index < 9){
              return(
              <Badge.Ribbon text={item.category} color={'#10CF4E'}  key={item.key}>
                      <Card
                        className={styles.projectCard}
                        cover={<img alt={item.title} src={item.imageUrl} />}
                      >
                        <Meta
                          title={item.title}
                          description={item?.description.length > 100 ? item?.description.slice(0,100) + '...' : item?.description}
                        />
                        {item?.description.length > 100 && <Link to={`/project-details/${item.id}`}>More</Link>}
                        <div className={styles.projectFooter}>
                        <Tag
                          style={{marginTop:'10px'}}
                          color={`${item.complexity ==='Beginner' ? 'success': item.complexity ==='Intermediate' ? 'warning' : 'error'}`}
                        >
                        {item?.complexity}
                        </Tag>
                        {currentUser ?   
                        <Link to={`/project-details/${item.id}`}  className={styles.projectLink}>
                        
                        View Project <BsArrowRight style={{marginLeft:'7px',marginTop:'2px'}} />
                        </Link>
                        :
                        <Link to={`/login`}  className={styles.projectLink}>
                        
                        Login to Start <BsArrowRight style={{marginLeft:'7px',marginTop:'2px'}} />
                        </Link>
                        }
                        </div>
                      
                      </Card>
                    </Badge.Ribbon>
             )
             }else{
               return null;
             }
            
           })}
           </div>
           
     
      </div>

      <div className={styles.allProjects__list} id="allProjects">
        <div className={styles.searchArea}>
          <div className={styles.searchInput}>
            <AiOutlineSearch className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search Projects..."
              onChange={e => handleSearch (e.target.value)}
            />
            <button className="primaryBtn">Search</button>
          </div>
        </div>

        <div className={styles.projectsAreaWrapper}>
          <div className={styles.projectsFilter}>
            <div
              style={{
                textAlign: 'left',
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <FiFilter style={{marginRight: '5px'}} />
              <span>Filter By Category</span>
            </div>

            <Menu mode="inline" theme="dark" defaultSelectedKeys="allProjects">
              <Menu.Item
                key="allProjects"
                onClick={() => handleFilter ('All Projects')}
              >
                All Projects
              </Menu.Item>
              {allProjectCategory.map (item => {
                return (
                  <Menu.Item
                    key={item.id}
                    onClick={() => handleFilter (item.title)}
                  >
                    {item.title}
                  </Menu.Item>
                );
              })}
            </Menu>
          </div>
          <div className={styles.projectArea}>
            {filteredArr.length > 0
              ? filteredArr.map (item => {
                  return (
                    <Badge.Ribbon text={item.category} color={'#10CF4E'}  key={item.key}>
                      <Card
                        className={styles.projectCard}
                       
                        cover={<img alt={item.title} src={item.imageUrl} />}
                      >
                        <Meta
                          title={item.title}
                          description={item?.description.length > 100 ? item?.description.slice(0,100) + '...' : item?.description}
                        />
                        {item?.description.length > 100 && <Link to={`/project-details/${item.id}`}>More</Link>}

                        <div className={styles.projectFooter}>
                        <Tag
                          style={{marginTop:'10px'}}
                          color={`${item.complexity ==='Beginner' ? 'success': item.complexity ==='Intermediate' ? 'warning' : 'error'}`}
                        >
                        {item?.complexity}
                        </Tag>
                        {currentUser ?   
                        <Link to={`/project-details/${item.id}`}  className={styles.projectLink}>
                        
                        View Project <BsArrowRight style={{marginLeft:'7px',marginTop:'2px'}} />
                        </Link>
                        :
                        <Link to={`/login`}  className={styles.projectLink}>
                        
                        Login to Start <BsArrowRight style={{marginLeft:'7px',marginTop:'2px'}} />
                        </Link>
                        }
                        </div>
                      
                      </Card>
                    </Badge.Ribbon>
                  );
                })
              : <div className='emptyBox'>
              <Empty
                  description={
                    <span>
                      No Projects Available
                    </span>
                  }
                ></Empty>
            </div>}
          </div>

        </div>
      </div>

    </div>
  );
}

export default AllProjects;
