import React, {useEffect, useState} from 'react';
import './Login.css';
import logo from '../../../assets/codersartsLogo.png';
import loginIcon from '../../../assets/login.png';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {login, setMsgNull} from '../../../actions/auth';
import {notification, Spin} from 'antd';
import {history} from '../../../helpers/history';
import {LoadingOutlined} from '@ant-design/icons';
import {RiEyeOffLine, RiEyeLine} from 'react-icons/ri';

const spinIcon = (
  <LoadingOutlined style={{fontSize: 24, color: 'white'}} spin />
);

function Login () {
  const [username, setUsername] = useState ('');
  const [password, setPassword] = useState ('');
  const [loading, setLoading] = useState (false);
  const dispatch = useDispatch ();
  const [passwordHidden, setPasswordHidden] = useState (true);
  const {user: currentUser} = useSelector (state => state.auth);
  const {currentMessage} = useSelector (state => state.currentMessage);

  const handleLogin = () => {
    setLoading (true);
    dispatch (login (username, password))
      .then (() => {
        setLoading (false);
        // history.push('/courses');
        // window.location.reload();
      })
      .catch (() => {
        setLoading (false);
      });
  };

  useEffect (
    () => {
      if (currentUser) {
        // console.log(currentUser);
        if (currentUser.roles[0].toLowerCase () === 'role_admin') {
          history.push ('/admin/dashboard');
        } else {
          history.push ('/courses');
        }
      }
    },
    [currentUser]
  );

  useEffect (
    () => {
      if (currentMessage) {
        notification.error ({
          message: 'Unable to Login!',
          description: currentMessage,
        });
      }
    },
    [currentMessage]
  );

  const handleUsernameChange = uname => {
    // console.log (uname);
    setUsername (uname);
    dispatch (setMsgNull ());
  };

  const handlePasswordChange = pass => {
    // console.log (pass);
    setPassword (pass);
    dispatch (setMsgNull ());
  };

  return (
    <div className="login">
      <div className="bgBlue">
        <div className="logoArea">
          <img src={logo} alt="logo" />
          <div className="logoText">
            Codersarts
          </div>
          <div className="logoDesc">
            Codersarts is a mentorship and Learning platform under
            {' '}
            <a
              href="https://www.sofstack.com/"
              rel="noreferrer"
              target={'_blank'}
            >
              Sofstack Technologies{' '}
            </a>
            {' '}
            that helps you get better at coding and provides support on latest Technologies.
          </div>
          <div className="readMore">
            <a
              href="https://www.codersarts.com/how-we-work"
              rel="noreferrer"
              target={'_blank'}
            >
              <u>Read More</u>
            </a>
          </div>
        </div>
      </div>
      <div className="form-area">

        <form>
          <div className="loginImg">
            <img src={loginIcon} alt="logo" />
          </div>
          <div> <h4>Login</h4></div>
          <div className="formGroup">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="formInput"
              name="username"
              value={username}
              onChange={e => {
                handleUsernameChange (e.target.value);
              }}
              placeholder="Enter Username or Email"
            />
          </div>

          <div className="formGroup">
            <label htmlFor="password">Password</label>
            <div className="passwordField">
              <input
                id="password"
                type={`${passwordHidden ? 'password' : 'text'}`}
                className="formInput"
                name="password"
                placeholder="Enter Account Password"
                value={password}
                onChange={e => {
                  handlePasswordChange (e.target.value);
                }}
              />
              <span>
                {passwordHidden
                  ? <RiEyeOffLine
                      className="passwordIcon"
                      onClick={() => {
                        setPasswordHidden (false);
                      }}
                    />
                  : <RiEyeLine
                      className="passwordIcon"
                      onClick={() => {
                        setPasswordHidden (true);
                      }}
                    />}
              </span>
            </div>
          </div>
          <div className="formBottomLink">
            <small>
              New Member? <Link to="/register" className="link">Register</Link>
            </small>
          </div>
          <div>
            <button
              type="button"
              disabled={username === '' || password === ''}
              onClick={handleLogin}
              className="loginBtn"
            >
              {loading ? <Spin indicator={spinIcon} /> : 'Login'}
            </button>
          </div>
        </form>

      </div>
    </div>
  );
}

export default Login;
