import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {useParams} from 'react-router-dom';
import StudentServices from '../../../services/students.service';
import { Spin, message } from 'antd';
import { history } from '../../../helpers/history';
import CourseServices from '../../../services/courses.service';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from '../../../components/CheckoutForm/CheckoutForm';
import PaymentServices from '../../../services/payments.services';
import { LoadingOutlined } from '@ant-design/icons';

//const STRIPE_PUBLISHABLE_KEY = "pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3"
const STRIPE_PUBLISHABLE_KEY = "pk_live_51JQbRNSB5a1UKLbSiZ51EixOqSnJdt5vtPdpj39w5JeJVvIKWFZDP9MSPlloPE3K5ONwDz1N4ztTVzcWgsziZrsR00mpCLICR7"

const stripePromise = loadStripe (STRIPE_PUBLISHABLE_KEY);


function PaymentCourse() {
    const params = useParams();
    const {user: currentUser} = useSelector (state => state.auth);
    const [courseDetails,setCourseDetails] = useState("");
    const [clientSecret,setClientSecret] = useState("");

    useEffect(()=>{
      if(!currentUser){
        history.push('/login');
      }
    },[currentUser]);

    useEffect(() => {
      if (clientSecret) {
        //console.log(clientSecret);
        // Render the Elements component or take necessary actions
      }
    }, [clientSecret]);



    useEffect (
        () => {
          async function getCourseDetails () {
            try {
              const response = await CourseServices.getCourseById (params.id);
              const response2 = await PaymentServices.createCoursePaymentIntent(params.id);
              //console.log(response2.data);
              // console.log("Course details : ",response.data);
              if(response.data?.currency==='inr'){
                const response3 = await CourseServices.findPrice({currency:"inr",value:response.data?.price})
                //console.log(response3.data);
                let finalData = {
                  ...response.data,
                  currency:"usd",
                  price:response3.data.value
                }
                setCourseDetails(finalData);
              }else{
              setCourseDetails (response.data);
              }
              setClientSecret(response2.data?.clientSecret || "")
            } catch (err) {
              console.log (err);
              message.error (
                err.message ? err.message : 'Unable to fetch course details!'
              );
            }
          }
          getCourseDetails ();
        },
        [params.id]
      );
   


  const appearance = {
    theme: 'flat',
    variables: { colorPrimaryText: '#262626' }
  };
  
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
       { clientSecret ? 
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} courseDetails={courseDetails}  />
        </Elements>
      :
      <div style={{marginTop:'15%'}}>
        <Spin indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 150,
                      color:'black',
                      marginBottom:'1rem'
                    }}
                    spin
                  />
                } />
        <h3>Please wait...</h3>
      </div>    
      }
    </div>
  )
}

export default PaymentCourse