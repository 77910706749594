import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './Landing.css';
import girl from '../../assets/landing.png';
import {FaLongArrowAltRight} from 'react-icons/fa';
import plane from '../../assets/plane.png';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PopularCourses from './PopularCourses';
import Mentorship from './Mentorship';
import Editor from './Editor';
import LearningMethods from './LearningMethods';
import Footer from './Footer';
// import PopularProjects from './PopularProjects';
// import PopularPrograms from './PopularPrograms';

function Landing () {
  const {user: currentUser} = useSelector (state => state.auth);
  return (
    <div>
      <Navbar />
      <div className="landing">
        <div className="imgArea">
          <img src={girl} alt="img" className="girl" />
        </div>
        <div className="textArea">
          <h1>Find The Best Courses For Your Dream Job!</h1>
          <p>
            Master In Demand Skills such as Machine Learning, Data Science, Web Development etc.
          </p>
          <Link
            to={`${currentUser ? '/courses' : '/login'}`}
            className="primaryBtn"
          >
            Start Learning <FaLongArrowAltRight className="rightIcon" />{' '}
          </Link>
          <div className="wave">
            <img src={plane} alt="" className="plane1" />
          </div>
        </div>

      </div>
      <PopularCourses />
      {/* <PopularProjects />
      <PopularPrograms /> */}
      <Mentorship />
      <Editor />
      <LearningMethods />
      <Footer />
    </div>
  );
}

export default Landing;
