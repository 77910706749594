import React from 'react';
import './Footer.css';
import logo from '../../assets/codersartsLogo.png';
import {FaFacebookF,FaTwitter,FaYoutube,FaLinkedinIn,FaInstagram} from 'react-icons/fa';


function Footer() {
  return(
  <div id="about">
        <div className='footer'>
      <div className='logoArea'>
        <div className='logo'>
            <img src={logo} alt="codersarts" />
            <h2>Codersarts</h2>
        </div>
        <div className='para'>
            <p>
            Codersarts is a product of Sofstack.com. SofStack is product development company based in Noida, India.
            We are  serving its clients across the globe.As passionate professionals of technology, Here you will find all sorts of services about our work as a Web Development, Mobile App Development, Technology Solutions for Business, Hiring dedicated developer for Project, IT Solutions, Database Development, Programming expert Help solutions. we provide all the programming expert  help, guidance, and support my clients need 
            in order to get good grasp of understanding and scaling business at the highest level.
            </p>
        </div>

        <div className='socialMediaIcons'>
        <div className='footerSocialArea'>

        <div className='footerSocialIcon'>
        <a href="https://www.facebook.com/codersarts2017" rel="noreferrer" target={'_blank'}>
        <FaFacebookF className="socialIcon" />
        </a>
        </div>

        <div className='footerSocialIcon'>
        <a href="https://twitter.com/@CodersArts" rel="noreferrer" target={'_blank'}>
        <FaTwitter className="socialIcon"/>
        </a>
        </div>

        <div className='footerSocialIcon'>
        <a href="https://www.youtube.com/channel/UC1nrlkYcj3hI8XnQgz8aK_g" rel="noreferrer" target={'_blank'}>
        <FaYoutube className="socialIcon" />
        </a>
        </div>

        <div className='footerSocialIcon'>
        <a href="http://linkedin.com/company/codersarts" rel="noreferrer" target={'_blank'}>
        <FaLinkedinIn className="socialIcon" />
        </a>
        </div>

        <div className='footerSocialIcon'>
        <a href="https://www.instagram.com/codersarts/" rel="noreferrer" target={'_blank'}>
        <FaInstagram className="socialIcon" />
        </a>
        </div>
        </div>
        </div>
      </div>

      <div className='footerContentArea'>
        <h3>About</h3>
       <div className='contentBody'>
       <a href="https://www.codersarts.com/about" rel="noreferrer" target={'_blank'}>About Us</a>
        <a href="https://www.codersarts.com/how-we-work" rel="noreferrer" target={'_blank'}>How we work</a>
        <a href="https://www.codersarts.com/python" rel="noreferrer" target={'_blank'}>Tutorial</a>
        <a href="https://www.codersarts.com/enterprise" rel="noreferrer" target={'_blank'}>Enterprise</a>
       </div>
     </div>
    <div className='footerContentArea'>
        <h3>Community</h3>
       <div className='contentBody'>
        <a href="https://www.codersarts.com/blog" rel="noreferrer" target={'_blank'}>Blog</a>
        <a href="https://www.codersarts.com/forum" rel="noreferrer" target={'_blank'}>Forums</a>
        <a href="https://www.codersarts.com/news" rel="noreferrer" target={'_blank'}>News</a>
        <a href="https://www.codersarts.com/reviews" rel="noreferrer" target={'_blank'}>Reviews</a>
       </div>
        
    </div>
    <div className='footerContentArea'>
        <h3>Career</h3>
       <div className='contentBody'>
        <a href="https://www.codersarts.com/jobs" rel="noreferrer" target={'_blank'}>Jobs</a>
        <a href="https://www.training.codersarts.com/internship"  rel="noreferrer" target={'_blank'}>Internship</a>
        <a href="https://www.training.codersarts.com" rel="noreferrer" target={'_blank'}>Course Training</a>
        <a href="https://www.codersarts.com/job-support-services" rel="noreferrer" target={'_blank'}>Job Support</a>
       </div>
    </div>

    <div className='footerContentArea'>
        <h3>Contact Us</h3>
       <div className="contentBodyAddress">
            <div className='footerAddress'><b>Tel:</b> (+91) 0120  4118730 </div>
            <div className='footerAddress'><b>Time :</b>   10 : 00  AM -  08 : 00 PM IST </div>
            <div className='footerAddress'><b>Email:</b> contact@codersarts.com</div>
            <div className='footerAddress'><b>Registered address:</b> G-69, Sector 63, Noida - 201301, India</div>
       </div>
    </div>

   
    </div>
    <div className="copyright">
        <p>All rights reserved by Sofstack Technology Pvt. Ltd.</p>
    </div>
  </div>
  );
}

export default Footer;
