import React from 'react';
import {Route } from 'react-router';
import Navbar from './components/Navbar/Navbar';
import { history } from "./helpers/history";

function RouteWithNavBarWithoutLoggedIn({component:Component, ...rest}){
    return(
       <Route {...rest}>
       <Navbar history={history} />
       <Component />
      </Route>);
}

export default RouteWithNavBarWithoutLoggedIn;