import React, {useEffect, useState} from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styles from './CheckoutForm.module.css';


import {AddressElement} from '@stripe/react-stripe-js';
import { Alert, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';



export default function CheckoutForm (props) {
  const stripe = useStripe ();
  const elements = useElements ();
  const {user: currentUser} = useSelector (state => state.auth);
  const [email, setEmail] = useState (currentUser?.email);
  const [msg, setMsg] = useState (null);
  const [isLoading, setIsLoading] = useState (false);
  const [paymentIntent,setPaymentIntent] = useState("");
  
  useEffect (
    () => {
      if (msg) {
        message.info (message);
      }
    },
    [msg]
  );

  useEffect (
    () => {
      if (!stripe) {
        return;
      }

      const clientSecret = props.clientSecret;
      //console.log (clientSecret);

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent (clientSecret).then (({paymentIntent,error}) => {
        if (error) {
          console.error("Error retrieving payment intent:", error);
          setMsg("Error retrieving payment intent. Please try again.");
          return;
        }
        //console.log (paymentIntent);
        setPaymentIntent(paymentIntent);
       
        switch (paymentIntent.status) {
          case 'succeeded':
            setMsg ('Payment succeeded!');
            break;
          case 'processing':
            setMsg ('Your payment is processing.');
            break;
          case 'requires_payment_method':
            break;
          default:
            setMsg ('Something went wrong.');
            break;
        }
      });
    },
    [stripe, props.clientSecret]
  );

  const handleSubmit = async e => {
    e.preventDefault ();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading (true);

    const {error} = await stripe.confirmPayment ({
      elements,

      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://learn.codersarts.com/payment/success/${props.courseDetails?.id}/${currentUser.id}/${paymentIntent?.id}`,
      },
      
    });
  
console.log(error);
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setIsLoading (false);
      message.error(error.message);
      setMsg (error.message);
    } else {
      setIsLoading (false);
      message.error("something went wrong!");
      setMsg ('An unexpected error occurred: ',error.message);
    }
    // console.log(paymentIntent);

   
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <div className={styles.checkoutFormWrapper}>
      <div className={styles.checkoutInfo}>
        <div className={styles.checkoutInfo__img}>
          
          <h3>Codersarts Learning</h3>
         
        </div>
        <div className={styles.checkoutInfoCard}> 
              <h4>{props.courseDetails?.title}</h4>
              <h2>{props.courseDetails?.price} {props.courseDetails?.currency}</h2>
        </div>
      </div>
      <div className={styles.checkoutForm__area}>
        <h2>Payment Details</h2>
        <form id="payment-form" onSubmit={handleSubmit}>
          <LinkAuthenticationElement
            id="link-authentication-element"
            defaultValues={{email}}
            
            onChange={e => setEmail (e.target.value)}
          />
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <br/>
          <h4>Address</h4>
          <AddressElement options={{mode: 'billing'}} />
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="primaryBtn"
            style={{
              borderRadius: '5px',
              boxShadow: 'none',
              width: '100%',
              margin: '0',
              marginTop: '20px',
            }}
          >
            <span id="button-text">
              {isLoading
                ? <Spin indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                    }}
                    spin
                  />
                } />
                : 'Pay now'}
            </span>
          </button>
          {/* Show any error or success messages */}
          {msg && <Alert style={{marginTop:'1rem'}} type='error' description={msg} />}
        </form>
      </div>
    </div>
  );
}
