import React,{useState,useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styles from '../../components/CheckoutForm/CheckoutForm.module.css';
import CourseServices from '../../services/courses.service';
import { Button, Input, message } from 'antd';
import { useSelector } from 'react-redux';
import PaymentServices from '../../services/payments.services';
import { history } from '../../helpers/history';
import StudentServices from '../../services/students.service';
// const RAZORPAY_KEY_ID = 'rzp_test_G7DsD1qnqXM09V';
const RAZORPAY_KEY_ID = 'rzp_live_U39HBuNMKnIr0n'
const loadRazorpay = (src)=>{
  return new Promise((resolve)=>{
      const script = document.createElement('script');
      script.src = src;
      
      script.onload = ()=>{
          resolve(true);
      }

      script.onerror = ()=>{
          resolve(false);
      }
      document.body.appendChild(script);
  })
}
function PaymentIndia() {
  const [courseDetails,setCourseDetails] = useState("");
  const {user: currentUser} = useSelector (state => state.auth);
  const [email,setEmail] = useState(currentUser?.email);
  const [fullName,setFullName] = useState(currentUser?.name)
  const [address,setAddress] = useState("");
  const [loading,setLoading] = useState("");
  const params = useParams();

  useEffect(()=>{
    if(!currentUser){
      message.error("Not Authorized!");
      history.push('/login')
    }
  },[currentUser])
 

  
  useEffect (
    () => {
      async function getCourseDetails () {
        try {
          const response = await CourseServices.getCourseById (params.id);
          // console.log("Course details : ",response.data);
          if(response.data?.currency==='usd'){
            const response3 = await CourseServices.findPrice({currency:"usd",value:response.data?.price})
            //console.log(response3.data);
            let finalData = {
              ...response.data,
              currency:"inr",
              price:response3.data.value
            }
            setCourseDetails(finalData);
          }else{
          setCourseDetails (response.data);
          }
         
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch course details!'
          );
        }
      }
      getCourseDetails ();
    },
    [params.id,currentUser]
  );

  const enrollNow = async () => {
     
    try {
      let response = await StudentServices.enrollNewStudent ({
        userId:currentUser.id,
        username: currentUser.username,
        courseId: courseDetails.id,
        userDetails:currentUser
      });
      let studentId = "";
      //console.log(response.data);

      if(response.status===200){
        message.success (`You're Enrolled successfully!`);
        studentId = response.data?.id;
      }

      return studentId;
      
     
    } catch (err) {
    
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString ();
      console.log (err);
      message.error (msg);
    }
  };

  const createProgress = async ()=>{
    try{
      const response = await StudentServices.createCourseProgress(
        {userId:currentUser.id,
        courseId:courseDetails.id});
        //console.log("progress created : ",response.data)
        if(response.data){
          history.push (`/courses/learn-course/${courseDetails.id}`);
        }
    }catch(err){  
      console.log(err);
      message.error(err.message ? err.message : 'something went wrong!');
    }
  
  }

  const razorpayPaymentHandler = async () => {
    setLoading(true);
   const res = await loadRazorpay("https://checkout.razorpay.com/v1/checkout.js");
  
   if(!res){
       message.error('Razorpay Failed To Load!');
       setLoading(false);
       return;
   }
   
   try{
  
    const response = await PaymentServices.createRazorpayCoursePayment({courseId:courseDetails.id});
    const { data } = response;
    //console.log(data);
    if(response.status===200){
      const options = {
        key: RAZORPAY_KEY_ID,
        amount:data.amount,
        name: "Codersarts Learning",
        description: courseDetails?.title,
        // order_id: data.id, //uncomment this line in production
        handler: async (response) => {
          // console.log(response);
          try {
            const paymentId = response.razorpay_payment_id;
            const captureResponse = await PaymentServices.captureRazorpayment(paymentId, {id:courseDetails.id})
            //console.log(captureResponse.data);
            let capturedData = JSON.parse(captureResponse.data)
            if(capturedData){
              message.success("Payment Successfully Completed!");
              const studentId = await enrollNow();
              let paymentData ={
                amount:parseFloat(capturedData?.amount)/100,
                currency:capturedData?.currency,
                transaction_id:capturedData?.id,
                paymentMethod:capturedData?.method,
                type:"Razorpay",
                StudentId:studentId,
                status:true
               
              } 
              //console.log(paymentData);
              const paymentResponse = await PaymentServices.createNewPayment(paymentData);
              //console.log(paymentResponse.data);
             
             
            }
            setLoading(false);
    
            createProgress();
            
           } catch (err) {
             console.log(err);
             setLoading(false);
           }
        },
        prefill:{
            name:fullName,
            email:email,
        },
        theme: {
          color: "#101728"
        },
        modal: {
          "ondismiss": function(){
            setLoading(false);
              // console.log("Checkout form closed");
          }
      }
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      
    }else{
      setLoading(false);
      message.error("Something went wrong!");
    }
  
  
   }catch(err){
     setLoading(false);
     message.error(err.message)
   }
  };


  return (
    <div className={styles.checkoutFormWrapper}>
      <div className={styles.checkoutInfo}>
        <div className={styles.checkoutInfo__img}>
          
          <h3>Codersarts Learning</h3>
         
        </div>
        <div className={styles.checkoutInfoCard}> 
              <h4>{courseDetails?.title}</h4>
              <h2>{courseDetails?.price} {courseDetails?.currency}</h2>
        </div>
      </div>
      <div className={styles.checkoutForm__area}>
           <h2>Payment Details</h2>
           <form>
           <div className={styles.input__wrapper}>
              <label>Full Name </label>
              <Input value={fullName} onChange={(e)=>setFullName(e.target.value)} />
            </div>
           <div className={styles.input__wrapper}>
              <label>Email </label>
              <Input value={email} onChange={(e)=>setEmail(e.target.value)} />
            </div>
            <div className={styles.input__wrapper}>
              <label>Billing Address </label>
              <Input.TextArea value={address} onChange={(e)=>setAddress(e.target.value)} />
            </div>
            <Button onClick={razorpayPaymentHandler} loading={loading} disabled={!email || !address} style={{width:'100%'}} className='primaryBtn' type='primary'>
              Pay Now
            </Button>
           </form>
      </div>

    
    </div>
  )
}

export default PaymentIndia