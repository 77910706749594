import React, {useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichEditor.css';
import ImageResize from 'quill-image-resize-module-react';
import {message, Breadcrumb, Modal, Spin, Upload} from 'antd';
import {HiUpload} from 'react-icons/hi';
import {useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import CourseServices from '../../services/courses.service';
import {InboxOutlined, LoadingOutlined, HomeOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {BiBookReader} from 'react-icons/bi';

import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';

Quill.register ('modules/imageResize', ImageResize);
const spinIcon = (
  <LoadingOutlined style={{color: 'white', fontSize: 24}} spin />
);

const modules = {
  syntax: {highlight: text => hljs.highlightAuto (text).value},
  toolbar: {
    container: [
      [{font: []}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ['bold', 'italic', 'underline', 'strike'],
      [{color: []}, {background: []}],
      [{script: 'sub'}, {script: 'super'}],
      ['blockquote', 'code-block'],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{indent: '-1'}, {indent: '+1'}, {align: []}],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    handlers: {
      image: imageHandler,
    },
  },
  imageResize: {
    parchment: Quill.import ('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
};

function imageHandler () {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const range = this.quill.getSelection (true);
    const value = this.textbox.value;
    if (value) {
      this.quill.insertEmbed (range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide ();
  };
  tooltip.edit ('image');
  tooltip.textbox.placeholder = 'Embed URL';
}

function QuillEditor () {
  const [editorState, setEditorState] = useState ('');
  const [loading, setLoading] = useState (false);
  const [uploadModal, setUploadModal] = useState (false);
  const [fileList, setFileList] = useState ([]);
  const [uploading, setUploading] = useState (false);
  const [fileUrl, setFileUrl] = useState ('');
  const location = useLocation ();
  const {
    courseId,
    isLocked,
    title,
    courseName,
    CourseSectionId,
  } = location.state;
  //   console.log("location : ",location)

  const {user: currentUser} = useSelector (state => state.auth);

  //  useEffect(() => {
  //   console.log(editorState);
  // }, [editorState]);

  const handleCreateCourseMaterial = async () => {
    //  console.log("Final Content : ",convertToRaw(editorState.getCurrentContent()));
    let contentRaw = editorState;
    //  console.log("Final data : ",contentRaw);

    if (CourseSectionId || title) {
      setLoading (true);
      // console.log("adding");
      let materialData = {
        title: title,
        textData: contentRaw,
        type: 'Rich Text',
        fileLink: '',
        isLocked: isLocked === false ? isLocked : true,
        CourseSectionId: CourseSectionId,
        userId: currentUser.id,
        courseId: courseId,
        isInstructorConnect:isLocked === false ? isLocked : true,
        isEnabled:isLocked === false ? isLocked : true,
      };
      // console.log("Final Data : ",materialData);
      try {
        const response = await CourseServices.createNewMaterial (materialData);
        // console.log(response.data);
        if (response) {
          setLoading (false);
          message.success ('Added new Rich Text Material Successfully!');
        }
      } catch (error) {
        const msg =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString ();
        console.log (error);
        setLoading (false);
        message.error (msg);
        console.log (error);
        setLoading (false);
        message.error (msg);
      }
    } else {
      message.warning ('Please select a section for course material!');
    }
  };

  const handleUploadModal = () => {
    setUploadModal (true);
  };

  const handleCancelUploadModal = () => {
    setUploadModal (false);
  };
  function beforeUpload (file) {
    if (file) {
      setFileList ([file]);
    }
    return false;
  }

  const handleFileUpload = async () => {
    // console.log("uploading!");
    setUploading (true);
    const files = fileList;

    // let date = new Date();
    // let dd = date.getDate();
    // let mm = date.getMonth() + 1;
    // let yyyy = date.getFullYear()
    // let hh = date.getHours();
    // let minutes = date.getMinutes();
    // let ss = date.getSeconds();
    // let finalDate =  dd + "-" + mm + "-"+yyyy + "_at_"+hh + ":" +minutes + ":"+ss;
    //  let changedName = finalDate.toString()+"__"+files[0].name
    let changedName = files[0].name;

    const formData = new FormData ();
    files.forEach (file => {
      formData.append ('file', file);
    });

    // console.log("changedName",changedName);
    try {
      const response = await CourseServices.uploadCourseImg (
        formData,
        changedName
      );
      setUploading (false);
      // console.log("Data : ",response.data);
      setFileUrl (response.data.url);
      navigator.clipboard.writeText (response.data.url);
      message.success ('File Uploaded Successfully!');
      message.success ('Link copied to clipboard!');
    } catch (err) {
      console.log (err);
      setUploading (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  // useEffect(() => {
  //   console.log(editorState)

  // }, [editorState])

  return (
    <div className="richEditor">
      <div>
        <div>
          <Breadcrumb className="breadCrumb">
            <Breadcrumb.Item className="breadcrumbLink">
              <Link to="/admin/dashboard">
                <HomeOutlined style={{marginRight: '5px'}} />
                <span>Dashboard</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumbLink">
              <Link to="/admin/dashboard/manage-courses">
                <BiBookReader
                  style={{
                    marginRight: '5px',
                    fontSize: '1rem',
                    marginBottom: '-2px',
                  }}
                />
                <span>All Courses</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumbLink">
              <Link to={`/admin/dashboard/edit-course-details/${courseId}`}>
                <span>{courseName}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Text Editor</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <br />
        <br />
        <div>
          <h1>Text Based Course Material</h1>
          <h2>Create a text based course material for the section</h2>
        </div>
      </div>

      <button
        className="primaryBtn"
        onClick={handleUploadModal}
        style={{float: 'right', margin: '0'}}
      >
        <HiUpload style={{fontSize: '1rem'}} />Upload File
      </button>
      <ReactQuill
        modules={modules}
        theme="snow"
        onChange={setEditorState}
        placeholder="Content goes here..."
      />

      <button className="primaryBtn" onClick={handleCreateCourseMaterial}>
        {loading ? <Spin indicator={spinIcon} /> : 'Create'}
      </button>

      <Modal
        title="Upload a File"
        visible={uploadModal}
        confirmLoading={uploading}
        okText={'Upload'}
        onOk={handleFileUpload}
        onCancel={handleCancelUploadModal}
      >
        {fileUrl &&
          <div>
            <div><b>Recent File Link: </b></div>
            <a
              href={fileUrl}
              style={{marginBottom: '2%'}}
              rel="noreferrer"
              target={'_blank'}
            >
              {fileUrl}
            </a>
          </div>}

        <Upload.Dragger
          name="files"
          beforeUpload={beforeUpload}
          onRemove={() => {
            setFileList ([]);
          }}
          className="uploader"
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{color: 'var(--green)'}} />
          </p>
          <p className="ant-upload-text">
            Click or drag File to this area to upload
          </p>
          <p className="ant-upload-hint">
            You upload any resource file such as .zip, .rar, .pdf, .docx etc.
          </p>
        </Upload.Dragger>

      </Modal>

    </div>
  );
}

export default QuillEditor;
