import React, {useState, useEffect} from 'react';
import './Courses.css';
import {BsSearch} from 'react-icons/bs';
import CourseCard from './CourseCard/CourseCard';
import {Badge, Divider, List, message} from 'antd';
import CourseServices from '../../services/courses.service';
import ProjectServices from '../../services/projects.service';


function Courses () {
  const [courses, setCourses] = useState ([]);
  const [filteredCourses, setFilteredCourses] = useState ([]);
  const [allProjectCategory, setAllProjectCategory] = useState ([]);
 
  const [filterCurrentCategoryItem,setCurrentFilterCategory] = useState("All");

  

  useEffect (() => {
    let isMounted = true;
    const getAllProjectCategory = () => {
      ProjectServices.getAllProjectCategory ()
        .then (response => {
          //console.log (response.data);
          setAllProjectCategory (response.data);
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        });
    };

    if (isMounted) {
      getAllProjectCategory ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getAllCourses = async () => {
    try {
      const response = await CourseServices.getAllCourses ();
      // console.log(response.data);
      setCourses (response.data);
      // setFilteredCourses(response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  useEffect (() => {
    getAllCourses ();
  }, []);

  

  const handleSearchChange = searchText => {
    // console.log("Courses : ",courses);
   
    if (searchText.length > 0) {
      const filteredArr = courses.filter (item => {
        return (
          item.title &&
          item.title.toLowerCase ().match (searchText.toLowerCase ())
        );
      });
      // console.log(filteredArr);
      setFilteredCourses (filteredArr);
      
    } else {
      setFilteredCourses ([]);
    }
  };

  

 

  const handleSelectCategoryFilter = category => {
   setCurrentFilterCategory(category);
    if(category==='All'){
      setFilteredCourses([]);
      return;
    }
   //console.log(category);
    let filteredCoursesArr = courses.filter (c => {
      // console.log(c);
        return (Array.isArray(c.category) ? c.category.includes(category) : c.category.replace(/['"]+/g, '')===category) || c.courseFormat === category;
      });
      //console.log(filteredCoursesArr);


        setFilteredCourses(filteredCoursesArr)
     

    
  };
  return (
    <div className="courses">
      <div className="searchArea">
        <h1 className="searchTitle">What are you looking for ?</h1>
        <p className="subText">Learn anytime and accelerate your future!</p>
        <div className="search">
          <BsSearch className="searchIcon" />
          <input
            type="search"
            placeholder="Search courses here..."
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
          <button type="button" className="searchBtn">Search</button>
        </div>
      </div>

      <div className="allCourses">
        <h1>
          {filteredCourses.length > 0
            ? `Found ${filteredCourses.length} Courses`
            : 'Popular Courses'}
        </h1>
        <div className={"filterCategories__wrapper"}>
        <div onClick={()=>handleSelectCategoryFilter('All')} className={`filterCategoryItem ${filterCurrentCategoryItem==='All' ? 'activeFilterCategory' : ''}`}>
                All
            </div>
           {allProjectCategory.map((item)=>{
            return( <div key={item._id} onClick={()=>handleSelectCategoryFilter(item.title)} className={`filterCategoryItem ${filterCurrentCategoryItem===item.title ? 'activeFilterCategory' : ''}`}>
                {item.title}
            </div>)
           })}
           <div onClick={()=>handleSelectCategoryFilter('Short')} className={`filterCategoryItem ${filterCurrentCategoryItem==='Short' ? 'activeFilterCategory' : ''}`}>
                Short
            </div>
             <div onClick={()=>handleSelectCategoryFilter('Long')} className={`filterCategoryItem ${filterCurrentCategoryItem==='Long' ? 'activeFilterCategory' : ''}`}>
                Long
            </div>

        </div>
        <Divider/>
        <div>

         <List
  grid={{
   gutter: 16,
   xs: 1,
   sm: 2,
   md: 4,
   lg: 4,
   xl: 4,
   xxl: 5
  }}

 

  pagination={{
    showSizeChanger: true,
    pageSizeOptions: ["8", "16", "30", "40","50","100"],
    defaultPageSize:8,
    position: "both"
  }}

  dataSource={filteredCourses.length > 0 ? filteredCourses :  courses}
  renderItem={course => (
    <List.Item key={course.id}>
      <Badge.Ribbon  text={course.courseFormat} color={'#10CF4E'} >
            <CourseCard
                  
                  img={course.imageUrl}
                  description={course.description}
                  category={course.category}
                  title={course.title}
                  id={course.id}
                />
      </Badge.Ribbon>
  </List.Item>)}
/>

           
           
        </div>
       
      </div>
   
    </div>
  );
}

export default Courses;
