import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {Dropdown, Input, Menu, message, Modal, Table, Tag} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {history} from '../../../helpers/history';
import ProgramServices from '../../../services/programs.service';
import '../ManageCourses/ManageCourses.css';
const {confirm} = Modal;

function ManagePrograms () {
  const [allPrograms, setAllPrograms] = useState ([]);
  const [filteredPrograms, setFilteredPrograms] = useState ([]);

  const {user: currentUser} = useSelector (state => state.auth);

  const getAllPrograms = async () => {
    try {
      const response = await ProgramServices.getAllPrograms ();
      console.log (response.data);
      let data = [];

      response.data.forEach (item => {
        let finalDate = '';
        if (item.createdAt) {
          const date = new Date (item.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }
        let modifiedData = {
          ...item,
          createdAt: finalDate ? finalDate : item.createdAt,
          sortDate: item.createdAt,
          key: item.id,
        };
        // console.log("Modifed Data : ",modifiedData);
        data.push (modifiedData);
      });
      setAllPrograms (data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to fetch Programs!');
    }
  };
  useEffect (() => {
    getAllPrograms ();
  }, []);

  const handleDelete = (id, programName) => {
    confirm ({
      title: 'Are you sure delete this Program?',
      icon: <ExclamationCircleOutlined />,
      content: `Program to Delete : ${programName}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        ProgramServices.deleteProgramById (id, currentUser.id)
          .then (response => {
            //   console.log(response.data);
            message.success (`Deleted ${programName} Successfully!`);
            getAllPrograms ();
          })
          .catch (err => {
            console.log (err);
            message.error (err.message ? err.message : 'Unable to delete!');
          });
      },
    });
  };

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: id => {
        return <b>#{id}</b>;
      },
    },
    {
      title: 'Program',
      dataIndex: 'title',
      width: '30%',
      key: 'title',
      render: value => {
        return <b>{value}</b>;
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '30%',
      key: 'category',
      render: value => {
        return <Tag color={'cyan'}>{value}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: record => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <EditOutlined />
                  {' '}
                  <Link
                    to={`/admin/dashboard/edit-program-details/${record.id}`}
                  />
                  {' '}
                  Edit Project
                </Menu.Item>
                <Menu.Item
                  danger
                  key="2"
                  onClick={() => {
                    handleDelete (record.id, record.title);
                  }}
                >
                  <div><DeleteOutlined /> Delete</div>
                </Menu.Item>
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{
                  cursor: 'pointer',
                  color: 'black',
                  transform: '90deg',
                  fontSize: '1.5rem',
                }}
              />
            </p>
          </Dropdown>
        );
      },
    },
  ];
  const handleSearch = e => {
    // console.log(e);
    let query = e.target.value;
    // console.log("Query : ",query);
    let filteredResult = [];
    filteredResult = allPrograms.filter (item => {
      return (
        item.title && item.title.toLowerCase ().match (query.toLowerCase ())
      );
    });
    // console.log(filteredResult);
    setFilteredPrograms (filteredResult);
  };

  const handleNewProject = () => {
    history.push ('/admin/dashboard/create-new-program');
  };

  return (
    <div className="manageCourses">
      <div className="tableHeading">
        <h1>All Programs </h1>
        <div className="searchArea">
          <Input
            className="searchInput"
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="Search for Program by title"
            allowClear
            onChange={handleSearch}
          />
        </div>
        <div>
          <button className="btn" onClick={handleNewProject}>
            {' '}<PlusOutlined /> New Program
          </button>
        </div>
      </div>
      <Table
        columns={columns}
        scroll={{y: 240}}
        expandable={{
          expandedRowRender: record => (
            <p style={{margin: 0}}>{record.description}</p>
          ),
          rowExpandable: record =>
            record.description !== '' || record.description !== null,
          expandRowByClick: true,
        }}
        dataSource={
          filteredPrograms.length > 0 ? filteredPrograms : allPrograms
        }
      />

    </div>
  );
}

export default ManagePrograms;
